
export default class Tool {
    constructor() {
        // do nothing.
    }

    checkNull(str){
        if(str == undefined){
            return ''
        }
        return str
    }

    checkNullAttr(obj, key){
        if(obj == undefined){
            return ''
        }
        return obj[key]
    }

    uuid() {
        var s = [];
        var hexDigits = '0123456789abcdef';
        for (var i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = '4';
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
        s[8] = s[13] = s[18] = s[23] = '-';

        var uuid = s.join('');
        // console.log(s.join(''), 's.join("")');
        return uuid;
    }
}