import axios from 'axios'
import store from '../store/index'
import router from '../router/index'
import dayjs from 'dayjs'
import {API_HOST} from '../common/config'

axios.defaults.timeout = 50000;                        //响应时间
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';        //配置请求头
axios.defaults.baseURL = API_HOST;   //配置接口地址

//POST传参序列化(添加请求拦截器)
axios.interceptors.request.use((config) => {
    //在发送请求之前做某件事
    if(config.method  === 'post'){
        // config.data = qs.stringify(config.data);
    }
    return config;
},(error) =>{
    console.log('错误的传参')
    return Promise.reject(error);
});

//返回状态判断(添加响应拦截器)
axios.interceptors.response.use((response) =>{
    console.log('接口请求成功')
    return Promise.resolve(response)
}, (error) => {
    if(error.response && error.response.status == 500 || error.response.status == 403){
        console.error('接口错误，错误代码：', error.response.status)
        console.error('接口错误，错误内容：', error)
        return Promise.reject(error)
    }else if(error.response && error.response.status == 401){
        console.error('没有权限，错误代码：', error.response.status)
        console.error('没有权限，错误内容：', error)
        store.commit('setToken', '')
        store.commit('setTokenTime', '')
        sessionStorage.setItem('token', '')
        sessionStorage.setItem('tokenTime', '')
        router.push('/login')
    }else if(error.response && error.response.status == 404){
        console.error('接口不存在，错误代码：', error.response.status)
        console.error('接口不存在，错误内容：', error)
        return Promise.reject(error)
    }else if(error.response && error.response.status == 400){
        console.error('业务错误，错误代码：', error.response.status)
        console.error('业务错误，错误内容：', error)
        return Promise.reject(error)
    }else{
        console.log('接口非500|401|403等错误，具体查看页面显示的出错信息：', error)
        return Promise.reject(error)
    }
});

export function login({name, password}){
    return new Promise((resolve, reject) => {
        axios.post(`/api/auth/login`, {name, password})
            .then(response => {
                console.log('login token', response)
                store.commit('setToken', response.data.token)
                store.commit('setTokenTime', dayjs())
                sessionStorage.setItem('token', response.data.token)
                sessionStorage.setItem('tokenTime', dayjs())
                resolve(response)
            }, error => {
                reject(error)
            })
            .catch((error) => {
                console.log('接口相关的前端代码错误')
                console.error(error)
            })
    })
}

export function logout(){
    return new Promise((resolve, reject) => {
        axios.post(`/api/auth/logout`, {},{
            headers: {
                'Authorization': sessionStorage.getItem('token') 
            },
        })
            .then(response => {
                store.commit('setToken', '')
                store.commit('setTokenTime', '')
                sessionStorage.setItem('token', '')
                sessionStorage.setItem('tokenTime', '')
                resolve(response)
            }, error => {
                reject(error)
            })
            .catch((error) => {
                console.log('接口相关的前端代码错误')
                console.error(error)
            })
    })
}

//返回一个Promise(发送post请求)
export function fetchPost(url, params, beforeResolveResponse) {
    return new Promise((resolve, reject) => {
        console.log('post token', sessionStorage.getItem('token'))
        axios.post(url, params, {
            headers: {
                'Authorization': sessionStorage.getItem('token') 
            },
        })
            .then(response => {
                if(typeof beforeResolveResponse == 'function'){
                    resolve(beforeResolveResponse(response))
                }else{
                    resolve(response)
                }
            }, error => {
                if(error.response && error.response.status == 404 && typeof beforeResolveResponse == 'function'){
                    console.log('接口不存在，触发service，模拟数据为：', beforeResolveResponse())
                    resolve(beforeResolveResponse())
                }else{
                    reject(error)
                }
            })
            .catch((error) => {
                console.log('接口相关的前端代码错误')
                console.error(error)
            })
    })
}
////返回一个Promise(发送get请求)
export function fetchGet(url, params, beforeResolveResponse) {
    return new Promise((resolve, reject) => {
        axios.get(url, {params: params, headers: {
                'Authorization': sessionStorage.getItem('token') 
            },
        })
            .then(response => {
                if(typeof beforeResolveResponse == 'function'){
                    resolve(beforeResolveResponse(response))
                }else{
                    resolve(response)
                }
            }, error => {
                if(error.response && error.response.status == 404 && typeof beforeResolveResponse == 'function'){
                    console.log('接口不存在，触发service，模拟数据为：', beforeResolveResponse())
                    resolve(beforeResolveResponse())
                }else{
                    reject(error)
                }
            })
            .catch((error) => {
                console.log('接口相关的前端代码错误')
                console.error(error)
            })
    })
}

export default {
    fetchPost,
    fetchGet,
    login,
    logout
}