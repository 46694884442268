<template>
  <div>
  <el-row>
      <el-col :span="24">
        <el-form :model="form" inline @submit.native.prevent>
          <el-form-item label="查找">
            <el-input v-model="form.search" placeholder="请输入手机号或昵称查找" @keyup.enter.native="listPointLog" clearable @change="changeKeyword"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="success" @click="listPointSettings">查找</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-table 
        :data="tableData" 
        v-loading="loading"
    >
        <el-table-column label="客人昵称">
            <template slot-scope="scope">
                <el-link :href="`/customer/${scope.row.customer.cellphone}`" type="primary">
                  {{scope.row.customer != null ? scope.row.customer.nickname : '已删除客人'}}
                </el-link>
            </template>
        </el-table-column>
        <el-table-column label="调整套餐">
            <template slot-scope="scope">
              {{scope.row.package_info.name}}
            </template>
        </el-table-column>
        <el-table-column label="调整学分">
            <template slot-scope="scope">
                {{scope.row.value}}（原始：{{scope.row.package_info.point}}）
            </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="created_at"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="oepnModifyAdjust(scope.row)">修改</el-button>
            <el-button size="mini" type="danger" @click="deleteAdjust(scope.row)">删除</el-button>
          </template>
        </el-table-column>
    </el-table>

    <el-pagination
        @prev-click="changePage(page--)"
        @next-click="changePage(page++)"
        @current-change="changePage"
        :current-page="page"
        :page-size="pageSize"
        layout="prev, pager, next"
        :total="total"
    >
    </el-pagination>

    <el-dialog title="修改调整" :visible.sync="dialogAdjustVisible">
      <el-form :model="adjust" label-width="120px">
        <el-form-item label="套餐卡">
          <el-input v-model="adjust.package_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="所需学分">
          <el-input v-model="adjust.value"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogAdjustVisible = false">取 消</el-button>
        <el-button type="primary" @click="doModifyAdjust" :disabled="doModifyAdjustLock">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import {
  LIST_CUSTOMER_POINT_SETTING,
  DELETE_CUSTOMER_POINT_SETTING,
  SAVE_CUSTOMER_POINT_SETTING
} from '../api/point'
export default {
  data(){
    return {
      dayjs: (date) => {
          return dayjs(date)
      },
      tableData: [],
      page: 1,
      pageSize: 20,
      total: 0,
      loading: false,
      form: {
        search: '',
      },
      doModifyAdjustLock: false,
      dialogAdjustVisible: false,
      adjust: {
        id: 0,
        value: 0,
        package_name: ''
      }
    }
  },
  mounted(){
    this.listPointSettings()
  },
  methods: {
    listPointSettings(){
      this.loading = true
      LIST_CUSTOMER_POINT_SETTING({
        page: this.page,
        search: this.form.search,
      })
        .then(res => {
          console.log('res', res)
          this.tableData = res.data.data.data
          this.total = res.data.data.total
          this.loading = false
        })
        .catch(err => {
          console.error(err)
          this.loading = false
        })
    },
    changePage(page){
      this.page = page
      this.listPointSettings()
    },
    deleteAdjust(row){
      this.$confirm('是否删除该积分配置', '提醒', {
        type: 'warning'
      }).then(() => {
        DELETE_CUSTOMER_POINT_SETTING({
          id: row.id
        })
          .then(res => {
            this.$message.success('积分已确认')
            this.listPointSettings()
          })
      }).catch(() => {
                  
      });
    },
    changeKeyword(val){
      if(val == ''){
        this.listPointSettings()
      }
    },
    oepnModifyAdjust(row) {
      this.dialogAdjustVisible = true
      this.adjust = row
      this.adjust.package_name = row.package_info.name
    },
    doModifyAdjust() {
      this.doModifyAdjustLock = true
      SAVE_CUSTOMER_POINT_SETTING(this.adjust)
        .then(res => {
          this.$message.success('编辑成功')
          this.dialogAdjustVisible = false
          this.listPointSettings()
          this.doModifyAdjustLock = false
        })
        .catch(err => {
          console.error(err)
          this.$message.error('编辑失败')
          this.doModifyAdjustLock = false
        })
    },
  }
}
</script>