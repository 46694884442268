<template>
    <div class="home">
        <el-form :inline="true" size="small">
            <el-form-item>
                <el-button @click="openWikiEditor">创建wiki</el-button>
            </el-form-item>
            <el-form-item>
                <el-input v-model="search">
                    <el-button slot="append" icon="el-icon-search" placeholder="请输入需要查找的内容" @click="doSearch"></el-button>
                </el-input>
            </el-form-item>
        </el-form>
        
        
        <el-card class="box-card" v-for="wiki in wikis" :key="wiki.id" style="margin-bottom: 10px;">
            <div slot="header" class="clearfix">
                <span>由 {{wiki.user.show_name}} 创建 最后编辑于 {{wiki.updated_at}} </span>
                <el-button style="float: right; padding: 3px 0" type="text" @click="deleteWiki(wiki.id)">删除</el-button>
                <el-button style="float: right; padding: 3px 0; margin-right: 20px;" type="text" @click="editWiki(wiki.id, wiki.content)">编辑</el-button>
            </div>
            <!-- <div v-html="makeHtml(wiki.content)"></div> -->
            <VueShowdown
                :markdown="wiki.content"
                flavor="github"
                :options="{ emoji: true }"/>
        </el-card>
        
        <el-dialog
            title="编辑器"
            :visible.sync="dialogVisible"
            :fullscreen="true"
        >
            <mavon-editor v-model="context" :toolbars="toolbars" />
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="sendBolg">发送</el-button>
                <el-button @click="cancelWiki">取消</el-button>
            </div>
        </el-dialog>
        
    </div>
</template>

<script>
import {
    SAVE_WIKI,
    GET_WIKIS,
    DELETE_WIKI
} from '../api/wiki'

export default {
    name: 'Home',
    mounted(){
        this.getWikis()
    },
    data(){
        return {
            dialogVisible: false,
            search: '',
            relation_id: 0,
            htmlContent: '',
            context: ' ',//输入的数据
            wikis: [],
            toolbars: {
                bold: true, // 粗体
                italic: true, // 斜体
                header: true, // 标题
                underline: true, // 下划线
                mark: true, // 标记
                superscript: true, // 上角标
                quote: true, // 引用
                ol: true, // 有序列表
                link: true, // 链接
                imagelink: false, // 图片链接
                help: true, // 帮助
                code: true, // code
                subfield: true, // 是否需要分栏
                fullscreen: true, // 全屏编辑
                readmodel: true, // 沉浸式阅读
                undo: true, // 上一步
                trash: true, // 清空
                save: true, // 保存（触发events中的save事件）
                navigation: true // 导航目录
            }
        }
    },
    methods: {
        sendBolg () {
            SAVE_WIKI({
                relation_id: this.relation_id,
                content: this.context
            })
                .then(res => {
                    this.$message.success('wiki保存成功')
                    this.getWikis()
                    this.dialogVisible = false
                })
                .catch(err => {
                    this.$message.error(err.response.data)
                })
        },
        getWikis() {
            GET_WIKIS({
                search: this.search
            })
                .then(res => {
                    this.wikis = res.data.data
                })
        },
        // makeHtml(content) {
        //     return this.converter.makeHtml(content)
        // },
        deleteWiki(id) {
            this.$confirm('是否确定删除该wiki?删除后可能无法恢复', '警告', {
                type: 'warning'
            }).then(() => {
                DELETE_WIKI({relation_id: id})
                    .then(res => {
                        this.getWikis();
                    })
                    .catch(err => {
                        console.error(err)
                    })
            }).catch(() => {
                        
            });
        },
        editWiki(id, content) {
            this.dialogVisible = true
            this.relation_id = id
            this.context = content
        },
        cancelWiki() {
            this.dialogVisible = false
            this.relation_id = 0
            this.context = ''
        },
        openWikiEditor() {
            this.dialogVisible = true
            this.relation_id = 0
            this.context = ''
        },
        doSearch() {
            this.getWikis()
        }
    }
}
</script>

<style scoped>
.mavonEditor {
  width: 100%;
  height: 100%;
}
</style>