<template>
    <div class="package">
        <el-row>
            <el-col :span="24">
                <el-form :model="form" :inline="true" size="mini">
                    <el-form-item>
                        <el-button type="primary" @click="openAdd">添加套餐</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <el-table :data="tableData">
            <el-table-column label="套餐名" prop="name"></el-table-column>
            <el-table-column label="卡类型" prop="type">
                <template slot-scope="scope">
                    {{type[scope.row.type]}} {{scope.row.once == 1 ? '（一次性）' : ''}}
                </template>
            </el-table-column>
            <el-table-column label="原价" prop="original_price"></el-table-column>
            <el-table-column label="折后价" prop="discount_price"></el-table-column>
            <el-table-column label="可用时长" prop="times"></el-table-column>
            <el-table-column label="兑换积分" prop="point"></el-table-column>
            <el-table-column label="绑定团购" prop="deal_id">
                <template slot-scope="scope">
                    {{scope.row.deal_id != 0 ? '绑定：' + scope.row.deal_id : ''}}
                </template>
            </el-table-column>
            <el-table-column label="状态">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.status == 1 ? 'success' : 'danger'">{{status[scope.row.status]}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" prop="created_at"></el-table-column>
            <el-table-column label="修改时间" prop="updated_at"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="openEditor(scope.row)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog title="修改" :visible.sync="dialogFormVisible" :before-close="closeDialog">
            <el-form :model="pkg" ref="editor" label-width="120">
                <el-form-item label="套餐名" prop="name">
                    <el-input v-model="pkg.name"></el-input>
                </el-form-item>
                <el-form-item label="团购" prop="deal_id">
                    <el-select v-model="pkg.deal_id" placeholder="请选择">
                        <el-option
                            v-for="deal in dealList.filter(item => item.sale_status == 2)"
                            :key="deal.deal_id"
                            :label="deal.title"
                            :value="deal.deal_id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="原价" prop="original_price">
                    <el-input v-model="pkg.original_price" @click.native="selectValue($event)"></el-input>
                </el-form-item>
                <el-form-item label="折后价" prop="discount_price">
                    <el-input v-model="pkg.discount_price" @click.native="selectValue($event)"></el-input>
                </el-form-item>
                <el-form-item label="可用时长" prop="times">
                    <el-input-number v-model="pkg.times" :step="1" step-strictly @click.native="selectValue($event)"></el-input-number>
                </el-form-item>
                <el-form-item label="兑换积分" prop="times">
                    <el-input-number v-model="pkg.point" :step="1" step-strictly @click.native="selectValue($event)"></el-input-number>
                </el-form-item>
                <el-form-item label="卡类型" prop="status">
                    <el-select v-model="pkg.type" placeholder="请选择">
                        <el-option
                            v-for="(item, key) in type"
                            :key="key"
                            :label="item"
                            :value="key">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态" prop="status">
                    <el-select v-model="pkg.status" placeholder="请选择">
                        <el-option
                            v-for="(item, key) in status"
                            :key="key"
                            :label="item"
                            :value="key">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否一次性使用完毕" prop="once">
                    <el-switch
                        v-model="pkg.once"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        :active-value="1"
                        :inactive-value="0"
                    >
                    </el-switch>
                </el-form-item>
                <el-form-item label="一次性使用完毕单日扣时" prop="once_time">
                    <el-input v-model="pkg.once_time" @click.native="selectValue($event)"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="savePackage">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
// import dayjs from 'dayjs'
import {
    SAVE_PACKAGE,
    SEARCH_PACKAGE,
    GET_DEAL_LIST
} from '../api/package'
export default {
    name: 'Seat',
    data(){
        return {
            form: {},
            tableData: [],
            pkg: {
                id: 0,
                name: '',
                original_price: 0,
                discount_price: 0,
                times: 1,
                type: 'times',
                point: 0,
                status: '1',
                once: false,
                once_time: 0
            },
            dialogFormVisible: false,
            status: {
                '1': '可用',
                '2': '不可用'
            },
            type: {
                'times': '次卡',
                'hours': '储值卡',
            },
            dealList: []
        }
    },
    mounted(){
        this.searchPackage()
        this.getDealList()
    },
    methods: {
        getDealList() {
            GET_DEAL_LIST()
                .then(res => {
                    if (res.data.code != 400) {
                        this.dealList = res.data.data
                    }
                })
                .catch(err => {
                    console.error(err)
                    this.$message.error(err.response.data.data)
                })
        },
        savePackage(){
            SAVE_PACKAGE(this.pkg)
                .then(res => {
                    console.log(res)
                    this.searchPackage()
                    this.dialogFormVisible = false
                    this.resetForm()
                })
                .catch(err => {
                    this.$message.error(err.response.data)
                    console.error(err)
                })
        },
        searchPackage(){
            SEARCH_PACKAGE()
                .then(res => {
                    res.data.data.forEach(item => {
                        item.status = item.status.toString()
                    })
                    this.tableData = res.data.data
                })
                .catch(err => {
                    console.error(err)
                })
        },
        openEditor(row){
            this.dialogFormVisible = true
            this.pkg = Object.assign({}, row)
        },
        openAdd(){
            this.dialogFormVisible = true
        },
        closeDialog(){
            this.resetForm()
            this.dialogFormVisible = false
        },
        resetForm(){
            this.pkg = {
                id: 0,
                name: '',
                original_price: 0,
                discount_price: 0,
                times: 1,
                point: 0,
                status: '1'
            }
        },
        selectValue(e){
            console.log('selectValue', e)
            e.target.select()
        }
    }
}
</script>