<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
    data(){
        return {
        }
    },
    mounted(){
        console.log('children',this.$route)
        // this.active = this.$route.path
    },
    methods: {
        // logout(){
        //     LOGOUT()
        //         .then(res => {
        //             console.log('logout success', res)
        //             this.$router.push('/login')
        //         })
        //         .catch(err => {
        //             console.error('logout error', err)
        //         })
        // }
    }
}
</script>