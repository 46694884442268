let API_HOST = ''
const ENV = process.env.NODE_ENV

if (process.env.NODE_ENV === 'development') {
    // dev
    API_HOST = ''
} else {
    // prod
    API_HOST = 'https://api.viewinn.cn'
}

export { 
    API_HOST,
    ENV
}
