import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import routesSetting from './routesSetting'
import dayjs from 'dayjs'

Vue.use(VueRouter)

// // 解决报错
// const originalPush = VueRouter.prototype.push
// const originalReplace = VueRouter.prototype.replace
// // push
// VueRouter.prototype.push = function push (location:any, onResolve:any, onReject:any) {
//   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//   return originalPush.call(this, location).catch(err => err)
// }
// // replace
// VueRouter.prototype.replace = function push (location, onResolve, onReject) {
//   if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
//   return originalReplace.call(this, location).catch(err => err)
// }

const routes: Array<RouteConfig> = routesSetting

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//     var tokenTime:any = sessionStorage.getItem('token')
//     console.log('beforeEach', tokenTime, dayjs().diff(tokenTime, 'seconds'))
//     if(tokenTime == null || tokenTime == '' || dayjs().diff(tokenTime, 'seconds') > 3600){
//         store.commit('setToken', '')
//         store.commit('setTokenTime', '')
//         sessionStorage.setItem('token', '')
//         sessionStorage.setItem('tokenTime', '')
//         router.push('/login')
//         next()
//     }
//     next()
// })

router.beforeEach((to, from, next) => {
    var tokenTime:any = sessionStorage.getItem('token')
    console.log('beforeEach', to, from, tokenTime)
    if( to.name != 'Login'){
        if(tokenTime == null || tokenTime == '' || dayjs().diff(tokenTime, 'seconds') > 3600){
            // router.push('/login')
            next({name: 'Login'})
        }else{
            let permissionsStorage = JSON.parse(sessionStorage.getItem('permissions') || '0')
            let permissionsArray = []
            console.log('permissionsStorage', permissionsStorage)
            if(permissionsStorage != 0){
                permissionsStorage.forEach(perm => {
                    permissionsArray.push(perm.permission.key as never)
                })
                if(permissionsArray.includes(to.meta.key as never) || to.name == 'Error'){
                  next()
                }else{
                  next({name: 'Error'})
                }
                // console.log('this.permissions', this.permissions)
                // this.$set(this, 'permissions', permissionsArray)
            }else{
              next({name: 'Login'})
            }
        }
    }else{
        next()
    }
    // else{
    //     if(dayjs().diff(tokenTime, 'seconds') <= 3600){
    //         router.push('/')
    //     }
    // }
    // next()
})

export default router
