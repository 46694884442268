<template>
    <div class="book">
        <el-row>
            <el-col :span="24">
                <el-form :model="form" :inline="true" size="mini" @submit.native.prevent>
                    <el-form-item>
                        <el-button type="primary" @click="openAdd">创建门禁</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <el-table :data="tableData" v-loading="loading">
            <el-table-column label="房间" prop="room"></el-table-column>
            <el-table-column label="账号" prop="id_no">
                <template slot-scope="scope">
                    {{scope.row.id_no.padStart(3, '0')}}
                </template>
            </el-table-column>
            <el-table-column label="日期" prop="date"></el-table-column>
            <el-table-column label="密码" prop="password"></el-table-column>
            <el-table-column label="状态" prop="status">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.status == 1 ? 'success' : 'info'">{{scope.row.status == 1 ? '可用' : '不可用'}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" prop="created_at"></el-table-column>
            <el-table-column label="修改时间" prop="updated_at"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="danger" size="small" @click="deleteDoorLock(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            @prev-click="changePage(page--)"
            @next-click="changePage(page++)"
            @current-change="changePage"
            :current-page="page"
            :page-size="pageSize"
            layout="prev, pager, next"
            :total="total"
        >
        </el-pagination>

        <el-dialog title="创建门禁" :visible.sync="dialogFormVisible" :before-close="closeDialog">
            <el-form ref="editor" :model="edit" label-width="120">
                <el-form-item label="房间" prop="room">
                    <el-select v-model="edit.room" placeholder="请选择房间" clearable filterable>
                        <el-option v-for="item in rooms"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="日期" prop="date">
                    <el-date-picker 
                        v-model="edit.date" 
                        type="date" 
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                </el-form-item> -->
                <el-form-item label="是否可用" prop="status">
                    <el-switch 
                        v-model="edit.status" 
                        :active-value="1" 
                        :inactive-value="0"
                    >
                    </el-switch>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import {
    CREATE_DOOR_LOCK,
    GET_DOOR_LOCK,
    DELETE_DOOR_LOCK
} from '../api/doorLock'
export default {
    name: 'Book',
    data(){
        return {
            form: {},
            tableData: [],
            edit: {
                id: 0,
                room: '401',
                // date: dayjs().add(1, 'day').format('YYYY-MM-DD'),
                status: 1
            },
            dialogFormVisible: false,
            page: 1,
            pageSize: 1,
            total: 0,
            loading: false,
            rooms: [
                {
                    label: '310室',
                    value: '310'
                },
                {
                    label: '401室',
                    value: '401'
                }
            ]
        }
    },
    mounted(){
        this.search()
    },
    methods: {
        save(){
            CREATE_DOOR_LOCK(this.edit)
                .then(res => {
                    console.log(res)
                    this.search()
                    this.dialogFormVisible = false
                    this.resetForm()
                })
                .catch(err => {
                    this.$message.error(err.response.data)
                    console.error(err)
                })
        },
        changePage(page){
            this.page = page
            this.search()
        },
        search(){
            this.loading = true
            GET_DOOR_LOCK({
                page: this.page
            })
                .then(res => {
                    this.tableData = res.data.data.data
                    this.total = res.data.data.total
                    this.pageSize = res.data.data.per_page
                    this.loading = false
                })
                .catch(err => {
                    console.error(err)
                    this.$message.error(err.response.data)
                    this.loading = false
                })
        },
        openEditor(row){
            this.dialogFormVisible = true
            this.edit = Object.assign({}, row)
        },
        openAdd(){
            this.dialogFormVisible = true
        },
        closeDialog(){
            this.resetForm()
            this.dialogFormVisible = false
        },
        resetForm(){
            this.edit = {
                id: 0,
                room: '401',
                // date: dayjs().add(1, 'day').format('YYYY-MM-DD'),
                status: 1
            }
        },
        deleteDoorLock(row) {
            this.$confirm('是否确定删除该密码?删除后可能无法恢复', '警告', {
                type: 'warning'
            }).then(() => {
                DELETE_DOOR_LOCK({id: row.id})
                    .then(res => {
                        this.search();
                    })
                    .catch(err => {
                        console.error(err)
                    })
            }).catch(() => {
                        
            });
        }
    }
}
</script>