<template>
    <div id="app">
        <div v-if="!$route.meta.isLogin">
            <el-container>
                <el-header class="header" :style="{background: ENV() !== 'development' ? '#C8A564' : '#ec80e7'}">
                    <span>{{ENV() !== 'development' ? '不轻文化管理系统' : '不轻测试'}}</span>
                    <div>
                        <el-button 
                            v-if="meituanAuthStatus == '需要重新授权'"  
                            type="warning" 
                            style="margin-right: 10px;"
                        >
                            <el-link 
                                :underline="false"
                                style="color: white" 
                                :href="`https://e.dianping.com/dz-open/merchant/auth?app_key=${meituanAuth.app_key}&redirect_url=${meituanAuth.redirect_url}&state=${meituanAuth.state}`" 
                                target="_blank"
                            >
                                获取美团授权
                            </el-link>
                        </el-button>
                        
                        <el-button type="primary" @click="logout">退出登录</el-button>
                    </div>
                </el-header>
                <el-container>
                    <el-aside width="200px">
                        <div class="welcome">
                            {{username}}，欢迎回来
                        </div>
                        <el-menu
                            :default-active="active"
                            router
                            active-text-color="#C8A564"
                            unique-opened
                        >
                          <template
                            v-for="route in routes.filter(item => !item.meta.hide && permissions.includes(item.meta.key))"
                          >
                            <el-menu-item v-if="route.children == undefined" :index="route.meta.pathWithoutParams != undefined ? route.meta.pathWithoutParams : route.path" :key="route.name">
                              <i :class="route.meta.icon"></i>
                              <span slot="title">{{route.meta.label}}</span>
                            </el-menu-item>
                            <el-submenu v-else :index="route.path" :key="route.name">
                                <template slot="title">
                                    <i :class="route.meta.icon"></i>
                                    <span>{{route.meta.label}}</span>
                                </template>
                                <el-menu-item v-for="child in route.children" :key="child.name" :index="`${route.path}/${child.meta.pathWithoutParams != undefined ? child.meta.pathWithoutParams : child.path}`">{{child.meta.label}}</el-menu-item>
                            </el-submenu>
                          </template>
                        </el-menu>
                    </el-aside>
                    <el-container>
                        <el-main>
                            <router-view></router-view>
                        </el-main>
                    </el-container>    
                </el-container>
            </el-container>
        </div>
        <router-view v-if="$route.meta.isLogin"></router-view>
    </div>
</template>

<script>
// import dayjs from 'dayjs'
import routesSetting from './router/index'
import {
    ENV
} from '@/common/config.js'
import {LOGOUT, GET_MEITUAN_AUTH_INFOR,CHECK_MEITUAN_SESSION } from './api/auth'
export default {
    data(){
        return {
            active: '/',
            routes: [],
            ENV: _ => {
                return ENV
            },
            permissions: [],
            userid: '',
            username: '',
            meituanAuth: {},
            meituanAuthStatus: ''
        }
    },
    mounted(){
        console.log('routesSetting', routesSetting.options.routes)
        this.routes = routesSetting.options.routes
        console.log('app',this.$route)
        this.active = this.$route.path
        this.getPermission()
        this.checkMeituanSession()
    },
    watch: {
        // 方法1
        '$route' (to, from) { //监听路由是否变化
            this.active = this.$route.path
            this.getPermission()
        },
    },
    methods: {
        getMeituanAuthInfor() {
            GET_MEITUAN_AUTH_INFOR()
                .then(res => {
                    this.meituanAuth = res.data
                })
        },
        checkMeituanSession() {
            CHECK_MEITUAN_SESSION()
                .then(res => {
                    this.meituanAuthStatus = res.data
                    if (this.meituanAuthStatus == '需要重新授权') {
                        this.getMeituanAuthInfor()
                    }
                })
        },
        getPermission(){
            let permissionsStorage = JSON.parse(sessionStorage.getItem('permissions'))
            let permissionsArray = []
            console.log('permissionsStorage', permissionsStorage)
            if(permissionsStorage != null){
                permissionsStorage.forEach(perm => {
                    permissionsArray.push(perm.permission.key)
                })
                console.log('this.permissions', this.permissions)
                this.$set(this, 'permissions', permissionsArray)
            }

            this.userid = sessionStorage.getItem('userid')
            this.username = sessionStorage.getItem('username')
            this.username = sessionStorage.getItem('show_name')
        },
        logout(){
            LOGOUT()
                .then(res => {
                    console.log('logout success', res)
                    this.$router.push('/login')
                    sessionStorage.removeItem('show_name')
                    sessionStorage.removeItem('userid')
                    sessionStorage.removeItem('username')
                    sessionStorage.removeItem('userrole')
                    sessionStorage.removeItem('token')
                    sessionStorage.removeItem('tokenTime')
                    sessionStorage.removeItem('permissions')
                })
                .catch(err => {
                    console.error('logout error', err)
                })
        }
    }
}
</script>

<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    box-sizing: border-box;
    height: 100%;

    &>div {
        box-sizing: border-box;
        height: 100%;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.el-header, .el-footer {
    background-color: #C8A564;
    color: white;
    font-size: 24px;
    line-height: 60px;
}

.el-aside {
    // background-color: #D3DCE6;
    // color: #333;
    // line-height: 200px;
    height: 100%;
    box-sizing: border-box;
}

.el-main {
    background-color: #fff8eb;
    color: #333;
    height: 100%;
    box-sizing: border-box;
}

html,body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    height: 100%;
}

body > .el-container {
    margin-bottom: 40px;
    height: 100%;
}

body > .el-container {
    height: 100%;
}

.el-container {
  height: 100%;
}

.el-menu {
    height: 100%;
}
.welcome {
    text-align: center; 
    padding: 10px; 
    border-right: solid 1px #e6e6e6;
}
</style>
