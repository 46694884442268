import {fetchPost} from '../common/http.js'

export const SAVE_CUSTOMER=params=>fetchPost('/api/customer/saveCustomer', params)
export const SEARCH_CUSTOMER=params=>fetchPost('/api/customer/searchCustomer', params)
export const DO_CHARGE=params=>fetchPost('/api/customer/doCharge', params)
export const DO_REFUND=params=>fetchPost('/api/customer/doRefund', params)
export const DO_CARD_BUY=params=>fetchPost('/api/customer/doCardBuy', params)
export const DO_CARD_POINT_BUY=params=>fetchPost('/api/customer/doCardPointBuy', params)
export const DO_REFUND_CARD=params=>fetchPost('/api/customer/doRefundCard', params)
export const DO_MODIFY_CARD=params=>fetchPost('/api/customer/doModifyCard', params)
export const DO_MODIFY_POINT = params => fetchPost('/api/customer/doModifyPoint', params)
export const DO_MODIFY_NICKNAME = params => fetchPost('/api/customer/doModifyNickname', params)
export const DO_SET_CUSTOMER_POINT=params=>fetchPost('/api/customer/setCustomerPoint', params)
export const SET_FIRST_USE = params => fetchPost('/api/customer/setFirstUse', params)
export const RECEIPT_PREPARE = params => fetchPost('/api/meituan/receiptPrepare', params)
export const RECEIPT_CONSUME = params => fetchPost('/api/meituan/receiptConsume', params)
export const RECEIPT_REVERSE_CONSUME = params => fetchPost('/api/meituan/receiptReverseConsume', params)
export const MERGE_CARD = params => fetchPost('/api/customer/mergeCard', params)
export const GET_CUSTOMER_POINT=params=>fetchPost('/api/customer/getCustomerPoint', params)