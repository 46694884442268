<template>
    <div class="customer">
        <el-row>
            <el-col :span="24">
                <el-form :model="form" inline @submit.native.prevent>
                    <el-form-item>
                        <el-button type="success" @click="addCustomer">新增客人</el-button>
                    </el-form-item>
                    <el-form-item label="查找">
                        <el-input v-model="form.search" placeholder="请输入手机号或昵称查找" @keyup.enter.native="searchCustomer" clearable @change="changeKeyword"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="success" @click="searchCustomer">查找</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <el-table 
            :data="tableData" 
            v-loading="loading"
            row-key="id"
            :expand-row-keys="openedCustomer"
            @expand-change="openCustomer"
        >
            <el-table-column type="expand">
                <template slot-scope="scope">
                    <div style="padding: 0 10px;">
                        <p v-for="(card, card_idx) in scope.row.customer_packages" :key="card_idx">
                            {{`${card.package_info.name} 有效期：${card.expires}`}} 
                            <span v-if="card.package_info.type == 'times'" style="margin-right: 10px;">使用了 {{card.use_log.filter(item => item.status == 3 && item.times > 0).length}} 次</span>
                            <span v-if="card.package_info.type == 'times'" style="margin-right: 10px;">剩余：{{card.available_time / 6}} 次</span>
                            <span v-if="card.package_info.type == 'hours'" style="margin-right: 10px;">剩余：{{card.available_time}} 小时</span>
                            <span v-if="card.first_use == 1" style="margin-right: 10px;">首选</span>
                            <el-tag v-if="card.status == 1" type="danger" style="margin-left: 10px; margin-right: 10px;">用完</el-tag>
                            <el-tag v-else-if="card.status == 0 && dayjs(card.expires).isAfter(dayjs())" type="success" style="margin-left: 10px; margin-right: 10px;">可用</el-tag>
                            <el-tag v-else type="danger" style="margin-left: 10px; margin-right: 10px;">过期</el-tag>
                            <el-button type="text" size="small" @click="setStatus(scope.row, card.id)">编辑</el-button>
                            <el-button type="text" size="small" @click="setFirstUse(scope.row.id, card.id)">设为首选结账卡</el-button>
                            <el-button type="text" size="small" @click="openCancelCard(scope.row)">退卡</el-button>
                        </p>
                        <el-row>
                            <el-col :span="8">
                                <h5>购卡记录</h5>
                                <p v-for="(billing, billing_idx) in scope.row.billing_package" :key="billing_idx">
                                    {{billing.created_at}}&nbsp;&nbsp;
                                    {{billing.remark}}&nbsp;&nbsp;
                                    <template v-if="billing.package_info != null">
                                        {{billing.package_info.name}}&nbsp;&nbsp;
                                        {{billing.package_info.times}}
                                    </template>
                                </p>
                            </el-col>
                            <el-col :span="8">
                                <h5>使用记录</h5>
                                <p v-for="(appt, appt_idx) in scope.row.customer_appointment" :key="appt_idx">
                                    {{appt.date}}&nbsp;&nbsp;{{appt.start_time}} - {{appt.end_time}} {{appt.seat != null ? appt.seat.name : '未知坐席'}} {{status[appt.status]}}
                                    <span v-if="appt.checkout_log !== null && appt.checkout_log.customer_package !== null">{{ appt.checkout_log.customer_package.package_info.name }}</span>
                                </p>
                            </el-col>
                            <el-col :span="8">
                                <h5>积分记录</h5>
                                <p v-for="(pl, pl_idx) in scope.row.customer_point_log" :key="pl_idx">
                                    {{pl.created_at}}&nbsp;&nbsp;{{pl.reason}} 学分：{{pl.point}} <el-button v-if="pl.status == 1" type="primary" size="mini" @click="confirmPoint(pl)">确认</el-button>
                                </p>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="16">
                                <h5>签到结账记录</h5>
                                <p v-for="(appt, appt_idx) in scope.row.customer_appointment" :key="appt_idx">
                                    {{appt.date}}&nbsp;&nbsp;{{appt.start_time}} - {{appt.end_time}} 
                                    <span v-if="appt.stauts == 4 || appt.status == 5">取消</span>
                                    <span v-if="appt.checkin_log !== null">签到：{{ appt.checkin_log.created_at }} <el-tag type="warning" v-if="dayjs(appt.checkin_log.created_at).isAfter(dayjs(`${appt.date} ${appt.start_time}:00`).add(30, 'minutes'))">超时签到</el-tag></span>&nbsp;&nbsp;&nbsp;
                                    <span v-if="appt.checkout_log !== null">结账：{{ appt.checkout_log.created_at }} <el-tag type="warning" v-if="dayjs(appt.checkout_log.created_at).isAfter(dayjs(`${appt.date} ${appt.end_time}:00`).add(30, 'minutes'))">超时结账</el-tag></span>
                                </p>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <h5>备注</h5>
                                {{scope.row.remark}}
                            </el-col>
                            <el-col :span="24">
                                <h5>禁用坐席</h5>
                                <span v-for="seat in scope.row.customer_seat" :key="seat.id">{{seat.seat.name}} </span>
                            </el-col>
                        </el-row>
                    </div>
                </template>
            </el-table-column>
            <!-- <el-table-column label="unionid" prop="unionid"></el-ta
            ble-column> -->
            <!-- <el-table-column label="openid" prop="openid"></el-table-column> -->
            <!-- <el-table-column label="姓名" prop="name"></el-table-column> -->
            <el-table-column label="昵称" width="150">
                <template slot-scope="scope">
                    <span @click="oepnModifyNickname(scope.row)">{{scope.row.nickname}}</span>&nbsp;&nbsp;<i class="el-icon-warning" v-if="scope.row.blacklist == 1"></i>
                </template>
            </el-table-column>
            <el-table-column label="手机" prop="cellphone" width="150"></el-table-column>
            <el-table-column label="头像" width="50">
                <template slot-scope="scope">
                    <img :src="scope.row.headimgurl" width="30" height="30">
                </template>
            </el-table-column>
            <el-table-column label="可用时间" prop="times" width="100"></el-table-column>
            <el-table-column label="剩余学分" prop="points" width="100">
              <template slot-scope="scope">
                {{scope.row.points}} <el-button type="text" style="padding: 0" @click="setPoint(scope.row)" size="mini">修改</el-button>
              </template>
            </el-table-column>
            <el-table-column label="创建时间" prop="created_at" width="120">
                <template slot-scope="scope">
                    {{dayjs(scope.row.created_at).format('YYYY-MM-DD')}}<br/>
                    {{dayjs(scope.row.created_at).format('HH:mm:ss')}}
                </template>
            </el-table-column>
            <el-table-column label="修改时间" prop="updated_at" width="120">
                <template slot-scope="scope">
                    {{dayjs(scope.row.updated_at).format('YYYY-MM-DD')}}<br/>
                    {{dayjs(scope.row.updated_at).format('HH:mm:ss')}}
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="openEditor(scope.row)">编辑</el-button>
                    <el-button type="text" size="small" @click="openSeat(scope.row)">坐席</el-button>
                    <el-button type="text" size="small" @click="openConsume(scope.row)">验券</el-button>
                    <el-button type="text" size="small" @click="openCard(scope.row)">购卡</el-button>
                    <el-button type="text" size="small" @click="mergeCard(scope.row)">并卡</el-button>
                    <el-button type="text" size="small" @click="openCardPoint(scope.row)">兑卡</el-button>
                    <el-button type="text" size="small" @click="openCharge(scope.row)" v-show="false">充值</el-button>
                    <el-button type="text" size="small" @click="openPointSetting(scope.row)">积分</el-button>
                    <el-button type="text" size="small" @click="openRefund(scope.row)">退款</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            @prev-click="changePage(page--)"
            @next-click="changePage(page++)"
            @current-change="changePage"
            :current-page="page"
            :page-size="pageSize"
            layout="prev, pager, next"
            :total="total"
        >
        </el-pagination>

        <el-dialog title="修改" :visible.sync="dialogFormVisible">
            <el-form :model="customer" label-width="120">
                <el-form-item label="昵称">
                    <el-input v-model="customer.nickname" :disabled="customer.id > 0"></el-input>
                </el-form-item>
                <el-form-item label="手机号">
                    <el-input v-model="customer.cellphone" :disabled="customer.id > 0"></el-input>
                </el-form-item>
                <el-form-item label="可用时间">
                    <el-input-number v-model="customer.times" :step="1" step-strictly></el-input-number>
                </el-form-item>
                <el-form-item label="可用学分">
                    <el-input-number v-model="customer.points" :step="1" step-strictly disabled></el-input-number>
                </el-form-item>
                <el-form-item label="黑名单">
                    <el-checkbox v-model="customer.blacklist"  :true-label=1 :false-label=0>黑名单</el-checkbox>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="customer.remark" type="textarea" :rows="2"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveCustomer" :disabled="saveCustomerLock">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="充值" :visible.sync="dialogChargeVisible">
            <el-form :model="charge" label-width="120px">
                <el-form-item label="金额">
                    <el-input v-model="charge.amount"></el-input>
                </el-form-item>
                <el-form-item label="时长">
                    <el-input v-model="charge.times"></el-input>
                </el-form-item>
                <el-form-item label="支付方式">
                    <el-select v-model="charge.method" placeholder="请选择">
                        <el-option 
                            v-for="(method, method_idx) in paymethod" 
                            :key="method_idx"
                            :value="method_idx"
                            :label="method"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <div v-show="charge.alipay.subject != ''">
                    <el-form-item label="标题">
                        <el-input v-model="charge.alipay.subject"></el-input>
                    </el-form-item>
                    <el-form-item label="订单号">
                        <el-input v-model="charge.alipay.out_trade_no"></el-input>
                    </el-form-item>
                    <el-form-item label="金额">
                        <el-input v-model="charge.alipay.total_amount"></el-input>
                    </el-form-item>
                    <el-form-item label="条码">
                        <el-input ref="alipay_charge_buyer_id" autofocus v-model="charge.alipay.buyer_id" @keyup.enter.native="sendPay('charge')"></el-input>
                    </el-form-item>
                </div>
                <div v-show="charge.wechat.subject != ''">
                    <el-form-item label="标题">
                        <el-input v-model="charge.wechat.subject"></el-input>
                    </el-form-item>
                    <el-form-item label="订单号">
                        <el-input v-model="charge.wechat.out_trade_no"></el-input>
                    </el-form-item>
                    <el-form-item label="金额">
                        <el-input v-model="charge.wechat.total_amount"></el-input>
                    </el-form-item>
                    <el-form-item label="条码">
                        <el-input ref="wechat_charge_buyer_id" autofocus v-model="charge.wechat.buyer_id" @keyup.enter.native="sendPayWechat('charge')"></el-input>
                    </el-form-item>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogChargeVisible = false">取 消</el-button>
                <el-button type="primary" @click="doCharge" :disabled="doChargeLock">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="退款" :visible.sync="dialogRefundVisible">
            <el-form :model="refund" label-width="120px">
                <el-form-item label="金额">
                    <el-input v-model="refund.amount"></el-input>
                </el-form-item>
                <el-form-item label="时长">
                    <el-input v-model="refund.times"></el-input>
                </el-form-item>
                <el-form-item label="支付方式">
                    <el-select v-model="refund.method" placeholder="请选择">
                        <el-option 
                            v-for="(method, method_idx) in paymethod" 
                            :key="method_idx"
                            :value="method_idx"
                            :label="method"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogRefundVisible = false">取 消</el-button>
                <el-button type="primary" @click="doRefund" :disabled="doRefundLock">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="购卡" :visible.sync="dialogCardVisible">
            <el-form :model="card" label-width="120px">
                <el-form-item label="套餐卡">
                    <el-select v-model="card.package_id" @change="changeCard" placeholder="请选择">
                        <el-option 
                            v-for="(pkg, pkc_idx) in packages" 
                            :key="pkc_idx"
                            :value="pkg.id"
                            :label="pkg.name"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="金额">
                    <el-input v-model="card.amount"></el-input>
                </el-form-item>
                <el-form-item label="有效期">
                    <el-input v-model="card.expiry"></el-input>
                </el-form-item>
                <el-form-item label="快捷支付">
                    <!-- <el-button type="success" @click="setPayment('wechat')" :disabled="card.amount == '' || card.amount <= 0">微信</el-button>
                    <el-button type="primary" @click="setPayment('alipay')" :disabled="card.amount == '' || card.amount <= 0">支付宝</el-button> -->
                    <el-button type="warning" @click="setUtilPayment" :disabled="card.amount == '' || card.amount <= 0">快捷支付</el-button>
                </el-form-item>
                <el-form-item label="其他支付方式">
                    <el-select v-model="card.method" placeholder="请选择">
                        <el-option 
                            v-for="(method, method_idx) in paymethod" 
                            :key="method_idx"
                            :value="method_idx"
                            :label="method"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否开启盲盒">
                    <el-switch
                        v-model="card.manghe"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                    >
                    </el-switch>
                </el-form-item>
                <div v-show="card.method == 'alipay'">
                    <el-form-item label="标题">
                        <el-input v-model="card.alipay.subject"></el-input>
                    </el-form-item>
                    <el-form-item label="订单号">
                        <el-input v-model="card.alipay.out_trade_no"></el-input>
                    </el-form-item>
                    <el-form-item label="金额">
                        <el-input v-model="card.alipay.total_amount"></el-input>
                    </el-form-item>
                    <el-form-item label="条码">
                        <el-input ref="alipay_card_buyer_id" autofocus v-model="card.alipay.buyer_id" @keyup.enter.native="sendPay('card')"></el-input>
                    </el-form-item>
                </div>
                <div v-show="card.method == 'wechat'">
                    <el-form-item label="标题">
                        <el-input v-model="card.wechat.subject"></el-input>
                    </el-form-item>
                    <el-form-item label="订单号">
                        <el-input v-model="card.wechat.out_trade_no"></el-input>
                    </el-form-item>
                    <el-form-item label="金额">
                        <el-input v-model="card.wechat.total_amount"></el-input>
                    </el-form-item>
                    <el-form-item label="条码">
                        <el-input ref="wechat_card_buyer_id" autofocus v-model="card.wechat.buyer_id" @keyup.enter.native="sendPayWechat('card')"></el-input>
                    </el-form-item>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogCardVisible = false">取 消</el-button>
                <el-button type="primary" @click="doCardBuy" :disabled="cardBuyLock">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog :title="`购卡 当前剩余学分：${customer.point}`" :visible.sync="dialogCardPointVisible">
            <el-form :model="cardPoint" label-width="120px">
                <el-form-item label="套餐卡">
                    <el-select v-model="cardPoint.package_id" @change="changeCard" placeholder="请选择">
                        <el-option 
                            v-for="(pkg, pkc_idx) in packages.filter(item => item.point > 0)" 
                            :key="pkc_idx"
                            :value="pkg.id"
                            :label="`${pkg.name} 所需积分：${pkg.point}`"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogCardPointVisible = false">取 消</el-button>
                <el-button type="primary" @click="doCardPointBuy" :disabled="cardBuyPointLock">确 定</el-button>
            </div>
        </el-dialog>
        
        <el-dialog title="退卡" :visible.sync="dialogRefundCardVisible">
            <el-form :model="refundCard" label-width="120px">
                <el-form-item label="金额">
                    <el-input v-model="refundCard.amount"></el-input>
                </el-form-item>
                <el-form-item label="时长">
                    <el-input v-model="refundCard.times"></el-input>
                </el-form-item>
                <el-form-item label="学分">
                    <el-input v-model="refundCard.points"></el-input>
                </el-form-item>
                <el-form-item label="支付方式">
                    <el-select v-model="refundCard.method" placeholder="请选择">
                        <el-option 
                            v-for="(method, method_idx) in paymethod" 
                            :key="method_idx"
                            :value="method_idx"
                            :label="method"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogRefundCardVisible = false">取 消</el-button>
                <el-button type="primary" @click="doRefundCard" :disabled="doRefundCardLock">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="编辑卡" :visible.sync="dialogModifyCardVisible">
            <el-form :model="modifyCard" label-width="120px">
                <el-form-item label="是否可用">
                    <el-select v-model="modifyCard.status" placeholder="请选择">
                        <el-option :value=0 label="可用"></el-option>
                        <el-option :value=1 label="用完"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="可用时间">
                    <el-input v-model="modifyCard.available_time"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogModifyCardVisible = false">取 消</el-button>
                <el-button type="primary" @click="doModifyCard" :disabled="doModifyCardLock">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="编辑学分" :visible.sync="dialogModifyPointVisible">
            <el-form :model="modifyPoint" label-width="120px">
                <el-form-item label="操作类别">
                    <el-select v-model="modifyPoint.type" placeholder="请选择">
                        <el-option :value=1 label="存入"></el-option>
                        <el-option :value=2 label="扣除"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="需调整积分">
                    <el-input v-model="modifyPoint.point"></el-input>
                </el-form-item>
                <el-form-item label="原因">
                    <el-input v-model="modifyPoint.reason"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogModifyPointVisible = false">取 消</el-button>
                <el-button type="primary" @click="doModifyPoint" :disabled="doModifyPointLock">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="编辑坐席" :visible.sync="dialogSeatVisible">
            <el-form :model="modifySeat" label-width="120px">
                <el-form-item label="坐席">
                    <el-select v-model="modifySeat.seat_ids" clearable filterable placeholder="请选择坐席" multiple>
                        <el-option
                            v-for="(item, key) in seat"
                            :key="key"
                            :label="`${item.room}-${item.name}`"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogSeatVisible = false">取 消</el-button>
                <el-button type="primary" @click="doModifySeat" :disabled="doModifySeatLock">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="编辑昵称" :visible.sync="dialogNicknameVisible">
            <el-form :model="customer" label-width="120px">
                <el-form-item label="昵称">
                    <el-input v-model="customer.nickname"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogNicknameVisible = false">取 消</el-button>
                <el-button type="primary" @click="doModifyNickname" :disabled="doModifyNicknameLock">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="设定积分" :visible.sync="dialogPointSettingVisible">
            <el-form :model="modifyPointSetting" label-width="120px">
                <el-form-item label="坐席">
                    <el-select v-model="modifyPointSetting.package_id" filterable placeholder="请选择套餐" @change="changePointSetting">
                        <el-option
                            v-for="(item, key) in package_settings"
                            :key="key"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="设定积分">
                    <el-input v-model="modifyPointSetting.value"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogPointSettingVisible = false">取 消</el-button>
                <el-button type="primary" @click="doModifyPointSetting" :disabled="doModifyPointSettingLock">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="并卡" :visible.sync="dialogMergeCardVisible">
            <el-form :model="cardMerge" label-width="120px" v-if="customer.customer_packages != undefined">
                <el-form-item label="来源套餐卡">
                    <el-select v-model="cardMerge.from_package" placeholder="请选择">
                        <el-option 
                            v-for="(pkg, pkc_idx) in customer.customer_packages.filter(card => card.available_time > 0)" 
                            :key="pkc_idx"
                            :value="pkg.id"
                            :label="`${pkg.package_info.name} 剩余：${pkg.available_time}`"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="合并到">
                    <el-select v-model="cardMerge.to_package" placeholder="请选择">
                        <el-option 
                            v-for="(pkg, pkc_idx) in customer.customer_packages.filter(card => card.available_time > 0)" 
                            :key="pkc_idx"
                            :value="pkg.id"
                            :label="`${pkg.package_info.name} 剩余：${pkg.available_time}`"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogMergeCardVisible = false">取 消</el-button>
                <el-button type="primary" @click="doMergeCard" :disabled="mergeCardLock">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog
            title="扫码支付"
            :visible.sync="payCodeVisible"
            width="30%"
        >
            <el-row :gutter="20">
                <el-col :span="24" :offset="0">请使用扫码枪，扫描客户手机中微信或者支付宝的付款码。扫码时候，需要保持下列输入框激活状态。</el-col>
                <el-col :span="24" :offset="0">
                    <el-input ref="code_number" v-model="codeNumber" @keyup.enter.native="setCode"></el-input>
                </el-col>
            </el-row>
        </el-dialog>

        <el-dialog
            title="通用扫码支付(不用区分支付宝或微信)"
            :visible.sync="payCodeUtilVisible"
            width="30%"
        >
            <el-row :gutter="20">
                <el-col :span="24" :offset="0">请使用扫码枪，扫描客户手机中微信或者支付宝的付款码。扫码时候，需要保持下列输入框激活状态。</el-col>
                <el-col :span="24" :offset="0">
                    <el-input ref="code_number_util" v-model="codeNumberUtil" size="medium" @keyup.enter.native="setCodeUtil"></el-input>
                </el-col>
            </el-row>
        </el-dialog>

        <el-dialog
            title="美团验券"
            :visible.sync="dialogConsumeVisible"
            width="30%"
        >
            <el-row :gutter="20">
                <el-col :span="24" :offset="0">请使用扫码枪，扫描客户手机中美团券码。扫码时候，需要保持下列输入框激活状态。</el-col>
                <el-col :span="24" :offset="0">
                    <el-input ref="meituan_code_number" autofocus v-model="codeNumberMeituan" @keyup.enter.native="setCodeMeituan"></el-input>
                </el-col>
            </el-row>
        </el-dialog>

        <el-dialog
            title="支付结果"
            :visible.sync="payResultVisible"
            width="30%"
        >
            <el-result icon="success" title="本单已支付"></el-result>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="payResultVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import {
    SAVE_CUSTOMER,
    SEARCH_CUSTOMER,
    DO_CHARGE,
    DO_REFUND,
    DO_CARD_BUY,
    DO_CARD_POINT_BUY,
    DO_REFUND_CARD,
    DO_MODIFY_CARD,
    DO_MODIFY_POINT,
    SET_FIRST_USE,
    RECEIPT_PREPARE,
    RECEIPT_CONSUME,
    MERGE_CARD,
    DO_MODIFY_NICKNAME,
    DO_SET_CUSTOMER_POINT,
    GET_CUSTOMER_POINT
} from '../api/customer'
import {
    SEND_PAY,
    CHECK_PAY,
    SEND_PAY_WECHAT,
    CHECK_PAY_WECHAT,
    UPDATE_PAY_STATUS,
} from '../api/billing'
import {
    SEARCH_PACKAGE,
} from '../api/package'
import {
  CONFIRM_POINT
} from '../api/point'
import {
    SEARCH_SEAT,
    SET_CUSTOMER_SEAT,
    GET_CUSTOMER_SEAT
} from '../api/seat'
export default {
    name: 'Customer',
    data(){
        return {
            dayjs: (date) => {
                return dayjs(date)
            },
            payLoading: '',
            tableData: [],
            customer: {
                points: 0
            },
            dialogFormVisible: false,
            dialogSeatVisible: false,
            dialogChargeVisible: false,
            dialogPointSettingVisible: false,
            dialogRefundVisible: false,
            dialogCardVisible: false,
            dialogConsumeVisible: false,
            dialogCardPointVisible: false,
            dialogRefundCardVisible: false,
            dialogModifyCardVisible: false,
            dialogModifyPointVisible: false,
            payCodeVisible: false,
            payCodeUtilVisible: false,
            payResultVisible: false,
            dialogMergeCardVisible: false,
            dialogNicknameVisible: false,
            codeNumber: '',
            codeNumberUtil: '',
            codeNumberMeituan: '',
            form: {
                search: ''
            },
            charge: {
                customer_id: '',
                amount: '',
                times: '',
                method: '',
                alipay: {
                    subject: '',
                    out_trade_no: '',
                    total_amount: '',
                    buyer_id: '',
                },
                wechat: {
                    subject: '',
                    out_trade_no: '',
                    total_amount: '',
                    buyer_id: '',
                },
            },
            refund: {
                customer_id: '',
                amount: '',
                times: '',
            },
            card: {
                package_id: '',
                amount: '',
                expiry: '',
                method: '',
                alipay: {
                    subject: '',
                    out_trade_no: '',
                    total_amount: '',
                    buyer_id: '',
                },
                wechat: {
                    subject: '',
                    out_trade_no: '',
                    total_amount: '',
                    buyer_id: '',
                },
            },
            cardPoint: {
              customer_id: '',
              package_id: '',
            },
            refundCard: {
                customer_id: '',
                customer_package_id: '',
                amount: '',
                times: '',
                points: '',
            },
            modifyCard: {
                customer_id: '',
                customer_package_id: '',
                status: 0,
                available_time: 0,
            },
            cardMerge: {
                customer_id: '',
                from_package: '',
                to_package: '',
            },
            modifyPoint: {
                customer_id: '',
                operation_key: 'manual_operation',
                type: '',
                point: 0,
                reason: ''
            },
            modifySeat: {
                customer_id: '',
                seat_ids: []
            },
            modifyPointSetting: {
                customer_id: '',
                package_id: '',
                value: '',
            },
            billing: {},
            packages: [],
            status: {
                '0': '预约中',
                '2': '使用中',
                '3': '已结账',
                '4': '已空出',
                '5': '取消预约',
                '6': '包周',
                '7': '包月',
            },
            paymethod: {
                'meituan': '美团点评',
                'wechat_qr': '微信自助扫码',
                'alipay_qr': '支付宝自助扫码',
                'cash': '现金',
                'alipay': '扫客户支付宝付款码',
                'wechat': '扫客户微信付款码',
            },
            cardBuyLock: false,
            cardBuyPointLock: false,
            saveCustomerLock: false,
            doChargeLock: false,
            doRefundLock: false,
            doRefundCardLock: false,
            doModifyCardLock: false,
            doModifyPointLock: false,
            doModifySeatLock: false,
            doModifyNicknameLock: false,
            doModifyPointSettingLock: false,
            mergeCardLock: false,
            page: 1,
            pageSize: 1,
            total: 0,
            loading: false,
            openedCustomer: [],
            seat: [],
            order: {},
            consumeLoading: '',
            package_settings: []
        }
    },
    mounted(){
        console.log('this.$router', this.$route)
        let {search, status} = this.$route.params
        if(search != undefined){
            this.form.search = search
        }
        if(status != undefined && status == 1){
            this.payResultVisible = true
        }
        this.searchCustomer()
        this.listPackage()
        this.searchSeat()
    },
    methods: {
        receiptPrepare() { 
            RECEIPT_PREPARE({
                receipt_code: this.codeNumberMeituan
            })
                .then(res => {
                    if (res.data.code == 0) {
                        this.order = res.data.data
                        console.log('this.order', this.order)

                        this.$confirm(`团购${this.order.deal_title}${this.order.count}张（金额：${this.order.deal_price}），是否确认验券？`, '提醒', {
                            type: 'warning'
                        }).then(() => {
                            this.receiptConsume()
                        }).catch(() => {

                        });
                    } else {
                        this.$message.warning(res.data.data)
                    }
                })
                .catch(err => {
                    console.error(err)
                    this.$message.error(err.response.data.data)
                })
        },
        receiptConsume() { 
            this.consumeLoading = this.$loading({
                lock: true,
                text: '验券中，请勿刷新页面，请勿输入其他内容，等待系统反馈。如长时间无反应请联系系统管理员。',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            RECEIPT_CONSUME({
                requestid: this.order.deal_id.toString(),
                receipt_code: this.codeNumberMeituan,
                count: this.order.count
            })
                .then(res => {
                    res.data.data.forEach(item => {
                        // this.card = [...this.card, ...[item]]
                        this.card = Object.assign(this.card, item)
                        this.card.expiry = dayjs().add(1, 'year').format('YYYY-MM-DD')
                        this.card.method = 'meituan'
                        this.doCardBuy()
                    })
                    this.consumeLoading.close()
                })
                .catch(err => {
                    console.error(err)
                    this.$message.error(err.response.data.data)
                    this.consumeLoading.close()
                })
        },
        searchSeat(){
            SEARCH_SEAT()
                .then(res => {
                    console.log(res)
                    this.seat = res.data.data
                })
                .catch(err => {
                    this.$message.error(err.response.data)
                    console.error(err)
                })
        },
        listPackage(){
            SEARCH_PACKAGE({status: 1})
                .then(res => {
                    this.packages = res.data.data
                })
                .catch(err => {
                    this.$message.error(err.response.data)
                    console.error(err)
                })
        },
        changePage(page){
            this.page = page
            this.searchCustomer()
        },
        saveCustomer(){
            this.saveCustomerLock = true
            SAVE_CUSTOMER(this.customer)
                .then(res => {
                    console.log(res)
                    if(res.data.code == 0){
                        this.searchCustomer()
                        this.dialogFormVisible = false
                    }else{
                        this.$message.error(res.data.data)
                    }
                    this.saveCustomerLock = false
                })
                .catch(err => {
                    this.$message.error(err.response.data)
                    console.error(err)
                    this.saveCustomerLock = false
                })
        },
        searchCustomer(){
            this.loading = true
            SEARCH_CUSTOMER({page: this.page, search: this.form.search})
                .then(res => {
                    this.tableData = res.data.data.data
                    this.total = res.data.data.total
                    this.pageSize = res.data.data.per_page
                    this.loading = false
                })
                .catch(err => {
                    console.error(err)
                    this.loading = false
                })
        },
        openEditor(row){
            this.dialogFormVisible = true
            this.customer = row
        },
        openSeat(row){
            this.dialogSeatVisible = true
            this.modifySeat.customer_id = row.id
            this.modifySeat.seat_ids = []
            row.customer_seat.forEach(seat => {
                this.modifySeat.seat_ids.push(seat.id)
            })
        },
        openCard(row){
            this.dialogCardVisible = true
            this.customer = row
        },
        openConsume(row) { 
            this.dialogConsumeVisible = true
            this.customer = row
            this.$nextTick(_ => {
                this.$refs['meituan_code_number'].focus()
            })
        },
        openCardPoint(row){
            this.dialogCardPointVisible = true
            this.customer = row
        },
        openCancelCard(row){
            this.dialogRefundCardVisible = true
            this.customer = row
        },
        mergeCard(row) { 
            this.dialogMergeCardVisible = true
            this.customer = row
        },
        setStatus(row, customer_package_id){
            this.dialogModifyCardVisible = true
            this.modifyCard.customer_id = row.id
            this.modifyCard.customer_package_id = customer_package_id
        },
        setPoint(row){
            this.dialogModifyPointVisible = true
            this.modifyPoint.customer_id = row.id
        },
        openCharge(row){
            this.dialogChargeVisible = true
            this.customer = row
        },
        openPointSetting(row){
            this.dialogPointSettingVisible = true
            this.customer = row

            GET_CUSTOMER_POINT({
                customer_id: this.customer.id
            })
                .then(res => {
                    this.package_settings = res.data.data
                })
        },
        openRefund(row){
            this.dialogRefundVisible = true
            this.customer = row
        },
        doCharge(){
            this.doChargeLock = true
            this.charge.customer_id = this.customer.id
            DO_CHARGE(this.charge)
                .then(res => {
                    // this.$message.success('充值成功')
                    this.billing = res.data.data.billing
                    if(this.charge.method == 'alipay'){
                        this.charge.alipay.subject = '充值'
                        this.charge.alipay.out_trade_no = `charge-${this.billing.id}`
                        this.charge.alipay.total_amount = this.billing.amount
                        // this.$nextTick(_ => {
                        //     this.$refs['alipay_charge_buyer_id'].focus()
                        // })
                    }else 
                    if(this.charge.method == 'wechat'){
                        this.charge.wechat.subject = '充值'
                        this.charge.wechat.out_trade_no = `charge-${this.billing.id}`
                        this.charge.wechat.total_amount = this.billing.amount
                        // this.$nextTick(_ => {
                        //     this.$refs['wechat_charge_buyer_id'].focus()
                        // })
                    }
                    // this.searchCustomer()
                    this.doChargeLock = false
                })
                .catch(err => {
                    console.error(err)
                    this.$message.error('充值失败')
                    this.doChargeLock = false
                })
        },
        doRefund(){
            this.doRefundLock = true
            this.refund.customer_id = this.customer.id
            DO_REFUND(this.refund)
                .then(res => {
                    
                    this.searchCustomer()
                    this.doRefundLock = false
                })
                .catch(err => {
                    console.error(err)
                    this.$message.error('退款失败')
                    this.doRefundLock = false
                })
        },
        doCardBuy(callback){
            this.cardBuyLock = true
            this.card.customer_id = this.customer.id
            DO_CARD_BUY(this.card)
                .then(res => {
                    // this.$message.success('充值成功')
                    this.billing = res.data.data.billing
                    if(this.card.method == 'alipay'){
                        this.card.alipay.subject = '购买套餐'
                        this.card.alipay.out_trade_no = `package-buy-${this.billing.id}`
                        this.card.alipay.total_amount = this.billing.amount
                        // this.$nextTick(_ => {
                        //     this.$refs['alipay_card_buyer_id'].focus()
                        // })
                    }else if(this.card.method == 'wechat'){
                        this.card.wechat.subject = '购买套餐'
                        this.card.wechat.out_trade_no = `package-buy-${this.billing.id}`
                        this.card.wechat.total_amount = this.billing.amount
                        // this.$nextTick(_ => {
                        //     this.$refs['wechat_card_buyer_id'].focus()
                        // })
                    } else {
                        this.$message.success('购卡成功')
                        this.dialogCardVisible = false
                        this.dialogConsumeVisible = false
                        this.searchCustomer()
                    }
                    // this.searchCustomer()
                    this.cardBuyLock = false
                    if(callback != undefined && typeof callback == 'function'){
                        callback()
                    }
                })
                .catch(err => {
                    console.error(err)
                    this.$message.error('购买套餐失败')
                    this.cardBuyLock = false
                })
        },
        doCardPointBuy(){
          this.cardBuyPointLock = true
          this.cardPoint.customer_id = this.customer.id
          this.cardPoint.expiry = dayjs().add(1, 'year').format('YYYY-MM-DD')
          DO_CARD_POINT_BUY(this.cardPoint)
              .then(res => {
                  this.dialogCardVisible = false
                  this.searchCustomer()
                  this.cardBuyPointLock = false
              })
              .catch(err => {
                  console.error(err)
                  this.$message.error('兑换套餐失败')
                  this.cardBuyPointLock = false
              })
        },
        doRefundCard(){
            this.doRefundCardLock = true
            this.refundCard.customer_id = this.customer.id
            this.refundCard.customer_package_id = this.customer.customer_package.id
            DO_REFUND_CARD(this.refundCard)
                .then(res => {
                    this.$message.success('退卡成功')
                    this.dialogRefundCardVisible = false
                    this.searchCustomer()
                    this.doRefundCardLock = false
                })
                .catch(err => {
                    console.error(err)
                    this.$message.error('退卡失败')
                    this.doRefundCardLock = false
                })
        },
        doModifyCard(){
            this.doModifyCardLock = true
            
            DO_MODIFY_CARD(this.modifyCard)
                .then(res => {
                    this.$message.success('编辑成功')
                    this.dialogModifyCardVisible = false
                    this.searchCustomer()
                    this.doModifyCardLock = false
                })
                .catch(err => {
                    console.error(err)
                    this.$message.error('编辑失败')
                    this.doModifyCardLock = false
                })
        },
        doModifyPoint(){
            this.doModifyPointLock = true
            if(this.modifyPoint.type == 2){
              this.modifyPoint.point = -this.modifyPoint.point
            }
            DO_MODIFY_POINT(this.modifyPoint)
                .then(res => {
                    this.$message.success('编辑成功')
                    this.dialogModifyPointVisible = false
                    this.searchCustomer()
                    this.doModifyPointLock = false
                })
                .catch(err => {
                    console.error(err)
                    this.$message.error('编辑失败')
                    this.doModifyPointLock = false
                })
        },
        doModifySeat(){
            this.doModifySeatLock = true
            SET_CUSTOMER_SEAT(this.modifySeat)
                .then(res => {
                    this.$message.success('编辑成功')
                    this.dialogSeatVisible = false
                    this.searchCustomer()
                    this.doModifySeatLock = false
                })
                .catch(err => {
                    console.error(err)
                    this.$message.error('编辑失败')
                    this.doModifySeatLock = false
                })
        },
        sendPay(type){
            var that = this
            var params = {}
            if(type == 'charge'){
                params = this.charge.alipay
            }else{
                params = this.card.alipay
            }
            SEND_PAY(params)
                .then(res => {
                    if(res.data.code == 0){
                        this.$message.success('已提交支付宝支付')
                        this.checkTime = setInterval(_ => {
                            that.checkPay(type)
                        }, 3000)
                    }else{
                        this.$message.error(res.data.data)
                    }
                })
                .catch(err => {
                    console.error(err)
                    this.$message.error(err.response.data)
                })
        },
        checkPay(type){
            // var that = this
            if(type == 'charge'){
                type = 'charge-'
            }
            if(type == 'card'){
                type = 'package-buy-'
            }
            CHECK_PAY({out_trade_no: `${type}${this.billing.id}`})
                .then(res => {
                    if(res.data.code == 0){
                        this.$message.success('支付已经成功')
                        clearInterval(this.checkTime)

                        // UPDATE_PAY_STATUS({billing_id: this.billing.id})
                        //     .then(res => {
                                this.searchCustomer()
                                this.closePay(type)
                            // })
                            // .catch(err => {
                            //     console.error(err)
                            // })
                        this.payCodeVisible = false
                        this.payCodeUtilVisible = false
                        this.payResultVisible = true
                        this.payLoading.close()
                        
                    }else{
                        this.$message.error(res.data.data)
                        clearInterval(this.checkTime)
                    }
                })
                .catch(err => {
                    console.error(err)
                    this.$message.error(err.response.data)
                    clearInterval(this.checkTime)
                })
        },
        sendPayWechat(type){
            // var that = this
            var params = {}
            if(type == 'charge'){
                params = this.charge.wechat
            }else{
                params = this.card.wechat
            }
            this.$alert('提醒', '微信支付中，请勿关闭页面')
            var url = `https://api.viewinn.cn/wechat/sendPayWechat/${params.subject}/${params.out_trade_no}/${params.total_amount}/${params.buyer_id}/customer/${this.customer.cellphone}/1`
            window.open(url, '_self');
            // SEND_PAY_WECHAT(params)
            //     .then(res => {
            //         if(res.data.code == 0){
            //             this.$message.success('已提交微信支付')
            //             this.checkTime = setInterval(_ => {
            //                 that.checkPayWechat(type)
            //             }, 3000)
            //         }else{
            //             this.$message.error(res.data.data)
            //         }
            //     })
            //     .catch(err => {
            //         console.error(err)
            //         this.$message.error(err.response.data)
            //     })
        },
        checkPayWechat(type){
            // var that = this
            CHECK_PAY_WECHAT({out_trade_no: `${type == 'charge' ? 'charge-' : ''}${this.billing.id}`})
                .then(res => {
                    if(res.data.code == 0){
                        this.$message.success('支付已经成功')
                        clearInterval(this.checkTime)

                        UPDATE_PAY_STATUS({billing_id: this.billing.id})
                            .then(res => {
                                this.searchCustomer()
                                this.closePay(type)
                            })
                            .catch(err => {
                                console.error(err)
                            })

                        
                    }else{
                        this.$message.error(res.data.data)
                        clearInterval(this.checkTime)
                    }
                })
                .catch(err => {
                    console.error(err)
                    this.$message.error(err.response.data)
                    clearInterval(this.checkTime)
                })
        },
        changeCard(val){
            var card = this.packages.find(item => item.id == val)
            this.card.amount = card.discount_price
            this.card.expiry = dayjs().add(1, 'year').format('YYYY-MM-DD')
        },
        closePay(type){
            if(type == 'charge'){
                this.dialogChargeVisible = false
                this.charge = {
                    customer_id: '',
                    amount: '',
                    times: '',
                    method: '',
                    alipay: {
                        subject: '',
                        out_trade_no: '',
                        total_amount: '',
                        buyer_id: '',
                    },
                    wechat: {
                        subject: '',
                        out_trade_no: '',
                        total_amount: '',
                        buyer_id: '',
                    },
                }
            }else{
                this.dialogCardVisible = false
                this.card = {
                    package_id: '',
                    amount: '',
                    expiry: '',
                    method: '',
                    alipay: {
                        subject: '',
                        out_trade_no: '',
                        total_amount: '',
                        buyer_id: '',
                    },
                    wechat: {
                        subject: '',
                        out_trade_no: '',
                        total_amount: '',
                        buyer_id: '',
                    },
                }
            }
        },
        addCustomer(){
            this.customer = {}
            this.dialogFormVisible = true
        },
        changeKeyword(val){
            if(val == ''){
                this.searchCustomer()
            }
        },
        setFirstUse(customer_id, customer_package_id){
            SET_FIRST_USE({
                customer_id,
                customer_package_id
            })
                .then(res => {
                    this.$message.success('设置成功')
                    this.searchCustomer()
                })
                .catch(err => {
                    console.error(err)
                    this.$message.error('设置失败')
                })
        },
        openCustomer(row, expandedRows){
          console.log('openCustomer', row, expandedRows)
          this.openedCustomer = []
          expandedRows.forEach(item => {
            this.openedCustomer.push(item.id)
          })
        },
        confirmPoint(pl) {
            this.$confirm('是否确认该积分', '提醒', {
                type: 'warning'
            }).then(() => {
                CONFIRM_POINT({
                    id: pl.id
                })
                    .then(res => {
                        this.$message.success('积分已确认')
                        this.searchCustomer()
                    })
            }).catch(() => {
                        
            });
        },
        setPayment(type) {
            this.card.method = type
            this.doCardBuy()
            this.payCodeVisible = true
            this.$nextTick(_ => {
                this.$refs['code_number'].focus()
                this.codeNumber = ''
            })
        },
        setCode(){
            if(this.card.method == 'alipay'){
                this.card.alipay.buyer_id = this.codeNumber
                this.sendPay('card')

            }
            if(this.card.method == 'wechat'){
                this.card.wechat.buyer_id = this.codeNumber
                this.sendPayWechat('card')
            }
        },
        setUtilPayment(){
            this.payCodeUtilVisible = true
            this.$nextTick(_ => {
                this.$refs['code_number_util'].focus()
            })
        },
        setCodeUtil(){
            this.payLoading = this.$loading({
                lock: true,
                text: '支付中，请勿刷新页面，请勿输入其他内容，等待系统反馈。如长时间无反应请联系系统管理员。',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            if(/^(?:2[5-9]|30)\d{14,22}$/ig.test(this.codeNumberUtil)){
                this.card.method = 'alipay'
                this.doCardBuy(_ => {
                    this.card.alipay.buyer_id = this.codeNumberUtil
                    this.sendPay('card')
                })
            }else if(/^(?:1[0-5])\d{16}$/ig.test(this.codeNumberUtil)){
                this.card.method = 'wechat'
                this.doCardBuy(_ => {
                    this.card.wechat.buyer_id = this.codeNumberUtil
                    this.sendPayWechat('card')
                })
            }else{
                this.$message.error('错误的付款码，请确认客人正在使用支付宝或微信的付款码');
                this.payLoading.close()
            }
        },
        setCodeMeituan() {
            this.receiptPrepare()
        },
        doMergeCard() {
            this.mergeCardLock = true
            this.cardMerge.customer_id = this.customer.id
            MERGE_CARD(this.cardMerge)
                .then(res => {
                    this.$message.success('合并成功')
                    this.dialogMergeCardVisible = false
                    this.mergeCardLock = false
                    this.searchCustomer()
                    this.cardMerge.to_package = ''
                    this.cardMerge.from_package = ''
                })
                .catch(err => {
                    console.error(err)
                    this.$message.error(err.response.data)
                })
        },
        oepnModifyNickname(row) {
            this.dialogNicknameVisible = true
            this.customer = row
        },
        doModifyNickname() {
            this.doModifyNicknameLock = true
            DO_MODIFY_NICKNAME({
                id: this.customer.id,
                nickname: this.customer.nickname
            })
                .then(res => {
                    this.$message.success('编辑成功')
                    this.dialogNicknameVisible = false
                    this.searchCustomer()
                    this.doModifyNicknameLock = false
                })
                .catch(err => {
                    console.error(err)
                    this.$message.error('编辑失败')
                    this.doModifyNicknameLock = false
                })
        },
        doModifyPointSetting() {
            this.doModifyPointSettingLock = true
            this.modifyPointSetting.customer_id = this.customer.id
            DO_SET_CUSTOMER_POINT(this.modifyPointSetting)
                .then(res => {
                    this.$message.success('编辑成功')
                    this.dialogPointSettingVisible = false
                    this.searchCustomer()
                    this.doModifyPointSettingLock = false
                })
                .catch(err => {
                    console.error(err)
                    this.$message.error('编辑失败')
                    this.doModifyNicknameLock = false
                })
        },
        changePointSetting(val) {
            var thePackage = this.package_settings.find(item => item.id == val)
            this.modifyPointSetting.value = thePackage.customer_point_setting != null ? thePackage.customer_point_setting.value : thePackage.point
        }
    }
}
</script>