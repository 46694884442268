<template>
    <div class="goods">
        <el-row>
            <el-col :span="24">
                <el-form :model="form" :inline="true" size="mini" @submit.native.prevent>
                    <el-form-item>
                        <el-button type="primary" @click="openAdd">新增商品</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>

        <el-table :data="data">
            <el-table-column 
                prop="title"
                label="标题"
            >
            </el-table-column>
            <el-table-column 
                prop="thumbnail"
                label="缩略图"
            >
                <template slot-scope="scope">
                    <img :src="scope.row.thumb_pic.url" width="50" />
                </template>
            </el-table-column>
            <el-table-column 
                prop="updated_at"
                label="最后修改"
            >
            </el-table-column>
            <el-table-column 
                label="操作"
            >
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" @click="openModify(scope.row)">修改</el-button>
                    <el-button type="danger" size="mini" @click="doDelete(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        
        <el-dialog title="新增或修改商品" :visible.sync="dialogFormVisible">
            <el-form :model="sendForm" ref="editor" label-width="120px">
                <el-form-item label="标题" prop="title">
                    <el-input v-model="sendForm.title"></el-input>
                </el-form-item>
                <el-form-item label="描述" prop="description">
                    <el-input v-model="sendForm.description"></el-input>
                </el-form-item>
                <el-form-item label="是否上线" prop="status">
                    <el-switch 
                        v-model="sendForm.status" 
                        :active-value="1" 
                        :inactive-value="0"
                    >
                    </el-switch>
                </el-form-item>
                <!-- <el-form-item label="价格" prop="press">
                    <el-input v-model="sendForm.press"></el-input>
                </el-form-item>
                <el-form-item label="数量" prop="name">
                    <el-input v-model="sendForm.name"></el-input>
                </el-form-item> -->
                <el-form-item label="缩略图" prop="thumbnail">
                    <el-button type="primary" size="mini" @click="openLib('thumbnail')">打开图库</el-button>
                    <div class="lib-area">
                        <div class="lib-block">
                            <img class="img-block" :src="sendForm.thumb_pic.url" />
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="商品图" prop="pics">
                    <el-button type="primary" size="mini" @click="openLib('documents')">打开图库</el-button>
                    
                    <div class="lib-area">
                        <div class="lib-block" v-for="doc in sendForm.documents" :key="doc.id">
                            <img :src="doc.pic.url" />
                        </div>
                    </div>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="doSave">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="选择图库" :visible.sync="dialogLibraryVisible">
            <div class="lib-area">
                <div class="lib-block" v-for="lib in library" :key="lib.id">
                    <img :src="lib.url" @click="chooseLib(lib)" />
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogLibraryVisible = false">关 闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    GET_GOODS,
    // GET_GOOD_BY_ID,
    SAVE_GOOD,
    DELETE_GOOD,
} from '@/api/goods'

import {
    GET_LIBRARY,
} from '@/api/library'

export default {
    name: 'Goods',
    data() {
        return {
            sendForm: {
                id: 0,
                title: '',
                description: '',
                thumbnail: 0,
                thumb_pic: {},
                documents: []
            },
            library: [],
            data: [],
            dialogFormVisible: false,
            dialogLibraryVisible: false,
            libType: ''
        }
    },
    mounted() {
        this.getGoods()
    },
    methods: {
        getLibrary() { 
            GET_LIBRARY()
                .then(res => {
                    this.library = res.data.data
                })
        },
        getGoods() { 
            GET_GOODS()
                .then(res => {
                    this.data = res.data.data
                })
        },
        openAdd() { 
            this.dialogFormVisible = true
            this.sendForm = {
                id: 0,
                title: '',
                description: '',
                thumbnail: 0,
                thumb_pic: {},
                documents: []
            }
        },
        openModify(row) { 
            this.dialogFormVisible = true
            this.sendForm = row

            // var pics = []
            // this.sendForm.documents.forEach(doc => {
            //     pics.push(doc.pic)
            // })
        },
        doSave() { 
            console.log('doSave', this.sendForm)
            SAVE_GOOD(this.sendForm)
                .then(res => {
                    this.$message.success('提交成功')
                    this.dialogFormVisible = false
                    this.getGoods()
                })
        },
        doDelete(row) { 
            this.$confirm('是否删除商品，谨慎操作', '警告', {
                type: 'warning'
            }).then(() => {
                DELETE_GOOD({
                    id: row.id
                })
                    .then(res => {
                        this.$message.success('删除成功')
                        this.getGoods()
                    })
            }).catch(() => {
                        
            });
        },
        openLib(type) { 
            this.dialogLibraryVisible = true
            this.libType = type

            this.getLibrary()
        },
        chooseLib(lib) {
            if (this.libType == 'thumbnail') {
                this.sendForm.thumbnail = lib.id
                this.sendForm.thumb_pic = lib
            }

            if (this.libType == 'documents') {
                this.sendForm.documents.push({
                    pic: lib
                })
            }
        }
    }
}
</script>

<style lang="scss">
.lib-area {
    display: flex;

    .lib-block {
        width: 148px;
        height: 148px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            //position: absolute;
        }
    }
}
</style>