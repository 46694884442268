<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>设定</span>
        <el-button style="float: right;" type="primary" size="mini" @click="addSetting">添加</el-button>
      </div>
      <div>
        <el-table :data="setitngs">
          <el-table-column label="名称" prop="name"></el-table-column>
          <el-table-column label="值" prop="value"></el-table-column>
          <el-table-column label="说明" prop="description"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="warning" @click="modifySetting(scope.row)" size="mini">修改</el-button>
              <el-button type="danger" @click="deleteSetting(scope.row)" size="mini">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

    <el-dialog 
      title="修改" 
      :visible.sync="editorVisible" 
      :before-close="closeEditor"
    >
        <el-form ref="form" :model="form" label-width="120" size="mini">
          <el-row>
            <el-col :span="8">
              <el-input v-model="form.name" placeholder="请填写"></el-input>
            </el-col>
            <el-col :span="8">
              <el-input v-model="form.value" placeholder="请填写"></el-input>
            </el-col>
            <el-col :span="8">
              <el-input v-model="form.description" placeholder="请填写"></el-input>
            </el-col>
          </el-row>
          <p>* 填写消费金额1元可对应的学分数字，比如消费1元可兑换100学分的话，填写「100」</p>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="closeEditor">取 消</el-button>
            <el-button type="primary" @click="save">确 定</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import {
    SAVE_SETTINGS,
    SEARCH_SETTINGS,
    DELETE_SETTING
} from '../api/point'
export default {
  name: 'Point',
  data(){
    return {
      editorVisible: false,
      form: {
        id: 0,
        name: '',
        value: '',
        description: ''
      },
      setitngs: []
    }
  },
  mounted(){
    this.searchSettings()
  },
  methods: {
    searchSettings(){
      SEARCH_SETTINGS()
        .then(res => {
          this.setitngs = res.data.data.data
        })
    },
    addSetting(){
      this.editorVisible = true
      this.form = {
        id: 0,
        name: '',
        value: '',
        description: ''
      }
    },
    modifySetting(row){
      this.editorVisible = true
      this.form = Object.assign({}, row)
    },
    closeEditor(){
      this.editorVisible = false
    },
    save(){
      SAVE_SETTINGS(this.form)
        .then(res => {
          this.searchSettings()
          this.editorVisible = false
        })
    },
    deleteSetting(row){
      this.$confirm('是否删除设定，删除后无法恢复，谨慎操作', '警告', {
        type: 'warning'
      }).then(() => {
        DELETE_SETTING({id: row.id})
          .then(res => {
            this.$message.success('删除成功')
            this.searchSettings()
          })
      }).catch(() => {
                  
      });
    },
  }
}
</script>

<style lang="less">

</style>