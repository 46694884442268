<template>
    <div class="user">
        <el-row>
            <el-col :span="2">
                <el-button type="primary" @click="openCreate">创建用户</el-button>
            </el-col>
            <el-col :span="2">
                <el-button type="primary" @click="testSmart">测试smart</el-button>
            </el-col>
        </el-row>
        <el-table :data="tableData">
            <el-table-column label="账户" prop="name"></el-table-column>
            <el-table-column label="姓名" prop="show_name"></el-table-column>
            <el-table-column label="邮箱" prop="email"></el-table-column>
            <el-table-column label="角色">
                <template slot-scope="scope">
                    {{scope.row.role != null ? scope.row.role.name : '未设定'}}
                </template>
            </el-table-column>
            <el-table-column label="创建时间" prop="created_at"></el-table-column>
            <el-table-column label="修改时间" prop="updated_at"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="openEditor(scope.row)">编辑</el-button>
                    <el-button v-if="canResetPassword" type="text" size="small" @click="openResetPassword(scope.row)">重置密码</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog title="修改" :visible.sync="dialogFormVisible">
            <el-form :model="user" label-width="120">
                <el-form-item label="账户">
                    <el-input v-model="user.name" :disabled="isLock"></el-input>
                </el-form-item>
                <el-form-item label="密码">
                    <el-input v-model="user.password" type="password" :disabled="isLock"></el-input>
                </el-form-item>
                <el-form-item label="姓名">
                    <el-input v-model="user.show_name"></el-input>
                </el-form-item>
                <el-form-item label="邮箱">
                    <el-input v-model="user.email"></el-input>
                </el-form-item>
                <el-form-item label="角色">
                    <el-select v-model="user.role_id">
                        <el-option
                            v-for="role in roles"
                            :key="role.id"
                            :label="role.name"
                            :value="role.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveUser">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="重置密码" :visible.sync="dialogResetVisible">
            <el-form :model="user" label-width="120">
                <el-form-item label="新密码">
                    <el-input v-model="user.password" type="password" :disabled="isLock"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogResetVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveUser">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
// import dayjs from 'dayjs'
import {
    SAVE_USER,
    SEARCH_USER,
    GET_ALL_ROLES
} from '../api/user'
import {
    TEST_SMART
} from '../api/smart'
export default {
    name: 'User',
    data(){
        return {
            isLock: true,
            tableData: [],
            user: {},
            dialogFormVisible: false,
            dialogResetVisible: false,
            roles: [],
            canResetPassword: false
        }
    },
    mounted(){
        this.searchUser()
        this.getAllRoles()
        let permissionsStorage = JSON.parse(sessionStorage.getItem('permissions'))
        this.canResetPassword = permissionsStorage.find(item => item.permission.key == 'action_reset_user_password')
    },
    methods: {
        getAllRoles(){
            GET_ALL_ROLES()
                .then(res => {
                    this.roles = res.data.data
                })
        },
        saveUser(){
            SAVE_USER(this.user)
                .then(res => {
                    console.log(res)
                    this.searchUser()
                    this.dialogFormVisible = false
                    this.dialogResetVisible = false
                })
                .catch(err => {
                    this.$message.error(err.response.data)
                    console.error(err)
                })
        },
        searchUser(){
            SEARCH_USER()
                .then(res => {
                    this.tableData = res.data.data
                })
                .catch(err => {
                    console.error(err)
                })
        },
        openEditor(row){
            this.isLock = true
            this.dialogFormVisible = true
            this.user = row
        },
        openCreate(){
            this.isLock = false
            this.dialogFormVisible = true
            this.user = {}
        },
        openResetPassword(row){
            this.isLock = false
            this.dialogResetVisible = true
            this.user = row
        },
        testSmart(){
            TEST_SMART()
                .then(res => {
                    console.log('test smart', res)
                })
        }
    }
}
</script>