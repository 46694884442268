<template>
    <div class="appointment">
        <el-row>
            <el-col :span="24">
                <el-form :model="form" :inline="true" size="mini">
                    <el-form-item>
                        <el-button type="primary" @click="openAdd">添加预约</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="openAdd('batch')">批量添加预约</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model="form.cellphone" placeholder="搜索手机号" @keyup.enter.native="searchAppointment" clearable @change="changeKeyword" style="width: 120px;"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-date-picker
                            key="filterDateRange"
                            v-model="form.date"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd"
                            style="width: 250px;"
                            @change="changeDate">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="form.seat_id" clearable filterable placeholder="请选择坐席" style="width: 120px;">
                            <el-option
                                v-for="(item, key) in seat"
                                :key="key"
                                :label="`${item.room}-${item.name}`"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="form.status" clearable filterable multiple collapse-tags placeholder="请选择状态">
                            <el-option
                                v-for="(item, key) in status"
                                :key="key"
                                :label="item"
                                :value="key">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="searchAppointment">查找</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-checkbox v-model="form.today" @change="changeToday">看今天</el-checkbox>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>

        <el-tabs v-model="tabName" @tab-click="changeTab">
            <el-tab-pane label="预约列表" name="list">
                <el-table :data="tableData" v-loading="loading">
                    <template slot="empty">
                        <el-empty :image-size="200" description="暂无数据">
                            <el-button type="primary" @click="searchAppointment">重新加载数据</el-button>
                        </el-empty>
                    </template>
                    <el-table-column label="预约日期" prop="date" width="180">
                        <template slot-scope="scope">
                            <span :class="{'is-today': dayjs().isSame(scope.row.date, 'day')}">{{scope.row.date}} {{dayjs(scope.row.date).format('ddd')}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="预约时间">
                        <template slot-scope="scope">
                            {{scope.row.start_time}} - {{scope.row.end_time}} <el-button v-show="scope.row.status < 3" type="primary" icon="el-icon-edit" circle size="mini" @click="openDateTimeEditor(scope.row)"></el-button>
                            <br/><span v-if="checkArrived(scope.row)"> 入座：{{dayjs(checkArrived(scope.row).created_at).format('HH:mm')}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="预约人">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" placement="right" popper-class="remark">
                                <div slot="content">
                                    <!-- <p>
                                        剩余：{{scope.row.customer.times}}
                                    </p> -->
                                    <div v-if="scope.row.customer.customer_packages != null">
                                        <p v-for="(pkg, pkg_idx) in scope.row.customer.customer_packages.filter(item => item.status == 0)" :key="pkg_idx">
                                            {{pkg.package_info.name}} {{pkg.first_use == 1 ? '(首选结账卡)' : ''}} 剩余：{{calAvailableTime(pkg.available_time, pkg.package_info.type, pkg.package_info.once_time)}}
                                        </p>
                                    </div>
                                    <p v-if="scope.row.customer.remark != ''">
                                        备注：{{scope.row.customer.remark}}
                                    </p>
                                </div>
                                <span>
                                    <i class="el-icon-warning" v-if="scope.row.customer.blacklist == 1"></i>
                                    {{Tool.checkNullAttr(scope.row.customer, 'nickname')}}<br>
                                    <el-link :href="`/customer/${scope.row.customer.cellphone}`" type="primary">{{scope.row.customer.cellphone}}</el-link>
                                </span>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="预约坐席" width="100">
                        <template slot-scope="scope">
                            {{Tool.checkNullAttr(scope.row.seat, 'room')}}
                            -
                            {{Tool.checkNullAttr(scope.row.seat, 'name')}}
                        </template>
                    </el-table-column>
                    <el-table-column label="预约状态" width="200">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.status == 0" size="mini" effect="dark" type="danger">{{status[scope.row.status]}}</el-tag>
                            <el-tag v-if="scope.row.status == 2" size="mini" effect="dark" type="warning">{{status[scope.row.status]}}</el-tag>
                            <el-tag v-if="scope.row.status == 3" size="mini" effect="dark" type="success">{{status[scope.row.status]}}</el-tag>
                            <el-tag v-if="scope.row.status == 6" size="mini" effect="dark" color="#770777" :hit="false" class="subscription">{{status[scope.row.status]}}</el-tag>
                            <el-tag v-if="scope.row.status == 7" size="mini" effect="dark" color="#770777" :hit="false" class="subscription">{{status[scope.row.status]}}</el-tag>
                            <el-tag v-if="scope.row.status == 4" size="mini" type="info">{{status[scope.row.status]}}</el-tag>
                            <el-tag v-if="scope.row.status == 5" size="mini" type="info">{{status[scope.row.status]}}</el-tag>
                            <el-button v-if="scope.row.status < 3" size="mini" type="primary" @click="setArrived(scope.row)" style="margin-left: 20px;">到店</el-button>
                            <span v-if="scope.row.arrived_time != null && scope.row.status != 3" @click="openArrivedTime(scope.row)" class="btn-link"><br/>到店：{{scope.row.arrived_time}}</span>
                            <span v-if="scope.row.status == 3 && scope.row.log.find(item => item.status == 3).customer_package != null && scope.row.log.find(item => item.status == 3).customer_package.package_info.type == 'hours'"><br/>{{scope.row.log.find(item => item.status == 3).times}}小时</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="创建时间" prop="created_at" width="100">
                        <template slot-scope="scope">
                            {{dayjs(scope.row.created_at).format('YYYY-MM-DD')}}<br>
                            {{dayjs(scope.row.created_at).format('HH:mm:ss')}}
                        </template>
                    </el-table-column>
                    <el-table-column label="修改时间" prop="updated_at" width="100">
                        <template slot-scope="scope">
                            {{dayjs(scope.row.updated_at).format('YYYY-MM-DD')}}<br>
                            {{dayjs(scope.row.updated_at).format('HH:mm:ss')}}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="copyEditor(scope.row)">复制</el-button>
                            <el-button type="text" size="small" @click="openEditor(scope.row)">编辑</el-button>
                            <template v-if="scope.row.status < 3">
                              <el-button v-if="scope.row.customer.customer_packages.filter(item => item.status == 0).length == 1" type="text" size="small" @click="checkout(scope.row)">结账</el-button>
                              <el-button v-else type="text" size="small" @click="openCheckout(scope.row)">结账</el-button>
                            </template>
                            <template v-else>
                              <el-button v-if="canRollbackCheckout" type="text" size="small" @click="rollbackCheckout(scope.row)">撤销结账</el-button>
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="当前坐席分布" name="map" v-loading="seatStatusLoading">
                <el-row>
                    <el-col :span="12">
                        <h5>楼上</h5>
                        <h6>观山</h6>
                        <div style="width: 320px;">
                            <el-tag 
                                :type="usedSeat.includes(s.id) ? 'danger' : 'info'"
                                v-for="(s, s_idx) in seat.filter(item => item.room == '观山')"
                                :key="s_idx"
                                class="seat-tag"
                            >{{s.name}}</el-tag>
                        </div>
                        <h6>望川</h6>
                        <div style="width: 160px;">
                            <el-tag 
                                :type="usedSeat.includes(s.id) ? 'danger' : 'info'"
                                v-for="(s, s_idx) in seat.filter(item => item.room == '望川')"
                                :key="s_idx"
                                class="seat-tag"
                            >{{s.name}}</el-tag>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <h5>楼下</h5>
                        <h6>手工</h6>
                        <div style="width: 240px;">
                            <el-tag 
                                :type="usedSeat.includes(s.id) ? 'danger' : 'info'"
                                v-for="(s, s_idx) in seat.filter(item => item.room == '手工')"
                                :key="s_idx"
                                class="seat-tag"
                            >{{s.name}}</el-tag>
                        </div>
                    </el-col>
                </el-row>
            </el-tab-pane>
            <el-tab-pane label="坐席使用情况" name="gantt" :lazy="true">
                <div v-if="tabName=='gantt'" ref="gantt" class="gantt"></div>
            </el-tab-pane>
        </el-tabs>

        <el-dialog title="修改" :visible.sync="dialogFormVisible">
            <el-form :model="appointment" label-width="120px">
                <el-form-item label="昵称">
                    <el-autocomplete 
                        v-model="appointment.customer.nickname" 
                        :fetch-suggestions="querySearchAsync"
                        @select="handleCustomerSelect"
                        value-key="nickname"
                        :disabled="appointment.id != undefined"
                    ></el-autocomplete>
                </el-form-item>
                <el-form-item label="日期">
                    <el-date-picker
                        key="apptDate"
                        v-if="appointment.type != 'batch'"
                        v-model="appointment.date"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期"
                        :disabled="appointment.id != undefined"
                    >
                    </el-date-picker>
                    <el-date-picker
                        key="apptDateRange"
                        v-if="appointment.type == 'batch'"
                        v-model="appointment.batchDate"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期范围"
                        :disabled="appointment.id != undefined"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="时间">
                    <el-time-picker
                        :key="uuid"
                        is-range
                        v-model="appointment.time"
                        @blur="changeTime"
                        :picker-options="{format: 'HH:mm'}"
                        value-format="HH:mm"
                        format="HH:mm"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        placeholder="选择时间范围"
                        :disabled="appointment.isWholeDay || appointment.id != undefined"
                      >
                    </el-time-picker>
                </el-form-item>
                <el-form-item label="是否全天">
                  <el-switch
                    v-model="appointment.isWholeDay"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    @change="changeWholeDay"
                    :disabled="appointment.id != undefined"
                  >
                  </el-switch>
                </el-form-item>
                <el-form-item label="坐席">
                    <el-select v-model="appointment.seat_id" placeholder="请选择">
                        <el-option
                            v-for="(item, key) in seat"
                            :key="key"
                            :label="`${item.room}-${item.name}`"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="appointment.status" placeholder="请选择">
                        <el-option
                            v-for="(item, key) in status"
                            :key="key"
                            :label="item"
                            :value="key">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="套餐卡" v-show="appointment.status == 6 || appointment.status == 7">
                    <el-select 
                      v-model="appointment.customer_package_id" 
                      placeholder="请选择"
                      @change="changePackage"
                    >
                        <el-option
                            v-for="(item, key) in appointment.customer_packages.filter(item => item.status == 0)"
                            :key="key"
                            :label="item.package_info.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveAppointment" :disabled="saveAppointmentLock">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="修改日期时间" :visible.sync="dialogDateTimeVisible">
            <el-form :model="dateTimeForm" label-width="120px">
                <el-form-item label="日期">
                    <el-date-picker
                        key="apptModifyDate"
                        v-model="dateTimeForm.date"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="时间">
                    <el-time-picker
                        :key="uuid"
                        is-range
                        v-model="dateTimeForm.time"
                        @blur="changeTime"
                        :picker-options="{format: 'HH:mm'}"
                        value-format="HH:mm"
                        format="HH:mm"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        placeholder="选择时间范围"
                      >
                    </el-time-picker>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogDateTimeVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveDateTime" :disabled="saveDateTimeLock">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="选择套餐卡结账" :visible.sync="dialogCardVisible">
            <el-form :model="checkoutForm" label-width="120px">
                <el-form-item label="套餐卡">
                    <el-select 
                      v-model="checkoutForm.customer_package_id" 
                      placeholder="请选择"
                      @change="changePackage"
                    >
                        <el-option
                            v-for="(item, key) in checkoutForm.customer_packages.filter(item => item.status == 0)"
                            :key="key"
                            :label="item.package_info.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogCardVisible = false">取 消</el-button>
                <el-button type="primary" @click="doCheckout">确 定</el-button>
                <el-button v-if="canCheckoutByApptTime" type="primary" @click="doCheckoutByApptTime">使用预约时间结账</el-button>
                <el-button v-if="canCheckoutByCardTime" type="primary" @click="doCheckoutByCardTime">使用套餐卡时间结账</el-button>
            </div>
        </el-dialog>

        <el-dialog title="修改到达时间" :visible.sync="dialogArrivedTimeVisible">
            <el-form :model="arrivedTimeForm" label-width="120px">
                <el-form-item label="日期">
                    <el-date-picker
                        key="apptArrivedDate"
                        v-model="arrivedTimeForm.date"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="时间">
                    <el-time-picker
                        key="apptArrivedTime"
                        v-model="arrivedTimeForm.time"
                        :picker-options="{format: 'HH:mm'}"
                        value-format="HH:mm"
                        format="HH:mm"
                        placeholder="选择时间"
                      >
                    </el-time-picker>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogArrivedTimeVisible = false">取 消</el-button>
                <el-button type="primary" @click="changeArrivedTime">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import dayjs from 'dayjs'
require('dayjs/locale/zh-cn')
dayjs.locale('zh-cn')

import tool from '../common/tool'
import {
    SAVE_APPOINTMENT,
    SEARCH_APPOINTMENT,
    CHECKOUT,
    ROLLBACK_CHECKOUT,
    SET_ARRIVED,
    SAVE_DATETIME,
    SAVE_ARRIVED_TIME
} from '../api/appointment'
import {
    SEARCH_CUSTOMER
} from '../api/customer'
import {
    SEARCH_SEAT,
    SEARCH_AVILABLE_SEAT
} from '../api/seat'
import {
    GET_APPOINTMENT_SEAT_REPORT
} from '../api/report'
export default {
    name: 'Appointment',
    data(){
        return {
            dayjs: (date) => {
                return dayjs(date)
            },
            uuid: '',
            form: {
                date: [dayjs().format('YYYY-MM-DD'), dayjs().add(1, 'week').format('YYYY-MM-DD')],
                status: ['0', '2', '3', '6', '7']
            },
            tableData: [],
            appointment: {
                type: 'one',
                customer: {},
                batchDate: [dayjs().format('YYYY-MM-DD'), dayjs().add(1, 'day').format('YYYY-MM-DD')],
                dateRange: [],
                customer_packages: [],
                customer_package_id: ''
            },
            seat: [],
            usedSeat: [],
            dialogFormVisible: false,
            status: {
                '0': '预约中',
                '2': '使用中',
                '3': '已结账',
                '4': '已空出',
                '5': '取消预约',
                '6': '包周',
                '7': '包月',
            },
            Tool: new tool(),
            loading: false,
            saveAppointmentLock: false,
            tabName: 'list',
            seatStatusLoading: false,
            dialogCardVisible: false,
            checkoutLock: false,
            checkoutForm: {
              customer_packages: [],
              customer_package_id: ''
            },
            canCheckoutByApptTime: false,
            canCheckoutByCardTime: false,
            canRollbackCheckout: false,
            dialogDateTimeVisible: false,
            dialogArrivedTimeVisible: false,
            saveDateTimeLock: false,
            dateTimeForm: {
                id: 0,
                date: '',
                time: [],
            },
            arrivedTimeForm: {
                id: 0,
                date: '',
                time: '',
            }
        }
    },
    mounted(){
        this.searchAppointment()
        this.searchSeat()

        var _this = this
        setInterval(_ => {
            _this.searchAppointment()
        }, 30*1000)

        let permissionsStorage = JSON.parse(sessionStorage.getItem('permissions'))
        this.canCheckoutByApptTime = permissionsStorage.find(item => item.permission.key == 'action_checkout_by_appt_time')
        this.canCheckoutByCardTime = permissionsStorage.find(item => item.permission.key == 'action_checkout_by_card_time')
        this.canRollbackCheckout = permissionsStorage.find(item => item.permission.key == 'action_rollback_checkout')
    },
    methods: {
        querySearchAsync(queryString, cb) {
            console.log('querySearchAsync', queryString)
            if(queryString != ''  && queryString != undefined){
              SEARCH_CUSTOMER({search: queryString})
                  .then(res => {
                      console.log(res)
                      cb(res.data.data.data)
                  })
                  .catch(err => {
                      this.$message.error(err.response.data)
                      console.error(err)
                  })
            }
        },
        handleCustomerSelect(item){
            console.log(item)
            this.appointment.customer = item
            this.appointment.customer_id = item.id
            this.appointment.customer_packages = item.customer_packages
        },
        searchSeat(){
            SEARCH_SEAT()
                .then(res => {
                    console.log(res)
                    this.seat = res.data.data
                    this.seat.push({
                        id: 100,
                        room: '会议',
                        name: '未指定'
                    })
                })
                .catch(err => {
                    this.$message.error(err.response.data)
                    console.error(err)
                })
        },
        saveAppointment(){
            console.log(this.appointment.time)
            this.saveAppointmentLock = true
            this.appointment.start_time = this.appointment.time[0]
            this.appointment.end_time = this.appointment.time[1]
            this.appointment.multiple = 1

            if(this.appointment.isWholeDay){
                this.appointment.start_time = '08:30'
                this.appointment.end_time = '22:30'
                this.appointment.time = [this.appointment.start_time, this.appointment.end_time]
            }

            if(this.appointment.type == 'batch'){
                var diffDays = Math.abs(this.dayjs(this.appointment.batchDate[0]).diff(this.dayjs(this.appointment.batchDate[1]), 'day'))
                console.log('diffDays', diffDays)
                this.appointment.dateRange = [this.appointment.batchDate[0]]
                for(let i = 1; i <= diffDays; i++){
                    this.appointment.dateRange.push(this.dayjs(this.appointment.batchDate[0]).add(i, 'day').format('YYYY-MM-DD'))
                }
            }
            SAVE_APPOINTMENT(this.appointment)
                .then(res => {
                    console.log(res)
                    if(res.data.code == 0){
                        this.searchAppointment()
                        this.dialogFormVisible = false
                    }else{
                        this.$message.error(this.appointment.customer.nickname + res.data.data)
                    }
                    this.saveAppointmentLock = false
                })
                .catch(err => {
                    this.$message.error(err.response.data)
                    console.error(err)
                    this.saveAppointmentLock = false
                })
        },
        searchAppointment(){
            this.loading = true
            if(this.form.date != undefined){
                this.form.start_date = this.form.date[0]
                this.form.end_date = this.form.date[1]
            }else{
                delete this.form.start_date
                delete this.form.end_date
            }
            SEARCH_APPOINTMENT(this.form)
                .then(res => {
                    this.tableData = res.data.data
                    this.loading = false
                })
                .catch(err => {
                    console.error(err)
                    this.$message.error('获取预约列表出错，请重新尝试')
                    this.loading = false
                })
        },
        openAdd(type){
            this.dialogFormVisible = true
            this.appointment = {
                customer: {},
                date: this.dayjs().format('YYYY-MM-DD'),
                time: [this.dayjs().add(2, 'minutes').format('HH:mm'),this.dayjs().add(62, 'minutes').format('HH:mm')],
                status: '2',
                customer_packages: [],
                customer_package_id: ''
            }
            if(type == 'batch'){
                this.appointment.type = type
            }
        },
        openEditor(row){
            this.dialogFormVisible = true
            this.appointment = row
            // this.appointment.time = [new Date(`${row.date} ${row.start_time}:00`), new Date(`${row.date} ${row.end_time}:00`)]
            this.appointment.time = [row.start_time, row.end_time]
            this.appointment.status = row.status.toString()
            this.appointment.type = 'one'
            if(row.start_time == '08:30' && row.end_time == '22:30'){
              this.appointment.isWholeDay = true
            }
            this.appointment.customer_packages = row.customer.customer_packages
            var log = [...[], ...row.log]
            log.reverse()
            let theLog = log.filter(item => item.customer_package_id != null)
            if(theLog.length > 0){
              this.appointment.customer_package_id = theLog[0].customer_package_id
            }
        },
        copyEditor(row){
            this.dialogFormVisible = true
            this.appointment = row
            delete this.appointment.id
            // this.appointment.time = [new Date(`${row.date} ${row.start_time}:00`), new Date(`${row.date} ${row.end_time}:00`)]
            this.appointment.time = [row.start_time, row.end_time]
            this.appointment.status = row.status.toString()
            this.appointment.type = 'one'
            this.appointment.customer_packages = row.customer.customer_packages
            var log = [...[], ...row.log]
            log.reverse()
            let theLog = log.filter(item => item.customer_package_id != null)
            if(theLog.length > 0){
              this.appointment.customer_package_id = theLog[0].customer_package_id
            }
        },
        changeTime(val){
            console.log(val)
            this.uuid = this.Tool.uuid()
        },
        openCheckout(row){
            this.checkoutForm.customer_packages = [...[], ...row.customer.customer_packages]
            this.checkoutForm.customer_id = row.customer_id
            this.checkoutForm.id = row.id
            this.checkoutForm.customer_package_id = row.customer.customer_package.id
            this.dialogCardVisible = true
            
        },
        doCheckout(){
            this.checkout()
        },
        doCheckoutByApptTime(){
            this.checkout(undefined, 'appt_time')
        },
        doCheckoutByCardTime(){
            this.checkout(undefined, 'card_time')
        },
        checkout(row, type){
            if(row != undefined){
                // this.checkoutForm = Object.assign({}, row)
                this.checkoutForm.customer_id = row.customer_id
                this.checkoutForm.id = row.id
                this.checkoutForm.customer_package_id = row.customer.customer_package.id
                this.checkoutForm.customer_packages = []
            }
            this.$confirm('是否为该客人结账？', '提醒', {
                type: 'warning'
            }).then(() => {
                CHECKOUT({
                    customer_id: this.checkoutForm.customer_id, 
                    appt_id: this.checkoutForm.id, 
                    from: 'cms',
                    customer_package_id: this.checkoutForm.customer_package_id,
                    type
                })
                    .then(res => {
                        if(res.data.code == 0){
                            this.$message.success('结账完成')
                            this.searchAppointment()
                            this.dialogCardVisible = false
                        }else{
                            this.$message.error(res.data.data)
                        }
                    })
                    .catch(err => {
                        console.error(err)
                        this.$message.error(err.response.data)
                    })
            }).catch(() => {
                        
            });
        },
        changeToday(val){
            if(val){
                this.form.date = [dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]
            }else{
                this.form.date = []
            }
            this.searchAppointment()
        },
        changeKeyword(val){
            if(val == ''){
                this.searchAppointment()
            }
        },
        changeDate(){
            if(this.form.date.length > 0 && this.form.date[0] == this.form.date[1] && this.form.date[0] == dayjs().format('YYYY-MM-DD')){
                this.form.today = 1
            }else{
                this.form.today = 0
            }
            this.searchAppointment()
        },
        searchAvilableSeat(){
            this.seatStatusLoading = true
            SEARCH_AVILABLE_SEAT({
                date: this.dayjs().format('YYYY-MM-DD'),
                start_time: this.dayjs().format('HH:mm'),
                end_time: this.dayjs().format('HH:mm')
            })
                .then(res => {
                    this.usedSeat = []
                    res.data.data.avilable.forEach(item => {
                        this.usedSeat.push(item.seat_id)
                    })
                    res.data.data.seats.forEach(item => {
                        if(item.seat_locks.find(sl => sl.date == this.dayjs().format('YYYY-MM-DD'))){
                            this.usedSeat.push(item.id)
                        }
                    })
                    this.seatStatusLoading = false
                })
                .catch(err => {
                    this.seatStatusLoading = false
                })
        },
        changeTab(tab){
            console.log('changeTab', tab)
            if (tab.name == 'map') {
                this.searchAvilableSeat()
            }
            if (tab.name == 'gantt') {
                this.getAppointmentSeatReport()
            }
        },
        changePackage(val){
          this.$set(this.checkoutForm, 'customer_package_id', val)
        },
        rollbackCheckout(row){
           this.$confirm('是否撤销该客人的结账？', '提醒', {
                type: 'warning'
            }).then(() => {
              ROLLBACK_CHECKOUT({
                customer_id: row.customer_id, 
                appt_id: row.id, 
              })
                .then(res => {
                  this.$message.success('结账已撤销')
                  this.searchAppointment()
                })
            })
        },
        calAvailableTime(time, type, once_time) {
            if (type == 'times') {
                return `${time / 6}次`
            } else {
                if (once_time > 0) {
                    return `${time / once_time}次`
                } else {
                    return `${time}小时`
                }
            }
        },
        checkArrived(row) {
            var arrived = false
            arrived = row.log.find(item => {
                if (item.status == 2) {
                    return true
                }
            })
            return arrived
        },
        getAppointmentSeatReport() { 
            GET_APPOINTMENT_SEAT_REPORT()
                .then(res => {
                    this.$nextTick(_ => {
                        this.initEcharts(res.data.data)
                    })
                })
        },
        initEcharts (dt) {
            let myEcharts = this.$echarts.init(this.$refs.gantt)

            let color = {
                '0': '#f56c6c',
                '2': '#e6a23c',
                '3': '#67c23a',
            }

            var data = [];
            var startTime = dayjs(dayjs().format('YYYY-MM-DD') + ' 08:00:00').valueOf();
            var categories = [];
            dt.forEach((item, index) => {
                var baseTime = dayjs(item.date + ' ' + item.start_time).valueOf()
                var duration = dayjs(item.date + ' ' + item.end_time).diff(dayjs(item.date + ' ' + item.start_time), 'millisecond')
                categories.push(item.name)
                data.push({
                    name: item.name,
                    value: [index, baseTime, baseTime + duration, duration],
                    itemStyle: {
                        normal: {
                            color: color[item.status]
                        }
                    }
                })
            })
            // // Generate mock data
            // categories.forEach((category, index) => {
            //     var baseTime = startTime;
            //     for (var i = 0; i < dataCount; i++) {
            //         var typeItem = types[Math.round(Math.random() * (types.length - 1))];
            //         var duration = Math.round(Math.random() * 10000);
            //         data.push({
            //             name: typeItem.name,
            //             value: [index, baseTime, (baseTime += duration), duration],
            //             itemStyle: {
            //                 normal: {
            //                     color: typeItem.color
            //                 }
            //             }
            //         });
            //         baseTime += Math.round(Math.random() * 2000);
            //     }
            // });
            console.log('data', data)

            var option = {
                tooltip: {
                    formatter: function (params) {
                        // console.log('params', params)
                        if (params.data != undefined) {
                            return params.data.name + ': ' + dayjs(params.data.value[1]).format('HH:mm') + ' - ' + dayjs(params.data.value[2]).format('HH:mm');
                        }
                    }
                },
                title: {
                    text: `坐席使用情况${dayjs().format('YYYY-MM-DD')}`,
                    left: 'center'
                },
                // dataZoom: [
                //     {
                //         type: 'slider',
                //         filterMode: 'weakFilter',
                //         showDataShadow: false,
                //         top: 400,
                //         labelFormatter: ''
                //     },
                //     {
                //         type: 'inside',
                //         filterMode: 'weakFilter'
                //     }
                // ],
                grid: {
                    height: 400
                },
                xAxis: {
                    min: startTime,
                    max: dayjs(dayjs().format('YYYY-MM-DD') + ' 22:30:00').valueOf(),
                    // scale: true,
                    axisLabel: {
                        formatter: function (val) {
                            return dayjs(val).format('HH:mm')
                        }
                    },
                    axisPointer: {
                        show: true,
                        label: {
                            show: true,
                            formatter: function (params) {
                                console.log('axisPointer', params)
                                return dayjs(params.value).format('HH:mm')
                            }
                        }
                    },
                },
                yAxis: {
                    data: categories
                },
                series: [
                    // {
                    //     type: 'custom',
                    //     renderItem: (params, api) => { 

                    //     },
                    //     data: []
                    // },
                    {
                        type: 'custom',
                        renderItem: (params, api) => {
                            console.log('renderItem', params, api)
                            var categoryIndex = api.value(0);
                            var start = api.coord([api.value(1), categoryIndex]);
                            var end = api.coord([api.value(2), categoryIndex]);
                            var height = api.size([0, 1])[1] * 0.6;
                            var rectShape = this.$echarts.graphic.clipRectByRect(
                                {
                                    x: start[0],
                                    y: start[1] - height / 2,
                                    width: end[0] - start[0],
                                    height: height
                                },
                                {
                                    x: params.coordSys.x,
                                    y: params.coordSys.y,
                                    width: params.coordSys.width,
                                    height: params.coordSys.height
                                }
                            );
                            return (
                                rectShape && {
                                    type: 'rect',
                                    transition: ['shape'],
                                    shape: rectShape,
                                    style: api.style()
                                }
                            );
                        },
                        itemStyle: {
                            opacity: 0.8
                        },
                        encode: {
                            x: [1, 2],
                            y: 0
                        },
                        data: data
                    }
                ]
            };
            myEcharts.setOption(option)
        },
        setArrived(row) {
            SET_ARRIVED({
                appt_id: row.id,
            })
                .then(res => {
                    this.$message.success('已到店')
                    this.searchAppointment()
                })
        },
        openDateTimeEditor(row) {
            this.dateTimeForm.id = row.id
            this.dateTimeForm.date = row.date
            this.dateTimeForm.time = [row.start_time, row.end_time]
            this.dialogDateTimeVisible = true
        },
        saveDateTime() {
            SAVE_DATETIME(this.dateTimeForm)
                .then(res => {
                    this.$message.success('日期时间已修改')
                    this.dialogDateTimeVisible = false
                    this.searchAppointment()
                })
        },
        openArrivedTime(row){
            this.arrivedTimeForm.id = row.id
            this.arrivedTimeForm.date = dayjs(row.arrived_time).format('YYYY-MM-DD')
            this.arrivedTimeForm.time = dayjs(row.arrived_time).format('HH:mm')
            this.dialogArrivedTimeVisible = true
        },
        changeArrivedTime(){
            SAVE_ARRIVED_TIME(this.arrivedTimeForm)
                .then(res => {
                    this.$message.success('到达时间已修改')
                    this.dialogArrivedTimeVisible = false
                    this.searchAppointment()
                })
        }
    }
}
</script>

<style lang="scss">
.is-today {
    &:before {
        content: '[今天]';
        color: red;
    }
}

.subscription {
    border-color: #770777 !important;
}

.seat-tag {
    width: 80px !important;
}

.remark {
    max-width: 500px;
}
.gantt {
    width: 100%;
    height: 500px;
    margin: auto;
}
.btn-link {
    cursor: pointer;
    color: cornflowerblue;
}
</style>