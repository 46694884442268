<template>
    <div class="revenue">
        <el-row>
            <el-col :span="24">
                <el-form v-model="filter" inline size="mini">
                    <el-form-item label="日期">
                        <el-date-picker
                            v-model="filter.date"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="截止">
                        <el-date-picker
                            v-model="filter.exp_date"
                            type="date"
                            value-format="yyyy-MM-dd"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="doSearch">查看</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>

        <el-descriptions title="统计" style="margin-bottom: 20px;" v-if="result.length > 0">
            <el-descriptions-item label="新客">{{new_customer}}人</el-descriptions-item>
            <el-descriptions-item label="新客转化人数">{{new_customer_convertion}}人</el-descriptions-item>
            <el-descriptions-item label="新客留存率">{{new_customer_convertion_per}}</el-descriptions-item>
        </el-descriptions>

        <el-table :data="result">
            <el-table-column
                prop="nickname"
                label="客人"
            >
            </el-table-column>
            <el-table-column
                prop="customer_type"
                label="是否新客"
            >
            </el-table-column>
            <el-table-column
                prop="customer_convertion"
                label="是否转化"
            >
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import {
    GET_CONVERSION_REPORT
} from '../api/report'
import dayjs from 'dayjs'
export default {
    name: 'Conversion',
    mounted(){
        var tokenTime = sessionStorage.getItem('token')
        if(tokenTime == null || tokenTime == '' || this.dayjs().diff(tokenTime, 'seconds') > 3600){
            this.$router.push('/login')
        }
    },
    data(){
        return {
            dayjs: (date) => {
                return dayjs(date)
            },
            filter: {
                date: [dayjs().subtract(1, 'month').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
                exp_date: dayjs().format('YYYY-MM-DD')
            },
            result: [],
            new_customer: 0,
            new_customer_convertion: 0,
            new_customer_convertion_per: 0
        }
    },
    methods: {
        doSearch(){
            GET_CONVERSION_REPORT(this.filter)
                .then(res => {
                    this.result = res.data.data

                    this.new_customer = this.result.filter(item => item.customer_type == 'new').length
                    this.new_customer_convertion = this.result.filter(item => item.customer_type == 'new' && item.customer_convertion == 'yes').length
                    this.new_customer_convertion_per = this.new_customer > 0 ? ((this.new_customer_convertion / this.new_customer) * 100).toFixed(1) + '%' : '-'
                })
        },
    }
}
</script>

<style>

</style>