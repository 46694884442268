<template>
  <div>
    <el-table 
        :data="tableData" 
        v-loading="loading"
    >
      <el-table-column label="分享人">
        <template slot-scope="scope">
          {{scope.row.customer_from.nickname}}
        </template>
      </el-table-column>
      <el-table-column label="被分享人">
        <template slot-scope="scope">
          {{scope.row.customer_to.nickname}}
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          {{status[scope.row.status]}}
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="created_at"></el-table-column>
    </el-table>

    <el-pagination
      @prev-click="changePage(page--)"
      @next-click="changePage(page++)"
      @current-change="changePage"
      :current-page="page"
      :page-size="pageSize"
      layout="prev, pager, next"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import {
  LIST_SHARE
} from '../api/share'
export default {
  data(){
    return {
      dayjs: (date) => {
          return dayjs(date)
      },
      tableData: [],
      page: 1,
      pageSize: 1,
      total: 0,
      loading: false,
      status: {
        '1': '已注册', 
        '2': '已预约', 
        '3': '已确认'
      }
    }
  },
  mounted(){
    this.listShare()
  },
  methods: {
    listShare(){
      this.loading = true
      LIST_SHARE({page: this.page})
        .then(res => {
          console.log('res', res)
          this.tableData = res.data.data.data
          this.total = res.data.data.total
          this.loading = false
        })
        .catch(err => {
          console.error(err)
          this.loading = false
        })
    },
    changePage(page){
        this.page = page
        this.listShare()
    },
  }
}
</script>