import dayjs from 'dayjs'

const util = {
    state: {
        token: '',
        tokenTime: ''
    },
    mutations: {
        setToken (state, token) {
            state.token = token
        },
        setTokenTime (state) {
            state.tokenTime = dayjs()
        },
    },
    actions: {
        
    },
}

export default util