<template>
    <div class="book">
        <el-row>
            <el-col :span="24">
                <el-form :model="form" :inline="true" size="mini" @submit.native.prevent>
                    <el-form-item>
                        <el-button type="primary" @click="openAdd">添加图书</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model="form.search" placeholder="请输入关键词"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="changePage(1)">查找</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <el-table :data="tableData" v-loading="loading">
            <el-table-column label="书名">
                <template slot-scope="scope">
                    <h6>{{scope.row.name}}</h6>
                    <p>{{scope.row.press}}</p>
                </template>
            </el-table-column>
            <el-table-column label="条码" prop="code"></el-table-column>
            <el-table-column label="创建时间" prop="created_at"></el-table-column>
            <el-table-column label="修改时间" prop="updated_at"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="openEditor(scope.row)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            @prev-click="changePage(page--)"
            @next-click="changePage(page++)"
            @current-change="changePage"
            :current-page="page"
            :page-size="pageSize"
            layout="prev, pager, next"
            :total="total"
        >
        </el-pagination>

        <el-dialog title="修改" :visible.sync="dialogFormVisible" :before-close="closeDialog">
            <el-form ref="editor" :model="edit" label-width="120">
                <el-form-item label="书名" prop="name">
                    <el-input v-model="edit.name"></el-input>
                </el-form-item>
                <el-form-item label="出版社" prop="press">
                    <el-input v-model="edit.press"></el-input>
                </el-form-item>
                <el-form-item label="条码" prop="code">
                    <el-input v-model="edit.code"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
// import dayjs from 'dayjs'
import {
    SAVE_BOOK,
    SEARCH_BOOK
} from '../api/book'
export default {
    name: 'Book',
    data(){
        return {
            form: {},
            tableData: [],
            edit: {
                id: 0,
                code: '',
                name: '',
                press: '',
            },
            dialogFormVisible: false,
            page: 1,
            pageSize: 1,
            total: 0,
            loading: false,
        }
    },
    mounted(){
        this.search()
    },
    methods: {
        save(){
            SAVE_BOOK(this.edit)
                .then(res => {
                    console.log(res)
                    this.search()
                    this.dialogFormVisible = false
                    this.resetForm()
                })
                .catch(err => {
                    this.$message.error(err.response.data)
                    console.error(err)
                })
        },
        changePage(page){
            this.page = page
            this.search()
        },
        search(){
            this.loading = true
            SEARCH_BOOK({
                page: this.page,
                search: this.form.search
            })
                .then(res => {
                    this.tableData = res.data.data.data
                    this.total = res.data.data.total
                    this.pageSize = res.data.data.per_page
                    this.loading = false
                })
                .catch(err => {
                    console.error(err)
                    this.$message.error(err.response.data)
                    this.loading = false
                })
        },
        openEditor(row){
            this.dialogFormVisible = true
            this.edit = Object.assign({}, row)
        },
        openAdd(){
            this.dialogFormVisible = true
        },
        closeDialog(){
            this.resetForm()
            this.dialogFormVisible = false
        },
        resetForm(){
            this.edit = {
                id: 0,
                name: '',
                press: '',
                code: '',
            }
        }
    }
}
</script>