// import Home from '../views/Home.vue'
import User from '../views/User.vue'
import Customer from '../views/Customer.vue'
import Appointment from '../views/Appointment.vue'
import Seat from '../views/Seat.vue'
import Package from '../views/Package.vue'
import ProductCategory from '../views/ProductCategory.vue'
import Product from '../views/Product.vue'
import Stock from '../views/Stock.vue'
import Checkout from '../views/Checkout.vue'
import Billing from '../views/Billing.vue'
import Consume from '../views/Consume.vue'
import Book from '../views/Book.vue'
import DoorLock from '../views/DoorLock.vue'
import Message from '../views/Message.vue'
// import Wechat from '../views/Wechat.vue'
import Point from '../views/Point.vue'
import Main from '../views/Main.vue'
import PointList from '../views/PointList.vue'
import PointAdjust from '../views/PointAdjust.vue'
import Share from '../views/Share.vue'
import ReportRevenue from '../views/ReportRevenue.vue'
import ReportConversion from '../views/ReportConversion.vue'
import ReportSeat from '../views/ReportSeat.vue'
import ReportCoupon from '../views/ReportCoupon.vue'
import ReportInventory from '../views/ReportInventory.vue'
import Library from '../views/Library.vue'
import Goods from '../views/Goods.vue'
import Error from '../views/Error.vue'
import Home from '../views/Home.vue'

const routesSetting = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   meta: {
  //     icon: 'el-icon-menu',
  //     label: '首页',
  //     key: 'menu_home'
  //   },
  //   component: Home
  // },
  {
    path: '/error',
    name: 'Error',
    meta: {
      // hide: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Error
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      isLogin: true,
      hide: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      icon: 'el-icon-home',
      label: '首页',
      key: 'menu_home'
    },
    component: Home
  },
  {
    path: '/shop',
    name: 'Shop',
    meta: {
      icon: 'el-icon-shopping-cart-full',
      label: '购物',
      key: 'menu_shop'
    },
    component: Main,
    children: [
      {
        path: 'library',
        name: 'Library',
        meta: {
          label: '图库',
          key: 'menu_shop_library'
        },
        component: Library
      },
      {
        path: 'goods',
        name: 'Goods',
        meta: {
          label: '商品',
          key: 'menu_shop_goods'
        },
        component: Goods
      },
    ]
  },
  {
    path: '/billing',
    name: 'Billing',
    meta: {
      icon: 'el-icon-notebook-1',
      label: '结账',
      key: 'menu_checkout'
    },
    component: Main,
    children: [
      {
        path: 'checkout/:sequence?',
        name: 'Checkout',
        meta: {
          pathWithoutParams: 'checkout',
          label: '结账',
          key: 'menu_checkout_checkout'
        },
        component: Checkout
      },
      {
        path: 'billingList',
        name: 'BillingList',
        meta: {
          label: '账单',
          key: 'menu_checkout_billing'
        },
        component: Billing
      },
      {
        path: 'consumeList',
        name: 'ConsumeList',
        meta: {
          label: '验券',
          key: 'menu_consume'
        },
        component: Consume
      },
    ]
  },
  {
      path: '/customer/:search?/:status?',
      name: 'Customer',
      meta: {
        icon: 'el-icon-s-custom',
        pathWithoutParams: '/customer',
        label: '客人',
        key: 'menu_customer'
      },
      component: Customer
  },
  {
    path: '/appointment',
    name: 'Appointment',
    meta: {
      icon: 'el-icon-time',
      label: '预约',
      key: 'menu_appointment'
    },
    component: Appointment
  },
  {
    path: '/point',
    name: 'Point',
    meta: {
      icon: 'el-icon-star-on',
      label: '学分',
      key: 'menu_point'
    },
    component: Main,
    children: [
      {
        path: 'pointList',
        name: 'PointList',
        meta: {
          label: '学分',
          key: 'menu_point_point'
        },
        component: PointList
      },
      {
        path: 'pointAdjust',
        name: 'PointAdjust',
        meta: {
          label: '学分调整',
          key: 'menu_point_adjust'
        },
        component: PointAdjust
      },
      {
        path: 'share',
        name: 'Share',
        meta: {
          label: '分享',
          key: 'menu_point_share'
        },
        component: Share
      },
    ]
  },
  {
    path: '/product',
    name: 'Product',
    meta: {
      icon: 'el-icon-goods',
      label: '产品',
      key: 'menu_product'
    },
    component: Main,
    children: [
      {
        path: 'package',
        name: 'Package',
        meta: {
          label: '套餐',
          key: 'menu_product_package'
        },
        component: Package
      },
      {
        path: 'productCategory',
        name: 'ProductCategory',
        meta: {
          label: '品类',
          key: 'menu_product_category'
        },
        component: ProductCategory
      },
      {
        path: 'productList',
        name: 'ProductList',
        meta: {
          label: '商品',
          key: 'menu_product_goods'
        },
        component: Product
      },
      {
        path: 'stock',
        name: 'Stock',
        meta: {
          label: '库存',
          key: 'menu_product_stock'
        },
        component: Stock
      },
    ]
  },
  {
    path: '/report',
    name: 'Report',
    meta: {
      icon: 'el-icon-pie-chart',
      label: '报表',
      key: 'menu_report'
    },
    component: Main,
    children: [
      {
        path: 'revenue',
        name: 'Revenue',
        meta: {
          label: '营业额及人数',
          key: 'menu_report_revenue'
        },
        component: ReportRevenue
      },
      {
        path: 'conversion',
        name: 'Conversion',
        meta: {
          label: '转化留存',
          key: 'menu_report_conversion'
        },
        component: ReportConversion
      },
      {
        path: 'seat',
        name: 'Seat',
        meta: {
          label: '坐席使用',
          key: 'menu_report_seat'
        },
        component: ReportSeat
      },
      {
        path: 'coupon',
        name: 'Coupon',
        meta: {
          label: '优惠券使用情况',
          key: 'menu_report_coupon'
        },
        component: ReportCoupon
      },
      {
        path: 'inventory',
        name: 'Inventory',
        meta: {
          label: '库存盘点',
          key: 'menu_report_inventory'
        },
        component: ReportInventory
      },
    ]
  },
  {
    path: '/setting',
    name: 'Setting',
    meta: {
      icon: 'el-icon-s-tools',
      label: '设置',
      key: 'menu_setting'
    },
    component: Main,
    children: [
      {
        path: 'pointList',
        name: 'PointList',
        meta: {
          label: '学分',
          key: 'menu_setting_point'
        },
        component: Point
      },
      {
        path: 'book',
        name: 'Book',
        meta: {
          label: '图书',
          key: 'menu_setting_book'
        },
        component: Book
      },
      {
        path: 'seat',
        name: 'Seat',
        meta: {
          label: '坐席',
          key: 'menu_setting_seat'
        },
        component: Seat
      },
      {
        path: 'user',
        name: 'User',
        meta: {
          label: '用户',
          key: 'menu_setting_user'
        },
        component: User
      },
      {
        path: 'doorLock',
        name: 'DoorLock',
        meta: {
          label: '门禁',
          key: 'menu_setting_door_lock'
        },
        component: DoorLock
      },
      {
        path: 'message',
        name: 'Message',
        meta: {
          label: '通知',
          key: 'menu_setting_message'
        },
        component: Message
      },
    ]
  },
  
  // {
  //     path: '/wechat',
  //     name: 'Wechat',
  //     meta: {
  //         keepAlive: true,
  //     },
  //     component: Wechat
  // },
  
]

export default routesSetting