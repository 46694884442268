<template>
    <div class="consume">
        <el-row>
            <el-col :span="24">
                <el-form :model="form" inline @submit.native.prevent>
                    <el-form-item label="验券">
                        <el-input ref="form_code" v-model="form.code" autofocus @keyup.enter.native="receiptPrepare"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="查找">
                        <el-input v-model="form.search" placeholder="请输入手机号或昵称、备注等信息"></el-input>
                    </el-form-item> -->
                    <!-- <el-form-item>
                        <el-button type="success" @click="searchBilling(1)">查找</el-button>
                    </el-form-item> -->
                </el-form>
            </el-col>
        </el-row>
        <el-table :data="orders" v-loading="loading">
            <el-table-column label="日期" prop="created_at">
                <template slot-scope="scope">
                    {{dayjs(scope.row.created_at).format('YYYY-MM-DD HH:mm')}}
                </template>
            </el-table-column>
            <el-table-column label="单号" prop="order_id" width="240"></el-table-column>
            <el-table-column label="券码" prop="receipt_code"></el-table-column>
            <el-table-column label="团购券" prop="deal_title" width="300"></el-table-column>
            <el-table-column label="购买价" prop="deal_price">
                <template slot-scope="scope">
                    {{scope.row.orders_payment_detail != null && scope.row.orders_payment_detail.find(item => item.amount_type == 17) != undefined ? scope.row.deal_price - scope.row.orders_payment_detail.find(item => item.amount_type == 17).amount : scope.row.deal_price}}
                </template>
            </el-table-column>
            <el-table-column label="手机" prop="mobile"></el-table-column>
            <el-table-column label="购卡人" width="200">
                <template slot-scope="scope">
                    <div v-if="scope.row.customer_package_id > 0 && scope.row.customer_package != null" class="customer-area">
                        <el-avatar icon="el-icon-user-solid" size="large" shape="circle" :src="scope.row.customer_package.customer_info.headimgurl" fit="fill"></el-avatar>
                        <span>{{scope.row.customer_package.customer_info.nickname}}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="状态" prop="deleted_at">
                <template slot-scope="scope">
                    {{scope.row.deleted_at != null ? '已撤销' : '已验券'}}
                </template>
            </el-table-column>
            <el-table-column label="操作" width="260">
                <template slot-scope="scope">
                    <el-button type="success" size="mini" @click="rollback(scope.row)">撤销</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            @prev-click="changePage(page--)"
            @next-click="changePage(page++)"
            @current-change="changePage"
            :current-page="page"
            :page-size="pageSize"
            layout="prev, pager, next"
            :total="total"
        >
        </el-pagination>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import {
    GET_ORDERS
} from '../api/order'
import {
    RECEIPT_REVERSE_CONSUME,
    RECEIPT_PREPARE,
    RECEIPT_CONSUME,
} from '../api/customer'
export default {
    name: 'BillingList',
    data(){
        return {
            dayjs: (date)=>{
                return dayjs(date)
            },
            form: {
                code: ''
            },
            orders: [],
            page: 1,
            pageSize: 1,
            total: 0,
            loading: false,
            order: {},
            consumeLoading: ''
        }
    },
    mounted(){
        this.$nextTick(_ => {
            this.$refs['form_code'].focus()
        })
        this.getOrders()
    },
    methods: {
        receiptPrepare() { 
            RECEIPT_PREPARE({
                receipt_code: this.form.code
            })
                .then(res => {
                    if (res.data.code == 0) {
                        this.order = res.data.data
                        console.log('this.order', this.order)

                        this.$confirm(`团购${this.order.deal_title}${this.order.count}张（金额：${this.order.deal_price}），是否确认验券？`, '提醒', {
                            type: 'warning'
                        }).then(() => {
                            this.receiptConsume()
                        }).catch(() => {

                        });
                    } else {
                        this.$message.warning(res.data.data)
                    }
                })
                .catch(err => {
                    console.error(err)
                    this.$message.error(err.response.data.data)
                })
        },
        receiptConsume() { 
            this.consumeLoading = this.$loading({
                lock: true,
                text: '验券中，请勿刷新页面，请勿输入其他内容，等待系统反馈。如长时间无反应请联系系统管理员。',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            RECEIPT_CONSUME({
                requestid: this.order.deal_id.toString(),
                receipt_code: this.form.code,
                count: this.order.count
            })
                .then(res => {
                    this.$message.success('验券成功')
                    this.consumeLoading.close()
                })
                .catch(err => {
                    console.error(err)
                    this.$message.error(err.response.data.data)
                    this.consumeLoading.close()
                })
        },
        changePage(page){
            this.page = page
            this.getOrders(this.page)
        },
        getOrders(page){
            this.loading = true
            var params = {
                page: page
            }
            GET_ORDERS(params)
                .then(res => {
                    this.orders = res.data.data.data
                    this.total = res.data.data.total
                    this.pageSize = res.data.data.per_page
                    this.loading = false
                })
                .catch(err => {
                    console.error(err)
                    this.loading = false
                })
        },
        rollback(row) {
            this.$confirm(`团购${row.deal_title}（金额：${row.deal_price}），是否确认撤销？`, '提醒', {
                type: 'warning'
            }).then(() => {
                RECEIPT_REVERSE_CONSUME({
                    order_id: row.id,
                    app_deal_id: row.deal_id,
                    receipt_code: row.receipt_code
                })
                    .then(res => {
                        if (res.data.code == 0) {
                            this.$message.success('已撤回')
                            this.getOrders(1)
                        } else {
                            this.$message.warning(res.data.data)
                        }
                    })
            }).catch(() => {

            });
        }
    }
}
</script>

<style lang="scss">
.customer-area {
    display: flex;
    align-items: center;

    .el-avatar {
        margin-right: 10px;
    }
}
</style>