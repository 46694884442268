<template>
    <div class="revenue">
        <el-row>
            <el-col :span="24">
                <el-form v-model="filter" inline size="mini">
                    <el-form-item label="日期">
                        <el-date-picker
                            v-model="filter.date"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            unlink-panels
                            :picker-options="pickerOptions"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="doSearch">查看</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <div ref="bar" class="bar"></div>
    </div>
</template>

<script>
import {
    GET_SEAT_COUNT_REPORT
} from '../api/report'
import dayjs from 'dayjs'
export default {
    name: 'Revenue',
    mounted(){
        var tokenTime = sessionStorage.getItem('token')
        if(tokenTime == null || tokenTime == '' || this.dayjs().diff(tokenTime, 'seconds') > 3600){
            this.$router.push('/login')
        }
    },
    data(){
        return {
            dayjs: (date) => {
                return dayjs(date)
            },
            filter: {
                date: [dayjs().subtract(1, 'month').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]
            },
            pickerOptions: {
                shortcuts: [
                    {
                        text: '本月',
                        onClick(picker) {
                            const start = dayjs().startOf('month').format('YYYY-MM-DD')
                            const end =  dayjs().endOf('month').format('YYYY-MM-DD')
                            picker.$emit('pick', [start, end])
                        }
                    },
                    {
                        text: '三个月',
                        onClick(picker) {
                            const start = dayjs().subtract(3, 'months').startOf('month').format('YYYY-MM-DD')
                            const end =  dayjs().endOf('month').format('YYYY-MM-DD')
                            picker.$emit('pick', [start, end])
                        }
                    },
                    {
                        text: '半年',
                        onClick(picker) {
                            const start = dayjs().subtract(6, 'months').startOf('month').format('YYYY-MM-DD')
                            const end =  dayjs().endOf('month').format('YYYY-MM-DD')
                            picker.$emit('pick', [start, end])
                        }
                    },
                ]
            }
        }
    },
    methods: {
        doSearch(){
            GET_SEAT_COUNT_REPORT(this.filter)
                .then(res => {
                    var data = res.data.data
                    this.initEcharts(data)
                })
        },
        initEcharts (data) {
            var category = []
            var dt = []
            // var total = 0
            data.forEach(item => {
                category.push(item.name)
                dt.push(item.seat_count)
                // total+=item.num
            })
            let myEcharts = this.$echarts.init(this.$refs.bar)
            let option = {
                title: {
                    text: `坐席使用分布`
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: category,
                    axisLabel: {
                        interval: 0
                    }
                },
                yAxis: {
                    type: 'value'
                },
                legend: {},
                series: [
                    {
                        name: '坐席使用分布',
                        type: 'bar',
                        data: dt
                    }
                ]
            }
            myEcharts.setOption(option)
        },
    }
}
</script>

<style>
.bar {
    width: 100%;
    height: 350px;
    margin: auto;
}
</style>