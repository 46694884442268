import {fetchPost} from '../common/http.js'

export const SEARCH_BILLING=params=>fetchPost('/api/billing/searchBilling', params)
export const GET_BILLING=params=>fetchPost('/api/billing/getBilling', params)
export const DO_PAY=params=>fetchPost('/api/billing/doPay', params)
export const ROLLBACK_PAY=params=>fetchPost('/api/billing/rollbackPay', params)
export const CANCEL_PAY=params=>fetchPost('/api/billing/cancelPay', params)
export const SEND_PAY=params=>fetchPost('/api/billing/sendPay', params)
export const CHECK_PAY=params=>fetchPost('/api/billing/checkPay', params)
export const SEND_PAY_WECHAT=params=>fetchPost('/api/wechat/sendPayWechat', params)
export const CHECK_PAY_WECHAT=params=>fetchPost('/api/wechat/checkPayWechat', params)
export const UPDATE_PAY_STATUS=params=>fetchPost('/api/billing/updatePayStatus', params)
export const UPDATE_IS_PAY=params=>fetchPost('/api/billing/updateIsPay', params)
// export const SEARCH_STOCK=params=>fetchPost('/api/stock/searchStock', params)
// export const GET_STOCK_DETAIL=params=>fetchPost('/api/stock/getStockDetail', params)
// export const STOCK_OUT=params=>fetchPost('/api/stock/stockOut', params)
// export const STOCK_ROLLBACK=params=>fetchPost('/api/stock/stockRollback', params)
// export const STOCK_DELETE=params=>fetchPost('/api/stock/stockDelete', params)
// export const DO_PAY=params=>fetchPost('/api/billing/doPay', params)