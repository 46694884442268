<template>
    <div class="library">
        <el-upload
            action="https://api.viewinn.cn/api/library/upload"
            list-type="picture-card"
            :file-list="fileList"
            :headers="headers"
        >
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{file}">
                <div class="img-wrap">
                    <img
                        class="el-upload-list__item-thumbnail"
                        :src="file.url" alt=""
                    >
                </div>
                <span class="el-upload-list__item-actions">
                    <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                    >
                    <i class="el-icon-zoom-in"></i>
                    </span>
                    <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                    >
                    <i class="el-icon-delete"></i>
                    </span>
                </span>
            </div>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>
import {
    GET_LIBRARY,
    DELETE_LIBRARY
} from '@/api/library'

export default {
    name: 'Library',
    data() {
        return {
            fileList: [],
            dialogImageUrl: '',
            dialogVisible: false,
            disabled: false,
            headers: {
                'Authorization': sessionStorage.getItem('token') 
            }
        }
    },
    mounted() {
        this.getLibrary()
    },
    methods: {
        getLibrary() { 
            GET_LIBRARY()
                .then(res => {
                    this.fileList = []
                    res.data.data.forEach(item => {
                        this.fileList.push({
                            library_id: item.id,
                            url: item.url
                        })
                    })
                })
        },
        handleRemove(file) {
            console.log(file);

            this.$confirm('是否删除图片，删除后无法恢复，谨慎操作', '警告', {
                type: 'warning'
            }).then(() => {
                DELETE_LIBRARY({
                    id: file.library_id
                })
                    .then(res => {
                        this.$message.success('删除成功')
                        this.getLibrary()
                    })
            }).catch(() => {
                        
            });
        },
        handlePictureCardPreview(file) {
            this.fileList.push(file.url)
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
    }
}
</script>

<style lang="scss">
.el-upload {
    float: left;
}

.el-upload-list {
    margin-left: 10px;
}

.img-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;

    img {
        position: absolute;
    }
}
</style>