<template>
  <div>
    <el-table :data="data" v-loading="loading">
      <el-table-column label="优惠券类型" prop="type"></el-table-column>
      <el-table-column label="券码" prop="code"></el-table-column>
      <el-table-column label="学分" prop="point"></el-table-column>
      <el-table-column label="状态" prop="status">
        <template slot-scope="scope">
          {{scope.row.status == 0 ? '未使用' : '已使用'}}
        </template>
      </el-table-column>
      <el-table-column label="客人" prop="customer">
        <template slot-scope="scope">
          {{scope.row.customer.nickname}}
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="created_at"></el-table-column>
      <el-table-column label="修改时间" prop="updated_at"></el-table-column>
    </el-table>

    <el-pagination
      @prev-click="changePage(page--)"
      @next-click="changePage(page++)"
      @current-change="changePage"
      :current-page="page"
      :page-size="pageSize"
      layout="prev, pager, next"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { GET_COUPON_REPORT } from "../api/report";
import dayjs from "dayjs";
export default {
  name: "Revenue",
  mounted() {
    this.doSearch();
  },
  data() {
    return {
      dayjs: (date) => {
        return dayjs(date);
      },
      data: [],
      page: 1,
      pageSize: 1,
      total: 0,
      loading: false,
      filter: {
        date: [
          dayjs().subtract(1, "month").format("YYYY-MM-DD"),
          dayjs().format("YYYY-MM-DD"),
        ],
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "本月",
            onClick(picker) {
              const start = dayjs().startOf("month").format("YYYY-MM-DD");
              const end = dayjs().endOf("month").format("YYYY-MM-DD");
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "三个月",
            onClick(picker) {
              const start = dayjs()
                .subtract(3, "months")
                .startOf("month")
                .format("YYYY-MM-DD");
              const end = dayjs().endOf("month").format("YYYY-MM-DD");
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "半年",
            onClick(picker) {
              const start = dayjs()
                .subtract(6, "months")
                .startOf("month")
                .format("YYYY-MM-DD");
              const end = dayjs().endOf("month").format("YYYY-MM-DD");
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    doSearch() {
      GET_COUPON_REPORT(this.filter)
        .then((res) => {
          this.data = res.data.data.data;
          this.total = res.data.data.total;
          this.pageSize = res.data.data.per_page;
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
          this.$message.error(err.response.data);
          this.loading = false;
        });
    },
    changePage(page) {
      this.page = page;
      this.doSearch();
    },
  },
};
</script>