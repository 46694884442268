<template>
  <div>
  <el-row>
      <el-col :span="24">
        <el-form :model="form" inline @submit.native.prevent>
          <el-form-item label="查找">
            <el-input v-model="form.search" placeholder="请输入手机号或昵称查找" @keyup.enter.native="listPointLog" clearable @change="changeKeyword"></el-input>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="form.no_pay_only">仅看待付学分</el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-button type="success" @click="listPointLog">查找</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-table 
        :data="tableData" 
        v-loading="loading"
    >
        <el-table-column label="客人昵称">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" placement="right" popper-class="remark">
                <div slot="content">
                    <p v-if="scope.row.customer.remark != ''">
                        备注：{{scope.row.customer.remark}}
                    </p>
                </div>
                <span>
                  <i class="el-icon-warning" v-if="scope.row.customer.blacklist == 1"></i>
                  <el-link :href="`/customer/${scope.row.customer.cellphone}`" type="primary">
                    {{scope.row.customer != null ? `${scope.row.customer.nickname}(自习 ${scope.row.customer.use_log.length} 次)` : '已删除客人'}}
                  </el-link>
                </span>
            </el-tooltip>
                
            </template>
        </el-table-column>
        <el-table-column label="当前学分">
            <template slot-scope="scope">
                {{scope.row.customer != null ? scope.row.customer.points : '-'}}
            </template>
        </el-table-column>
        <el-table-column label="兑换套餐">
            <template slot-scope="scope">
                {{scope.row.customer_package != null ? scope.row.customer_package.package_info.name : '-'}}
                {{scope.row.customer_package != null ? (scope.row.customer_package.package_info.type == 'hours' ? `（剩余：${scope.row.customer_package.available_time}小时）` : `（剩余：${scope.row.customer_package.available_time / 6}次）`) : ''}}
            </template>
        </el-table-column>
        <el-table-column label="原由" prop="reason"></el-table-column>
        <el-table-column label="学分" prop="point"></el-table-column>
        <el-table-column label="创建时间" prop="created_at"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" v-show="(scope.row.point > 0 && scope.row.status == 1 && scope.row.customer_package != null) || (scope.row.point > 0 && scope.row.status == 1 && scope.row.reason == '首次注册送学分')" type="primary" @click="confirmPoint(scope.row)">确认</el-button>
          </template>
        </el-table-column>
    </el-table>

    <el-pagination
        @prev-click="changePage(page--)"
        @next-click="changePage(page++)"
        @current-change="changePage"
        :current-page="page"
        :page-size="pageSize"
        layout="prev, pager, next"
        :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import {
  LIST_POINT_LOG,
  CONFIRM_POINT
} from '../api/point'
export default {
  data(){
    return {
      dayjs: (date) => {
          return dayjs(date)
      },
      tableData: [],
      page: 1,
      pageSize: 20,
      total: 0,
      loading: false,
      form: {
        search: '',
        no_pay_only: false
      }
    }
  },
  mounted(){
    this.listPointLog()
  },
  methods: {
    listPointLog(){
      this.loading = true
      LIST_POINT_LOG({page: this.page, search: this.form.search, no_pay_only: this.form.no_pay_only})
        .then(res => {
          console.log('res', res)
          this.tableData = res.data.data.data
          this.total = res.data.data.total
          this.loading = false
        })
        .catch(err => {
          console.error(err)
          this.loading = false
        })
    },
    changePage(page){
        this.page = page
        this.listPointLog()
    },
    confirmPoint(row){
      this.$confirm('是否确认该积分', '提醒', {
        type: 'warning'
      }).then(() => {
          CONFIRM_POINT({
            id: row.id
          })
            .then(res => {
              this.$message.success('积分已确认')
              this.listPointLog()
            })
      }).catch(() => {
                  
      });
    },
    changeKeyword(val){
      if(val == ''){
        this.listPointLog()
      }
    },
  }
}
</script>