<template>
    <div class="revenue">
        <el-row>
            <el-col :span="24">
                <el-form v-model="filter" inline size="mini">
                    <el-form-item label="日期">
                        <el-date-picker
                            v-model="filter.date"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            unlink-panels
                            :picker-options="pickerOptions"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="doSearch">查看</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <div ref="bar" class="bar"></div>
        <div ref="billing" class="bar"></div>
    </div>
</template>

<script>
import {
    GET_APPOINTMENT_REPORT,
    GET_BILLING_REPORT
} from '../api/report'
import dayjs from 'dayjs'
export default {
    name: 'Revenue',
    mounted(){
        var tokenTime = sessionStorage.getItem('token')
        if(tokenTime == null || tokenTime == '' || this.dayjs().diff(tokenTime, 'seconds') > 3600){
            this.$router.push('/login')
        }
    },
    data(){
        return {
            dayjs: (date) => {
                return dayjs(date)
            },
            filter: {
                date: [dayjs().subtract(1, 'month').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]
            },
            pickerOptions: {
                shortcuts: [
                    {
                        text: '本月',
                        onClick(picker) {
                            const start = dayjs().startOf('month').format('YYYY-MM-DD')
                            const end =  dayjs().endOf('month').format('YYYY-MM-DD')
                            picker.$emit('pick', [start, end])
                        }
                    },
                ]
            }
        }
    },
    methods: {
        doSearch(){
            GET_APPOINTMENT_REPORT(this.filter)
                .then(res => {
                    var data = res.data.data
                    this.initEcharts(data)
                })
            GET_BILLING_REPORT(this.filter)
                .then(res => {
                    var data = res.data.data
                    this.initBillingEcharts(data)
                })
        },
        initEcharts (data) {
            var category = []
            var dt = []
            var dtNew = []
            var total = 0
            var totalNew = 0

            var dataObj = {}
            data.forEach(item => {
                dataObj[item.date] = item
            })

            var currentDate = dayjs(this.filter.date[0])
            while (currentDate.isBefore(dayjs(this.filter.date[1]).add(1, 'day'))) {
                var currentDateStr = currentDate.format('YYYY-MM-DD')
                category.push(currentDateStr)
                if (dataObj[currentDateStr] != undefined) {
                    dt.push(dataObj[currentDateStr].num - dataObj[currentDateStr].new_customer)
                    dtNew.push(dataObj[currentDateStr].new_customer)
                    total += dataObj[currentDateStr].num
                    totalNew += dataObj[currentDateStr].new_customer
                } else {
                    dt.push(0)
                    dtNew.push(0)
                }
                currentDate = currentDate.add(1, 'day')
            }
            
            let myEcharts = this.$echarts.init(this.$refs.bar)
            let option = {
                title: {
                    text: `自习人数 总计：${total}人次 其中新客${totalNew}人 新客占比${((totalNew/total)*100).toFixed(1)}%（${this.filter.date[0]} 至 ${this.filter.date[1]}）`
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: category
                },
                yAxis: {
                    type: 'value'
                },
                legend: {
                    right: '20'
                },
                series: [
                    {
                        name: '新客',
                        type: 'bar',
                        stack: 'total',
                        data: dtNew
                    },
                    {
                        name: '老客',
                        type: 'bar',
                        stack: 'total',
                        data: dt
                    },
                ]
            }
            myEcharts.setOption(option)
        },
        initBillingEcharts (data) {
            var category = []
            var dt = []
            var total = 0

            var dataObj = {}
            data.forEach(item => {
                dataObj[item.date] = item
            })

            var currentDate = dayjs(this.filter.date[0])
            while (currentDate.isBefore(dayjs(this.filter.date[1]).add(1, 'day'))) {
                var currentDateStr = currentDate.format('YYYY-MM-DD')
                category.push(currentDateStr)
                if (dataObj[currentDateStr] != undefined) {
                    dt.push(dataObj[currentDateStr].amount)
                    total += parseFloat(dataObj[currentDateStr].amount)
                } else {
                    dt.push(0)
                }
                currentDate = currentDate.add(1, 'day')
            }

            let myEcharts = this.$echarts.init(this.$refs.billing)
            let option = {
                title: {
                    text: `营业额 总计：${total.toFixed(2)}元（${this.filter.date[0]} 至 ${this.filter.date[1]}）`
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: {
                    type: 'category',
                data: category
                },
                yAxis: {
                    type: 'value'
                },
                legend: {},
                series: [
                    {
                        name: '营业额',
                        type: 'bar',
                        data: dt
                    }
                ]
            }
            myEcharts.setOption(option)
        }
    }
}
</script>

<style>
.bar {
    width: 100%;
    height: 350px;
    margin: auto;
}
</style>