<template>
    <div class="checkout">
        <el-row>
            <el-col :span="24">
                <el-form :model="search" :inline="true" size="mini" @submit.native.prevent>
                    <el-form-item v-show="false">
                        <el-button type="primary" @click="openAdd" :disabled="isCheckouted">添加商品</el-button>
                    </el-form-item>
                    <el-form-item label="条码">
                        <el-input ref="form_code" autofocus v-model="search.code" @keyup.enter.native="getProduct"></el-input>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>

        <el-dialog title="添加商品" :visible.sync="dialogFormVisible" :before-close="closeDialog">
            <el-form :model="edit" ref="editor" label-width="120px">
                <el-form-item label="品类" prop="name">
                    <el-select v-model="edit.category_id" placeholder="请选择" @change="listProduct">
                        <el-option
                            v-for="(item, key) in category"
                            :key="key"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="商品名" prop="name">
                    <el-select v-model="edit.id" placeholder="请选择" filterable @change="chooseProduct">
                        <el-option
                            v-for="(item, key) in product"
                            :key="key"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="售价" prop="price">
                    <el-input v-model="edit.price"></el-input>
                </el-form-item>
                <el-form-item label="折扣" prop="discount">
                    <el-input v-model="edit.discount"></el-input>
                </el-form-item>
                <el-form-item label="数量" prop="number">
                    <el-input v-model="edit.number" :disabled="true"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="confirm">确 定</el-button>
            </div>
        </el-dialog>


        <el-row :gutter="20" class="checkout-area">
            <el-col :span="12" :offset="0">
                <el-cascader-panel 
                    v-model="edit.id" 
                    ref="productCascader"
                    filterable
                    :options="category" 
                    :props="props"
                    @change="confirm"
                >
                </el-cascader-panel>
                <el-row :gutter="20">
                    <el-col :span="12" :offset="0">
                        <el-row :span="24" v-for="(pay, p_idx) in payment" :key="p_idx">
                            <el-form :model="pay" :disabled="isCheckouted" size="small" label-width="80px">
                            <el-col style="padding: 20px 0 0;">
                                <el-form-item label="快捷支付">
                                    <!-- <el-button type="success" @click="setPayment('wechat')" :disabled="checkout.length == 0">微信</el-button>
                                    <el-button type="primary" @click="setPayment('alipay')" :disabled="checkout.length == 0">支付宝</el-button> -->
                                    <el-button type="warning" @click="setUtilPayment" :disabled="checkout.length == 0">快捷支付</el-button>
                                </el-form-item>
                            </el-col>
                            <el-col>
                                <el-form-item label="支付方式">
                                    <el-select v-model="pay.method" placeholder="请选择" style="width: 100%" :disabled="checkout.length == 0">
                                        <el-option 
                                            v-for="(method, method_idx) in paymethod" 
                                            :key="method_idx"
                                            :value="method_idx"
                                            :label="method"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="支付金额">
                                    <el-input v-model="pay.amount" placeholder="请输入实际金额" :disabled="checkout.length == 0"></el-input>
                                </el-form-item>
                                <!-- <el-form-item>
                                    <el-button type="danger" @click="removePayMethod(p_idx)" :disabled="isCheckouted">删除</el-button>
                                </el-form-item> -->
                            </el-col>
                            </el-form>
                        </el-row>
                    </el-col>
                    <el-col :span="12" :offset="0">
                        <el-row v-show="payment.length > 0 && this.billing.ispay != 1 && payment[0].method == 'alipay'">
                            <el-col :span="24" style="padding: 20px 0 0;">
                                <el-form :model="alipay" label-width="80px" size="small">
                                    <el-form-item label="标题">
                                        <el-input v-model="alipay.subject" disabled></el-input>
                                    </el-form-item>
                                    <el-form-item label="订单号">
                                        <el-input v-model="alipay.out_trade_no" disabled></el-input>
                                    </el-form-item>
                                    <el-form-item label="金额">
                                        <el-input v-model="alipay.total_amount" disabled></el-input>
                                    </el-form-item>
                                    <el-form-item label="条码">
                                        <el-input ref="alipay_buyer_id" disabled autofocus v-model="alipay.buyer_id" @keyup.enter.native="sendPay"></el-input>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                        </el-row>

                        <el-row v-show="payment.length > 0 && this.billing.ispay != 1 && payment[0].method == 'wechat'">
                            <el-col :span="24" style="padding: 20px 0 0;">
                                <el-form :model="wechat" label-width="80px" size="small">
                                    <el-form-item label="标题">
                                        <el-input v-model="wechat.subject" disabled></el-input>
                                    </el-form-item>
                                    <el-form-item label="订单号">
                                        <el-input v-model="wechat.out_trade_no" disabled></el-input>
                                    </el-form-item>
                                    <el-form-item label="金额">
                                        <el-input v-model="wechat.total_amount" disabled></el-input>
                                    </el-form-item>
                                    <el-form-item label="条码">
                                        <el-input ref="wechat_buyer_id" disabled autofocus v-model="wechat.buyer_id" @keyup.enter.native="sendPayWechat"></el-input>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-row v-show="payment.length > 0">
                    <el-col :span="24" style="padding: 20px 0;">
                        <el-button type="primary" @click="doPay" :disabled="isCheckouted || checkout.length == 0">结账</el-button>
                        <el-button type="danger" @click="rollback" :disabled="!isCheckouted">撤销结账</el-button>
                        <el-button type="success" @click="buyNew" :disabled="!isCheckouted">购买新商品</el-button>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12" :offset="0">
                <el-table :data="checkout" show-summary :summary-method="getSummaries">
                    <!-- <el-table-column label="条码">
                        <template slot-scope="scope">
                            {{scope.row.product_id.toString().padStart(6, '0')}}{{scope.row.stock_id.toString().padStart(6, '0')}}
                        </template>
                    </el-table-column> -->
                    <el-table-column label="类别">
                        <template slot-scope="scope">
                            {{scope.row.category_name}}
                        </template>
                    </el-table-column>
                    <el-table-column label="商品">
                        <template slot-scope="scope">
                            {{scope.row.product_name}}
                        </template>
                    </el-table-column>
                    <el-table-column label="单价">
                        <template slot-scope="scope">
                            {{scope.row.price}}元
                        </template>
                    </el-table-column>
                    <el-table-column label="折扣">
                        <template slot-scope="scope">
                            {{scope.row.discount}}
                        </template>
                    </el-table-column>
                    <el-table-column label="数量">
                        <template slot-scope="scope">
                            ✖️{{scope.row.number}}
                        </template>
                    </el-table-column>
                    <el-table-column label="售价" prop="total">
                        <template slot-scope="scope">
                            {{scope.row.total}}元
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button type="danger" @click="remove(scope.$index)" size="mini" :disabled="isCheckouted">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
        
        

        <el-row v-show="false">
            <el-col :span="24" style="padding: 20px 0;">
                <el-button type="primary" @click="addPay" :disabled="isCheckouted">添加支付</el-button>
            </el-col>
        </el-row>
        
        <el-dialog
            title="支付结果"
            :visible.sync="payResultVisible"
            width="30%"
        >
            <el-result icon="success" title="本单已支付"></el-result>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="payResultVisible = false">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog
            title="扫码支付"
            :visible.sync="payCodeVisible"
            width="30%"
        >
            <el-row :gutter="20">
                <el-col :span="24" :offset="0">请使用扫码枪，扫描客户手机中微信或者支付宝的付款码。扫码时候，需要保持下列输入框激活状态。</el-col>
                <el-col :span="24" :offset="0">
                    <el-input ref="code_number" v-model="codeNumber" @keyup.enter.native="setCode"></el-input>
                </el-col>
            </el-row>
        </el-dialog>

        <el-dialog
            title="通用扫码支付(不用区分支付宝或微信)"
            :visible.sync="payCodeUtilVisible"
            width="30%"
        >
            <el-row :gutter="20">
                <el-col :span="24" :offset="0">请使用扫码枪，扫描客户手机中微信或者支付宝的付款码。扫码时候，需要保持下列输入框激活状态。</el-col>
                <el-col :span="24" :offset="0">
                    <el-input ref="code_number_util" v-model="codeNumberUtil" size="medium" @keyup.enter.native="setCodeUtil"></el-input>
                </el-col>
            </el-row>
        </el-dialog>
        
    </div>
</template>

<script>
import {
    SEARCH_CATEGORY,
    GET_PRODUCT_BY_CATEGORY_ID,
    GET_PRODUCT,
} from '../api/product'
import {
    DO_PAY,
    GET_BILLING,
    CANCEL_PAY,
    ROLLBACK_PAY,
    SEND_PAY,
    CHECK_PAY,
    UPDATE_PAY_STATUS,
} from '../api/billing'
export default {
    name: 'Checkout',
    data() {
        var _this = this
        return {
            payLoading: '',
            alipay: {
                subject: '',
                out_trade_no: '',
                total_amount: '',
                buyer_id: '',
            },
            wechat: {
                subject: '',
                out_trade_no: '',
                total_amount: '',
                buyer_id: '',
            },
            sequence: '',
            payment: [],
            search: {
                code: ''
            },
            checkout: [],
            category: [],
            product: [],
            categoryObj: {},
            productObj: {},
            dialogFormVisible: false,
            payResultVisible: false,
            payCodeVisible: false,
            payCodeUtilVisible:false,
            codeNumber: '',
            codeNumberUtil: '',
            edit: {
                id: 0,
                stock_id: '',
                category_id: '',
                category_name: '',
                product_id: '',
                product_name: '',
                price: 0,
                stock_price: '',
                retail_price: '',
                discount: 1,
                code: true,
                type: 'in',
                status: 0,
                number: 1,
                remark: '',
            },
            billing: {},
            isCheckouted: false,
            checkTime: '',
            paymethod: {
                // 'meituan': '美团点评',
                'wechat_qr': '微信自助扫码',
                'alipay_qr': '支付宝自助扫码',
                'cash': '现金',
                'alipay': '扫客户支付宝付款码',
                'wechat': '扫客户微信付款码',
            },
            // total: 0,
            props: {
                lazy: true,
                lazyLoad(node, resolve) {
                    console.log('node', node)
                    // const { level } = node;

                    // setTimeout(() => {
                    // const nodes = Array.from({ length: level + 1 })
                    //     .map(item => ({
                    //     value: ++id,
                    //     label: `选项${id}`,
                    //     leaf: level >= 2
                    //     }));
                    // // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                    // resolve(nodes);
                    // }, 1000);
                    if (node.value != undefined) {
                        GET_PRODUCT_BY_CATEGORY_ID({ category_id: node.value })
                            .then(res => {
                                _this.product = res.data.data
                                _this.product.forEach(item => {
                                    item.value = item.id
                                    item.label = (item.brand != null ? item.brand : '') + item.name + (item.spec != null ? item.spec : '')
                                    item.leaf = 'leaf'
                                    // this.productObj[item.id] = item
                                })
                                // this.edit.category_name = this.categoryObj[category_id]
                                // const nodes = Array.from({ length: level + 1 })
                                //     .map(item => ({
                                //     value: ++id,
                                //     label: `选项${id}`,
                                //     leaf: level >= 2
                                //     }));
                                // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                                resolve(_this.product);
                            })
                            .catch(err => {
                                console.error(err)
                            })
                    }
                }
            }
        }
    },
    mounted(){
        console.log(this.$route)
        this.sequence = this.$route.params.sequence
        this.listCategory()
        if(this.sequence != undefined){
            this.getBilling()
            this.payResultVisible = true
        }
        this.$nextTick(_ => {
            this.$refs['form_code'].focus()
        })
        this.addPay()
    },
    methods: {
        openAdd(){
            this.dialogFormVisible = true
        },
        closeDialog(){
            this.resetForm()
            this.dialogFormVisible = false
        },
        confirm(node) {
            console.log('node', node, this.category, this.product)
            const category_name = this.category.find(item => item.value == node[0]).label
            const product_name = this.product.find(item => item.value == node[1]).label
            const price = this.product.find(item => item.value == node[1]).price
            const discount = this.product.find(item => item.value == node[1]).discount
            this.edit = {
                id: node[1],
                stock_id: '',
                category_id: node[0],
                category_name: category_name,
                product_id: node[1],
                product_name: product_name,
                stock_price: 0,
                retail_price: 0,
                price: price,
                discount: discount,
                code: true,
                type: 'in',
                status: 0,
                number: 1,
                remark: '',
                total: (price * 1 * discount).toFixed(2)
            }
            // this.edit.total = (this.edit.price * this.edit.number * this.edit.discount).toFixed(2)
            this.checkout.push(this.edit)
            // this.resetForm()
            this.dialogFormVisible = false
            this.payment[0].amount = 0
            this.checkout.forEach(item => {
                this.payment[0].amount += parseFloat(item.total)
            })
            this.payment[0].amount = this.payment[0].amount.toFixed(2)
            console.log('this.$refs.productCascader', this.$refs.productCascader)
            this.edit.id = [node[0]]
            // this.$refs.productCascader.clearCheckedNodes()
            // this.$refs.productCascader.checkedValue = [...[], ...node[0]]
            // this.total = 0
            // this.checkout.forEach(item => {
            //     this.total += item.price * item.number * item.discount
            // })
        },
        resetForm(){
            this.edit = {
                id: 0,
                stock_id: '',
                category_id: '',
                category_name: '',
                product_id: '',
                product_name: '',
                stock_price: '',
                retail_price: '',
                discount: 1,
                code: true,
                type: 'in',
                status: 0,
                number: 1,
                remark: '',
            }
        },
        listCategory(){
            SEARCH_CATEGORY()
                .then(res => {
                    this.category = res.data.data
                    this.category.forEach(item => {
                        item.value = item.id
                        item.label = item.name
                        this.categoryObj[item.id] = item
                    })
                })
                .catch(err => {
                    console.error(err)
                })
        },
        listProduct(category_id){
            console.log('listProduct', category_id)
            GET_PRODUCT_BY_CATEGORY_ID({category_id})
                .then(res => {
                    this.product = res.data.data
                    this.product.forEach(item => {
                        this.productObj[item.id] = item
                    })
                    this.edit.category_name = this.categoryObj[category_id]
                })
                .catch(err => {
                    console.error(err)
                })
        },
        chooseProduct(product_id){
            this.product.forEach(item => {
                if(item.id == product_id){
                    this.edit.product_name = this.productObj[product_id]
                    this.edit.product_id = product_id
                    this.edit.price = item.price
                    this.edit.discount = item.discount
                }
            })
        },
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '总价';
                    return;
                }
                const values = data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                    const value = Number(curr);
                    if (!isNaN(value)) {
                        return prev + curr;
                    } else {
                        return prev;
                    }
                    }, 0);
                    sums[index] = sums[index].toFixed(2) + '元';
                } else {
                    sums[index] = '';
                }
            });

            return sums;
        },
        addPay(){
            var total = 0;
            this.checkout.forEach(item => {
                total += parseFloat(item.total)
            })
            console.log('total', total)
            total = total.toFixed(2)
            var amount = 0;
            this.payment.forEach(item => {
                amount += parseFloat(item.amount)
            })
            amount = amount.toFixed(2)
            this.payment.push({
                method: '',
                amount: total - amount,
            })
        },
        doPay(callback){
            DO_PAY({
                billing: this.billing,
                payment: this.payment,
                checkout: this.checkout
            })
                .then(res => {
                    this.billing = res.data.data
                    if(this.payment[0].method == 'alipay'){
                        this.alipay.subject = '购买商品'
                        this.alipay.out_trade_no = `product-buy-${res.data.data.id}`
                        this.alipay.total_amount = res.data.data.amount
                        // this.$nextTick(_ => {
                        //     this.$refs['alipay_buyer_id'].focus()
                        // })
                    }else if(this.payment[0].method == 'wechat'){
                        this.wechat.subject = '购买商品'
                        this.wechat.out_trade_no = `product-buy-${res.data.data.id}`
                        this.wechat.total_amount = res.data.data.amount
                        // this.$nextTick(_ => {
                        //     this.$refs['wechat_buyer_id'].focus()
                        // })
                    } else {
                        this.$message.success('结账成功')
                        this.isCheckouted = true
                    }
                    if(callback != undefined){
                        callback()
                    }
                })
                .catch(err => {
                    console.error(err)
                })
        },
        getBilling(){
            GET_BILLING({sequence: this.sequence})
                .then(res => {
                    this.payment = res.data.data.payment
                    // this.checkout = res.data.data.checkout
                    console.log('res.data.data.checkout', res.data.data.checkout)
                    res.data.data.checkout.forEach(item => {
                        item.category_id = item.product.category_id
                        // item.category_name = {name: ''}
                        item.category_name = item.product.product_category.name
                        item.product_id = item.product_id
                        // item.product_name = {name: ''}
                        item.product_name = item.product.name
                        item.number = 1
                        item.price = item.product.price
                        item.total = item.sales.receipt
                    })
                    console.log('res.data.data.checkout', res.data.data.checkout)
                    this.$set(this, 'checkout', res.data.data.checkout)

                    this.billing = res.data.data.billing
                    this.isCheckouted = res.data.data.billing.status == 1 ? true : false
                    if(this.payment[0].method == 'alipay'){
                        this.alipay.subject = '购买商品'
                        this.alipay.out_trade_no = this.billing.id
                        this.alipay.total_amount = this.billing.amount
                        this.$nextTick(_ => {
                            this.$refs['alipay_buyer_id'].focus()
                        })
                    }else{
                        this.wechat.subject = '购买商品'
                        this.wechat.out_trade_no = this.billing.id
                        this.wechat.total_amount = this.billing.amount
                        this.$nextTick(_ => {
                            this.$refs['wechat_buyer_id'].focus()
                        })
                    }

                    this.payment[0].amount = 0
                    this.checkout.forEach(item => {
                        this.payment[0].amount += parseFloat(item.total)
                    })
                    this.payment[0].amount = this.payment[0].amount.toFixed(2)
                    
                    // this.alipay.buyer_id
                })
                .catch(err => {
                    console.error(err)
                })
        },
        cancelPay(){
            this.$confirm('是否确定撤销结算？', '警告', {
                type: 'warning'
            }).then(() => {
                CANCEL_PAY({billing_id: this.billing.id})
                    .then(res => {
                        this.getBilling();
                    })
                    .catch(err => {
                        console.error(err)
                    })
            }).catch(() => {
                        
            });
        },
        rollback(row){
            this.$confirm('是否确定删除该订单?删除后可能无法恢复', '警告', {
                type: 'warning'
            }).then(() => {
                ROLLBACK_PAY({billing_id: this.billing.id})
                    .then(res => {
                        this.$router.push('/billing/billingList')
                    })
                    .catch(err => {
                        console.error(err)
                    })
            }).catch(() => {
                        
            });
        },
        sendPay(){
            var that = this
            SEND_PAY(this.alipay)
                .then(res => {
                    if(res.data.code == 0){
                        this.$message.success('已提交支付宝支付')
                        this.checkTime = setInterval(_ => {
                            that.checkPay()
                        }, 3000)
                    }else{
                        this.$message.error(res.data.data)
                        this.payLoading.close()
                    }
                })
                .catch(err => {
                    console.error(err)
                    this.$message.error(err.response.data)
                    this.payLoading.close()
                })
        },
        checkPay(){
            // var that = this
            CHECK_PAY({out_trade_no: this.alipay.out_trade_no})
                .then(res => {
                    if(res.data.code == 0){
                        this.$message.success('支付宝支付已经成功')
                        clearInterval(this.checkTime)

                        // UPDATE_PAY_STATUS({billing_id: this.billing.id})
                        //     .then(res => {
                            this.$router.push(`/billing/checkout/${this.billing.sequence}`)
                            this.sequence = this.billing.sequence
                            this.getBilling()
                            this.payCodeVisible = false
                            this.payCodeUtilVisible = false
                            this.payResultVisible = true
                            this.payLoading.close()
                            // })
                            // .catch(err => {
                            //     console.error(err)
                            // })

                        
                    }else{
                        this.$message.error(res.data.data)
                        this.payLoading.close()
                    }
                })
                .catch(err => {
                    console.error(err)
                    this.$message.error(err.response.data)
                    this.payLoading.close()
                })
        },
        sendPayWechat(){
            // this.$alert('提醒', '微信支付中，请勿关闭页面')
            var url = `https://api.viewinn.cn/wechat/sendPayWechat/${this.wechat.subject}/${this.wechat.out_trade_no}/${this.wechat.total_amount}/${this.wechat.buyer_id}/billing_checkout/${this.billing.sequence}`
            window.open(url, '_self');
        },
        getProduct(){
            let product_id = this.search.code.substr(0, 6)
            let stock_id = this.search.code.substr(6, 6)

            console.log(parseInt(product_id), parseInt(stock_id))
            GET_PRODUCT({
                product_id,
                stock_id
            })
                .then(res => {
                    console.log('res', res.data.data)
                    var newProduct = Object.assign({}, res.data.data)
                    newProduct.stock_id = newProduct.id
                    newProduct.category_id = newProduct.product.category_id
                    newProduct.category_name = newProduct.product.product_category.name
                    newProduct.product_name = (newProduct.product.brand != null ? newProduct.product.brand : '') + newProduct.product.name + (newProduct.product.spec != null ? newProduct.product.spec : '')
                    newProduct.number = 1
                    newProduct.price = newProduct.product.price
                    newProduct.total = newProduct.price * newProduct.number * newProduct.discount
                    this.checkout.push(newProduct)
                    this.search.code = ''
                    this.payment[0].amount = 0
                    this.checkout.forEach(item => {
                        this.payment[0].amount += parseFloat(item.total)
                    })
                    this.payment[0].amount = this.payment[0].amount.toFixed(2)
                })
                .catch(err => {
                    console.error(err)
                })
        },
        remove(index){
            this.checkout.splice(index, 1)
            this.payment[0].amount = 0
            this.checkout.forEach(item => {
                this.payment[0].amount += parseFloat(item.total)
            })
            this.payment[0].amount = this.payment[0].amount.toFixed(2)

        },
        removePayMethod(index){
            this.payment.splice(index, 1)
        },
        setPayment(type) {
            this.payment[0].method = type
            this.doPay()
            this.payCodeVisible = true
            this.$nextTick(_ => {
                this.$refs['code_number'].focus()
            })
        },
        setCode() {
            this.payLoading = this.$loading({
                lock: true,
                text: '支付中，请勿刷新页面，请勿输入其他内容，等待系统反馈。如长时间无反应请联系系统管理员。',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            if(this.payment[0].method == 'alipay'){
                this.alipay.buyer_id = this.codeNumber
                this.sendPay()

            }
            if(this.payment[0].method == 'wechat'){
                this.wechat.buyer_id = this.codeNumber
                this.sendPayWechat()
            }
        },
        setUtilPayment(){
            this.payCodeUtilVisible = true
            this.$nextTick(_ => {
                this.$refs['code_number_util'].focus()
            })
        },
        setCodeUtil(){
            this.payLoading = this.$loading({
                lock: true,
                text: '支付中，请勿刷新页面，请勿输入其他内容，等待系统反馈。如长时间无反应请联系系统管理员。',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            if(/^(?:2[5-9]|30)\d{14,22}$/ig.test(this.codeNumberUtil)){
                this.payment[0].method = 'alipay'
                this.doPay(_ => {
                    this.alipay.buyer_id = this.codeNumberUtil
                    this.sendPay()
                })
            }else if(/^(?:1[0-5])\d{16}$/ig.test(this.codeNumberUtil)){
                this.payment[0].method = 'wechat'
                this.doPay(_ => {
                    this.wechat.buyer_id = this.codeNumberUtil
                    this.sendPayWechat()
                })
            }else{
                this.$message.error('错误的付款码，请确认客人正在使用支付宝或微信的付款码');
                this.payLoading.close()
            }
        },
        buyNew() {
            this.$router.push('/billing/checkout')
            location.reload()
        }
    }
}
</script>

<style lang="scss">
.checkout-area {
    padding: 10px !important;
    background: white;
}

.total-area {
    display: flex;
    justify-content: flex-end;
    font-size: 24px;
    padding: 20px;
}
</style>