import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import echarts from './common/echartsUi'

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.prototype.$echarts = echarts

// markdown
import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';
Vue.use(mavonEditor);

// showdown
import showdown from 'showdown';
Vue.prototype.converter = new showdown.Converter();

import VueShowdown from 'vue-showdown'
// Vue.use() 的第二个参数是可选的
Vue.use(VueShowdown, {
  // 设置 showdown 默认 flavor
  flavor: 'github',
  // 设置 showdown 默认 options （会覆盖上面 flavor 的 options）
  options: {
    emoji: true,
  },
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
