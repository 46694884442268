<template>
    <div class="product">
        <el-row>
            <el-col :span="24">
                <el-form :model="form" :inline="true" size="mini" @submit.native.prevent>
                    <el-form-item>
                        <el-button type="primary" @click="openAdd">添加商品</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model="form.search" placeholder="请输入关键词"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="changePage(1)">查找</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <el-table :data="tableData" v-loading="loading">
            <el-table-column label="品类" width="100">
                <template slot-scope="scope">
                    {{scope.row.product_category.name}}
                </template>
            </el-table-column>
            <el-table-column label="商品" prop="name">
                <template slot-scope="scope">
                    <h5>{{scope.row.name}}</h5>
                    <p class="intro">
                        <span v-if="scope.row.manufacturer != null">厂商：{{scope.row.manufacturer}}</span>
                        <span v-if="scope.row.brand != null">品牌：{{scope.row.brand}}</span>
                        <span v-if="scope.row.press != null">出版社：{{scope.row.press}}</span>
                        <span v-if="scope.row.spec != null">型号规格：{{scope.row.spec}}</span>
                    </p>
                    <p class="intro">
                        <span v-if="scope.row.description != null">商品描述：{{scope.row.description}}</span>
                        <span v-if="scope.row.remark != null"><br>备注：{{scope.row.remark}}</span>
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="售价" prop="price" width="50"></el-table-column>
            <el-table-column label="折扣" prop="discount" width="50"></el-table-column>
            <!-- <el-table-column label="创建时间" prop="created_at"></el-table-column>
            <el-table-column label="修改时间" prop="updated_at"></el-table-column> -->
            <el-table-column label="操作" width="80">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="openEditor(scope.row)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            @prev-click="changePage(page--)"
            @next-click="changePage(page++)"
            @current-change="changePage"
            :current-page="page"
            :page-size="pageSize"
            layout="prev, pager, next"
            :total="total"
        >
        </el-pagination>

        <el-dialog title="修改" :visible.sync="dialogFormVisible" :before-close="closeDialog">
            <el-form :model="edit" ref="editor" label-width="120px">
                <el-form-item label="品类" prop="name">
                    <el-select v-model="edit.category_id" placeholder="请选择">
                        <el-option
                            v-for="(item, key) in category"
                            :key="key"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="厂商" prop="manufacturer">
                    <el-input v-model="edit.manufacturer"></el-input>
                </el-form-item>
                <el-form-item label="品牌" prop="brand">
                    <el-input v-model="edit.brand"></el-input>
                </el-form-item>
                <el-form-item label="出版社" prop="press">
                    <el-input v-model="edit.press"></el-input>
                </el-form-item>
                <el-form-item label="商品名" prop="name">
                    <el-input v-model="edit.name"></el-input>
                </el-form-item>
                <el-form-item label="型号规格" prop="spec">
                    <el-input v-model="edit.spec"></el-input>
                </el-form-item>
                <el-form-item label="商品描述" prop="description">
                    <el-input v-model="edit.description"></el-input>
                </el-form-item>
                <el-form-item label="售价" prop="price">
                    <el-input v-model="edit.price"></el-input>
                </el-form-item>
                <el-form-item label="折扣" prop="discount">
                    <el-input v-model="edit.discount"></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input v-model="edit.remark"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
// import dayjs from 'dayjs'
import {
    SEARCH_CATEGORY,
    SEARCH_PRODUCT,
    SAVE_PRODUCT
} from '../api/product'
export default {
    name: 'ProductList',
    data(){
        return {
            category: [],
            form: {},
            tableData: [],
            edit: {
                id: 0,
                category_id: '',
                manufacturer: '',
                brand: '',
                press: '',
                name: '',
                spec: '',
                description: '',
                price: 0,
                discount: 1,
                remark: '',
            },
            dialogFormVisible: false,
            page: 1,
            pageSize: 1,
            total: 0,
            loading: false,
        }
    },
    mounted(){
        this.listCategory()
        this.search()
    },
    methods: {
        save(){
            SAVE_PRODUCT(this.edit)
                .then(res => {
                    console.log(res)
                    this.search()
                    this.dialogFormVisible = false
                    this.resetForm()
                })
                .catch(err => {
                    this.$message.error(err.response.data)
                    console.error(err)
                })
        },
        changePage(page){
            this.page = page
            this.search()
        },
        search(){
            this.loading = true
            SEARCH_PRODUCT({
                page: this.page,
                search: this.form.search
            })
                .then(res => {
                    this.tableData = res.data.data.data
                    this.total = res.data.data.total
                    this.pageSize = res.data.data.per_page
                    this.loading = false
                })
                .catch(err => {
                    console.error(err)
                    this.$message.error(err.response.data)
                    this.loading = false
                })
        },
        listCategory(){
            SEARCH_CATEGORY()
                .then(res => {
                    this.category = res.data.data
                })
                .catch(err => {
                    console.error(err)
                })
        },
        openEditor(row){
            this.dialogFormVisible = true
            this.edit = Object.assign({}, row)
        },
        openAdd(){
            this.dialogFormVisible = true
        },
        closeDialog(){
            this.resetForm()
            this.dialogFormVisible = false
        },
        resetForm(){
            this.edit = {
                id: 0,
                category_id: '',
                manufacturer: '',
                brand: '',
                press: '',
                name: '',
                spec: '',
                description: '',
                price: 0,
                discount: 1,
                remark: '',
            }
        },
    }
}
</script>

<style lang="scss">
.intro {
    span {
        margin-right: 10px;
    }
}
</style>