<template>
    <div class="seat">
        <el-row>
            <el-col :span="24">
                <el-form :model="form" :inline="true" size="mini">
                    <el-form-item>
                        <el-button type="primary" @click="openAdd">添加坐席</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="danger" @click="openLock">锁定全部坐席</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <el-table :data="tableData">
            <el-table-column label="坐席号" prop="code"></el-table-column>
            <el-table-column label="名称" prop="name"></el-table-column>
            <el-table-column label="坐标" prop="position"></el-table-column>
            <el-table-column label="房间" prop="room"></el-table-column>
            <el-table-column label="创建时间" prop="created_at"></el-table-column>
            <el-table-column label="修改时间" prop="updated_at"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="openEditor(scope.row)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog title="修改" :visible.sync="dialogFormVisible" :before-close="closeDialog">
            <el-form ref="editor" :model="seat" label-width="120">
                <el-form-item label="坐席号" prop="code">
                    <el-input v-model="seat.code"></el-input>
                </el-form-item>
                <el-form-item label="坐席名称" prop="name">
                    <el-input v-model="seat.name"></el-input>
                </el-form-item>
                <el-form-item label="坐席坐标" prop="position">
                    <el-input v-model="seat.position"></el-input>
                </el-form-item>
                <el-form-item label="坐席房间" prop="room">
                    <el-select v-model="seat.room" placeholder="请选择房间">
                      <el-option value="观山" label="观山"></el-option>
                      <el-option value="望川" label="望川"></el-option>
                      <el-option value="手工" label="手工"></el-option>
                      <el-option value="商务" label="商务"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="坐席锁定" prop="date">
                    <el-date-picker 
                        v-model="seat.date"
                        type="dates"
                        placeholder="选择一个或多个日期"
                        value-format="yyyy-MM-dd"
                    ></el-date-picker>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="saveSeat">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="锁定全部坐席" :visible.sync="dialogLockSeatVisible">
            <el-form ref="editor" :model="seatLock" label-width="120">
                <el-form-item label="坐席锁定日期" prop="date">
                    <el-date-picker 
                        v-model="seatLock.date"
                        type="dates"
                        placeholder="选择一个或多个日期"
                        value-format="yyyy-MM-dd"
                    ></el-date-picker>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogLockSeatVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveSeatLock">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
// import dayjs from 'dayjs'
import {
    SAVE_SEAT,
    SEARCH_SEAT,
    SAVE_SEAT_LOCK,
    // TEST
} from '../api/seat'
export default {
    name: 'Seat',
    data(){
        return {
            form: {},
            tableData: [],
            seat: {
                id: 0,
                code: '',
                name: '',
                position: '',
                date: []
            },
            seatLock: {
                date: []
            },
            dialogLockSeatVisible: false,
            dialogFormVisible: false
        }
    },
    mounted(){
        this.searchSeat()
        // TEST()
    },
    methods: {
        saveSeat(){
            SAVE_SEAT(this.seat)
                .then(res => {
                    console.log(res)
                    this.searchSeat()
                    this.dialogFormVisible = false
                    this.resetForm()
                })
                .catch(err => {
                    this.$message.error(err.response.data)
                    console.error(err)
                })
        },
        searchSeat(){
            SEARCH_SEAT()
                .then(res => {
                    this.tableData = res.data.data
                })
                .catch(err => {
                    console.error(err)
                })
        },
        openEditor(row){
            this.dialogFormVisible = true
            this.seat = Object.assign({}, row)
            var date = []
            this.seat.seat_locks.forEach(item => {
                date.push(item.date)
            })
            this.$set(this.seat, 'date', date)
        },
        openAdd(){
            this.dialogFormVisible = true
        },
        openLock() { 
            this.dialogLockSeatVisible = true
        },
        closeDialog(){
            this.resetForm()
            this.dialogFormVisible = false
        },
        resetForm(){
            this.seat = {
                id: 0,
                code: ''
            }
        },
        saveSeatLock() { 
            SAVE_SEAT_LOCK(this.seatLock)
                .then(res => {
                    console.log(res)
                    this.searchSeat()
                    this.dialogLockSeatVisible = false
                    this.resetForm()
                })
                .catch(err => {
                    this.$message.error(err.response.data)
                    console.error(err)
                })
        }
    }
}
</script>