<template>
    <div class="stock">
        <el-row>
            <el-col :span="24">
                <el-form :model="form" :inline="true" size="mini" @submit.native.prevent>
                    <el-form-item>
                        <el-button type="primary" @click="openAdd">入库</el-button>
                    </el-form-item><el-form-item>
                        <el-input v-model="form.search" placeholder="请输入关键词"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="changePage(1)">查找</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <el-table 
            :data="tableData"
            ref="table"
            row-key="stock_ids"
            :expand-row-keys="rowKeys"
            @expand-change="showDetail"
            v-loading="loading"
        >
            <el-table-column type="expand">
                <template slot-scope="scope">
                    <el-table :data="scope.row.detail">
                        <el-table-column label="状态" width="80">
                            <template slot-scope="prop">
                                <el-tag v-if="prop.row.stock_out != null" size="mini" type="danger">[已出库]</el-tag>
                                <el-tag v-else size="mini" type="success">[未出库]</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="条码">
                            <template slot-scope="prop">
                                {{prop.row.product.category_id.toString().padStart(6, '0')}}{{prop.row.id.toString().padStart(6, '0')}}
                            </template>
                        </el-table-column>
                        <el-table-column label="商品" width="400">
                            <template slot-scope="prop">
                                <span style="margin-left: 10px;">{{prop.row.id}}</span>
                                <span style="margin-right: 10px;">{{prop.row.product.brand != null ? prop.row.product.brand : ''}}{{prop.row.product.name}}{{prop.row.product.spec != null ? prop.row.product.spec : ''}}</span>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column label="创建时间" prop="created_at"></el-table-column> -->
                        <el-table-column label="修改时间" prop="updated_at"></el-table-column>
                        <el-table-column label="操作" width="300">
                            <template slot-scope="prop">
                                <el-button type="default" size="mini" @click="stockOut(scope.row, prop.row.id)" :disabled="prop.row.stock_out != null">手动出库</el-button>
                                <el-button type="default" size="mini" @click="stockRollback(scope.row, prop.row.id)" :disabled="prop.row.stock_out == null">库存回滚</el-button>
                                <el-button type="danger" size="mini" @click="stockDelete(scope.row, prop.row.id)" :disabled="prop.row.stock_out != null">删除库存</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
            </el-table-column>
            <el-table-column label="商品名" width="500">
                <template slot-scope="scope">
                    {{scope.row.product.brand != null ? scope.row.product.brand : ''}}{{scope.row.product.name}}{{scope.row.product.spec != null ? scope.row.product.spec : ''}}
                    <p v-if="scope.row.remark != null">{{scope.row.remark}}</p>
                </template>
            </el-table-column>
            <el-table-column label="进货价" prop="stock_price"></el-table-column>
            <el-table-column label="售价">
                <template slot-scope="scope">
                    {{scope.row.product.price}}
                </template>
            </el-table-column>
            <el-table-column label="折扣" prop="discount"></el-table-column>
            <el-table-column label="库存" prop="number"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="openEditor(scope.row)">编辑</el-button>
                    <el-button type="text" size="mini" @click="exportStock(scope.row)">导出</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            @prev-click="changePage(page--)"
            @next-click="changePage(page++)"
            @current-change="changePage"
            :current-page="page"
            :page-size="pageSize"
            layout="prev, pager, next"
            :total="total"
        >
        </el-pagination>

        <el-dialog title="修改" :visible.sync="dialogFormVisible" :before-close="closeDialog">
            <el-form :model="edit" ref="editor" label-width="120px">
                <el-form-item label="品类" prop="name">
                    <el-select v-model="edit.product.category_id" placeholder="请选择" @change="listProduct">
                        <el-option
                            v-for="(item, key) in category"
                            :key="key"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="商品名" prop="name">
                    <el-select v-model="edit.product.id" filterable placeholder="请选择">
                        <el-option
                            v-for="(item, key) in product"
                            :key="key"
                            :label="(item.brand != null ? item.brand : '')+item.name+(item.spec != null ? item.spec : '')"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="进货价" prop="stock_price">
                    <el-input v-model="edit.stock_price"></el-input>
                </el-form-item>
                <!-- <el-form-item label="售价" prop="retail_price">
                    <el-input v-model="edit.product.price"></el-input>
                </el-form-item> -->
                <el-form-item label="折扣" prop="discount">
                    <el-input v-model="edit.discount"></el-input>
                </el-form-item>
                <el-form-item label="数量" prop="number">
                    <el-input v-model="edit.number" :disabled="edit.id != 0"></el-input>
                </el-form-item>
                <el-form-item label="出入库" prop="type">
                    <el-select v-model="edit.type" placeholder="请选择" :disabled="edit.id != 0">
                        <el-option
                            v-for="(type, type_key) in types"
                            :key="type_key"
                            :label="type"
                            :value="type_key">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="条码">
                    <el-switch
                        v-model="edit.code"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        :disabled="edit.id != 0">
                    </el-switch>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
// import dayjs from 'dayjs'
import {
    SEARCH_CATEGORY,
    GET_PRODUCT_BY_CATEGORY_ID,
} from '../api/product'
import {
    SEARCH_STOCK,
    SAVE_STOCK,
    GET_STOCK_DETAIL,
    STOCK_OUT,
    STOCK_ROLLBACK,
    STOCK_DELETE,
} from '../api/stock'
import {
    EXPORT_STOCK,
} from '../api/export'

export default {
    name: 'Stock',
    data(){
        return {
            rowKeys: [],
            tableKey: '',
            currentIndex: null,
            category: [],
            product: [],
            form: {},
            tableData: [],
            edit: {
                id: 0,
                product: {},
                product_id: '',
                stock_price: '',
                retail_price: '',
                discount: 1,
                code: true,
                type: 'in',
                status: 0,
                number: 1,
                remark: '',
            },
            dialogFormVisible: false,
            status: {
                '0': '正常',
                '1': '删除'
            },
            types: {
                'in': '入库',
                'out': '出库'
            },
            page: 1,
            pageSize: 1,
            total: 0,
            loading: false,
        }
    },
    mounted(){
        this.listCategory()
        this.search()
    },
    methods: {
        save(){
            SAVE_STOCK(this.edit)
                .then(res => {
                    console.log(res)
                    this.search()
                    this.dialogFormVisible = false
                    this.resetForm()
                })
                .catch(err => {
                    this.$message.error(err.response.data)
                    console.error(err)
                })
        },
        changePage(page){
            this.page = page
            this.search()
        },
        search(){
            this.loading = true
            SEARCH_STOCK({
                page: this.page,
                search: this.form.search
            })
                .then(res => {
                    this.tableData = res.data.data.data
                    this.total = res.data.data.total
                    this.pageSize = res.data.data.per_page
                    this.loading = false
                })
                .catch(err => {
                    console.error(err)
                    this.$message.error(err.response.data)
                    this.loading = false
                })
        },
        listCategory(){
            SEARCH_CATEGORY()
                .then(res => {
                    this.category = res.data.data
                })
                .catch(err => {
                    console.error(err)
                })
        },
        listProduct(category_id){
            console.log('listProduct', category_id)
            GET_PRODUCT_BY_CATEGORY_ID({category_id})
                .then(res => {
                    this.product = res.data.data
                })
                .catch(err => {
                    console.error(err)
                })
        },
        openEditor(row){
            row.id = row.stock_ids.split(',')
            this.dialogFormVisible = true
            this.edit = Object.assign({}, row)
            console.log('open edit', this.edit)
            this.listProduct(this.edit.product.category_id)
        },
        openAdd(){
            this.dialogFormVisible = true
        },
        closeDialog(){
            this.resetForm()
            this.dialogFormVisible = false
        },
        resetForm(){
            this.edit = {
                id: 0,
                product: {},
                product_id: '',
                stock_price: '',
                retail_price: '',
                discount: 1,
                code: true,
                type: 'in',
                status: 0,
                number: 1,
                remark: '',
            }
        },
        // toogleExpand(row) {
        //     let $table = this.$refs.table;
        //     this.tableData.map((item) => {
        //         if (row.stock_ids != item.stock_ids) {
        //             $table.toggleRowExpansion(item, false)
        //         }
        //     })
        //     $table.toggleRowExpansion(row)
        //     this.currentIndex = row.stock_ids

        //     GET_STOCK_DETAIL({'stock_ids': row.stock_ids})
        //         .then(res => {
        //             row.detail = res.data.data
        //         })
        //         .catch(err => {
        //             console.error(err)
        //         })
        // },
        showDetail(row){
            // console.log('show detail', row, expandedRows)
            let $table = this.$refs.table
            this.tableData.forEach((item, index) => {
                if(item.stock_ids == row.stock_ids){
                    console.log('current', item.stock_ids)
                    GET_STOCK_DETAIL({'stock_ids': row.stock_ids})
                        .then(res => {
                            this.$set(this.tableData[index], 'detail', res.data.data)
                            $table.toggleRowExpansion(row, true)
                            this.rowKeys=[row.stock_ids]

                        })
                        .catch(err => {
                            console.error(err)
                        })
                }
            })
        },
        stockOut(row, id){
            STOCK_OUT({stock_id: id})
                .then(res => {
                    this.showDetail(row)
                })
                .catch(err => {
                    console.error(err)
                })
        },
        stockRollback(row, id){
            STOCK_ROLLBACK({stock_id: id})
                .then(res => {
                    if(res.data.code == 400){
                        this.$message.error(res.data.data)
                    }else{
                        this.showDetail(row)
                    }
                })
                .catch(err => {
                    console.error(err)
                })
        },
        stockDelete(row, id){
            this.$confirm('是否确定删除该库存?', '警告', {
                type: 'warning'
            }).then(() => {
                STOCK_DELETE({stock_id: id})
                    .then(res => {
                        this.showDetail(row)
                    })
                    .catch(err => {
                        console.error(err)
                    })
            }).catch(() => {
                        
            });
            
        },
        exportStock(row){
            window.open(`api/export/exportStock/${row.product_id}/${row.stock_ids}`, '_blank')
            // EXPORT_STOCK({id: 1})
            //     .then(res => {
            //         // this.showDetail(row)
            //     })
            //     .catch(err => {
            //         console.error(err)
            //     })
        },
    }
}
</script>

<style lang="scss">
.el-pagination {
    padding: 2px 0;
}
</style>