<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-form :model="filter" inline size="mini">
          <el-form-item label="日期">
            <el-date-picker 
              v-model="filter.date" 
              type="date" 
              value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSearch">搜索</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

    <el-table :data="listData">
      <el-table-column
        prop="date"
        label="日期"
      >
      </el-table-column>
      <el-table-column
        prop="product_name"
        label="商品名"
      >
      </el-table-column>
      <el-table-column
        prop="before_num"
        label="盘点前"
      >
      </el-table-column>
      <el-table-column
        prop="sales"
        label="当日已售"
      >
      </el-table-column>
      <el-table-column
        prop="after_num"
        label="盘点后"
      >
        <template slot-scope="scope">
          <span :class="{warning: scope.row.before_num - scope.row.sales != scope.row.after_num}">{{scope.row.after_num}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="user_name"
        label="盘点人"
      >
      </el-table-column>
      <el-table-column
        prop="created_at"
        label="盘点时间"
      >
      </el-table-column>
    </el-table>
    
  </div>
</template>

<script>
import dayjs from 'dayjs'
import {
    GET_INVENTORY_REPORT
    // TEST
} from '../api/report'
export default {
  data(){
    return {
      filter: {
        date: dayjs().format('YYYY-MM-DD')
      },
      listData: []
    }
  },
  mounted(){

  },
  methods: {
    onSearch(){
      GET_INVENTORY_REPORT(this.filter)
        .then(res => {
          this.listData = res.data.data
        })
    }
  }
}
</script>

<style lang="scss">
.warning {
  color: lightcoral;
}
</style>