<template>
    <div class="product-category">
        <el-row>
            <el-col :span="24">
                <el-form :model="form" :inline="true" size="mini">
                    <el-form-item>
                        <el-button type="primary" @click="openAdd">添加商品分类</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <el-table :data="tableData">
            <el-table-column label="分类名" prop="name"></el-table-column>
            <el-table-column label="创建时间" prop="created_at"></el-table-column>
            <el-table-column label="修改时间" prop="updated_at"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="openEditor(scope.row)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog title="修改" :visible.sync="dialogFormVisible" :before-close="closeDialog">
            <el-form :model="edit" ref="editor" label-width="120px">
                <el-form-item label="分类名" prop="name">
                    <el-input v-model="edit.name"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
// import dayjs from 'dayjs'
import {
    SAVE_CATEGORY,
    SEARCH_CATEGORY
} from '../api/product'
export default {
    name: 'Seat',
    data(){
        return {
            form: {},
            tableData: [],
            edit: {
                id: 0,
                name: '',
            },
            dialogFormVisible: false,
        }
    },
    mounted(){
        this.search()
    },
    methods: {
        save(){
            SAVE_CATEGORY(this.edit)
                .then(res => {
                    console.log(res)
                    this.search()
                    this.dialogFormVisible = false
                    this.resetForm()
                })
                .catch(err => {
                    this.$message.error(err.response.data)
                    console.error(err)
                })
        },
        search(){
            SEARCH_CATEGORY()
                .then(res => {
                    this.tableData = res.data.data
                })
                .catch(err => {
                    console.error(err)
                })
        },
        openEditor(row){
            this.dialogFormVisible = true
            this.edit = Object.assign({}, row)
        },
        openAdd(){
            this.dialogFormVisible = true
        },
        closeDialog(){
            this.resetForm()
            this.dialogFormVisible = false
        },
        resetForm(){
            this.edit = {
                id: 0,
                name: '',
            }
        },
    }
}
</script>