<template>
    <div class="book">
        <el-row>
            <el-col :span="24">
                <el-form :model="form" :inline="true" size="mini" @submit.native.prevent>
                    <el-form-item>
                        <el-button type="primary" @click="openAdd">添加通知</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <el-table :data="tableData" v-loading="loading">
            <el-table-column label="开始日期" prop="start_date"></el-table-column>
            <el-table-column label="结束日期" prop="end_date"></el-table-column>
            <el-table-column label="级别" prop="level"></el-table-column>
            <el-table-column label="通知内容" prop="content"></el-table-column>
            <el-table-column label="创建时间" prop="created_at"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="primary" size="small" @click="openEditor(scope.row)">编辑</el-button>
                    <el-button type="danger" size="small" @click="doDelete(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            @prev-click="changePage(page--)"
            @next-click="changePage(page++)"
            @current-change="changePage"
            :current-page="page"
            :page-size="pageSize"
            layout="prev, pager, next"
            :total="total"
        >
        </el-pagination>

        <el-dialog title="修改" :visible.sync="dialogFormVisible" :before-close="closeDialog">
            <el-form ref="editor" :model="edit" label-width="120">
                <el-form-item label="开始日期" prop="date">
                    <el-date-picker 
                        v-model="edit.date"
                        type="daterange"
                        placeholder="选择一个或多个日期"
                        value-format="yyyy-MM-dd"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="通知内容" prop="code">
                    <el-input v-model="edit.content" type="textarea"></el-input>
                </el-form-item>
                <el-form-item label="级别" prop="level">
                    <el-select v-model="edit.level">
                        <el-option v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
// import dayjs from 'dayjs'
import {
    SAVE_MESSAGE,
    DELETE_MESSAGE,
    GET_MESSAGES
} from '../api/message'
export default {
    name: 'Message',
    data(){
        return {
            form: {},
            tableData: [],
            edit: {
                id: 0,
                date: [],
                start_date: '',
                end_date: '',
                content: '',
                level: 'announcement'
            },
            dialogFormVisible: false,
            page: 1,
            pageSize: 1,
            total: 0,
            loading: false,
            options: [
                { label: '通知', value: 'announcement' },
                { label: '警告', value: 'warning' },
            ]
        }
    },
    mounted(){
        this.search()
    },
    methods: {
        save() {
            this.edit.start_date = this.edit.date[0]
            this.edit.end_date = this.edit.date[1]
            SAVE_MESSAGE(this.edit)
                .then(res => {
                    console.log(res)
                    this.search()
                    this.dialogFormVisible = false
                    this.resetForm()
                })
                .catch(err => {
                    this.$message.error(err.response.data)
                    console.error(err)
                })
        },
        changePage(page){
            this.page = page
            this.search()
        },
        search(){
            this.loading = true
            GET_MESSAGES({
                page: this.page
            })
                .then(res => {
                    this.tableData = res.data.data.data
                    this.total = res.data.data.total
                    this.pageSize = res.data.data.per_page
                    this.loading = false
                })
                .catch(err => {
                    console.error(err)
                    this.$message.error(err.response.data)
                    this.loading = false
                })
        },
        openEditor(row){
            this.dialogFormVisible = true
            this.edit = Object.assign({}, row)
            this.edit.date = [this.edit.start_date, this.edit.end_date]
        },
        openAdd(){
            this.dialogFormVisible = true
            this.resetForm()
        },
        closeDialog(){
            this.resetForm()
            this.dialogFormVisible = false
        },
        resetForm(){
            this.edit = {
                id: 0,
                date: [],
                start_date: '',
                end_date: '',
                content: '',
            }
        },
        doDelete(row) { 
            this.$confirm('是否删除通知？', '提醒', {
                type: 'warning'
            }).then(() => {
                DELETE_MESSAGE({id: row.id})
                    .then(res => {
                        if(res.data.code == 0){
                            this.$message.success('删除成功')
                            this.search()
                        }else{
                            this.$message.error(res.data.data)
                        }
                    })
                    .catch(err => {
                        console.error(err)
                        this.$message.error(err.response.data)
                    })
            }).catch(() => {
                        
            });
        },
    }
}
</script>