<template>
    <div class="billing">
        <el-row>
            <el-col :span="24">
                <el-form :model="form" inline>
                    <el-form-item label="查找">
                        <el-input v-model="form.search" placeholder="请输入手机号或昵称、备注等信息"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="success" @click="searchBilling(1)">查找</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <el-table :data="billing" v-loading="loading">
            <el-table-column type="expand">
                <template slot-scope="scope">
                    <p v-for="(sale, sale_idx) in scope.row.sales.filter(item=>item.stock_info != null)" :key="sale_idx">
                        {{sale.stock_info.product.name}} 售价：{{sale.receipt}}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="单号" prop="sequence"></el-table-column>
            <el-table-column label="金额" prop="amount" width="50"></el-table-column>
            <el-table-column label="备注" prop="remark" width="60"></el-table-column>
            <el-table-column label="充值时间" prop="times" width="50"></el-table-column>
            <el-table-column label="绑卡id" prop="card_id" width="50"></el-table-column>
            <el-table-column label="客户">
                <template slot-scope="scope">
                    {{scope.row.customer != null ? scope.row.customer.nickname : ''}}
                </template>
            </el-table-column>
            <el-table-column label="状态" prop="status" width="120">
                <template slot-scope="scope">
                    {{scope.row.status == 1 ? '已结账' : '未结账'}} 
                    {{scope.row.ispay == 1 ? '已支付' : '未支付'}}
                    <div v-for="(pay, pay_id) in scope.row.pay" :key="pay_id">{{paymethod[pay.pay_type]}} {{pay.pay_amount}}</div>
                </template>
            </el-table-column>
            <el-table-column label="时间" prop="billing_date" width="100">
                <template slot-scope="scope">
                    {{dayjs(scope.row.billing_date).format('YYYY-MM-DD')}}<br>
                    {{dayjs(scope.row.billing_date).format('HH:mm:ss')}}
                </template>
            </el-table-column>
            <el-table-column label="操作" width="260">
                <template slot-scope="scope">
                    <el-button type="success" size="mini" @click="goto(scope.row)">查看</el-button>
                    <el-button type="success" size="mini" @click="changePay(scope.row)">支付</el-button>
                    <el-button type="danger" size="mini" @click="rollback(scope.row)" :disabled="scope.row.status == 0 ? true : false">撤销</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            @prev-click="changePage(page--)"
            @next-click="changePage(page++)"
            @current-change="changePage"
            :current-page="page"
            :page-size="pageSize"
            layout="prev, pager, next"
            :total="total"
        >
        </el-pagination>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import {
    SEARCH_BILLING,
    ROLLBACK_PAY,
    CANCEL_PAY,
    UPDATE_IS_PAY,
} from '../api/billing'
export default {
    name: 'BillingList',
    data(){
        return {
            dayjs: (date)=>{
                return dayjs(date)
            },
            form: {
                search: ''
            },
            billing: [],
            page: 1,
            pageSize: 1,
            total: 0,
            loading: false,
            paymethod: {
                'meituan': '美团点评',
                'wechat_qr': '微信扫码',
                'alipay_qr': '支付宝扫码',
                'cash': '现金',
                'alipay': '支付宝',
                'wechat': '微信',
            },
        }
    },
    mounted(){
        this.searchBilling()
    },
    methods: {
        changePage(page){
            this.page = page
            this.searchBilling(this.page)
        },
        searchBilling(page){
            this.loading = true
            var params = {
                page: page
            }
            if(this.form.search != ''){
                params.search = this.form.search
            }
            SEARCH_BILLING(params)
                .then(res => {
                    this.billing = res.data.data.data
                    this.total = res.data.data.total
                    this.pageSize = res.data.data.per_page
                    this.loading = false
                })
                .catch(err => {
                    console.error(err)
                    this.loading = false
                })
        },
        rollback(row){
            this.$confirm('是否确定删除该订单?删除后可能无法恢复', '警告', {
                type: 'warning'
            }).then(() => {
                ROLLBACK_PAY({billing_id: row.id})
                    .then(res => {
                        this.searchBilling();
                    })
                    .catch(err => {
                        console.error(err)
                    })
            }).catch(() => {
                        
            });
        },
        cancelPay(){
            CANCEL_PAY()
                .then(res => {
                    this.searchBilling();
                })
                .catch(err => {
                    console.error(err)
                })
        },
        goto(row){
            this.$router.push(`/billing/checkout/${row.sequence}`)
        },
        changePay(row){
            UPDATE_IS_PAY({billing_id: row.id})
                .then(res => {
                    this.searchBilling(this.page);
                })
                .catch(err => {
                    console.error(err)
                })
        }
    }
}
</script>

<style lang="scss">

</style>